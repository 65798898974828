import { gql } from '@apollo/client'
import { Box, Burger, Flex, MediaQuery } from '@mantine/core'
import { useContext } from 'react'
import { LayoutContext } from '../../../../../../Contexts/LayoutContext'
import type { HeaderComplexWithPages_reportSource } from '../../../../../../__generated__/globalTypes'
import { desktopBreakpoint } from '../../../../../../utils/constants'
import LanguageDropdown from '../../../../../LanguageDropdown'
import { LanguageDropdownFragments } from '../../../../../LanguageDropdown/LanguageDropdown'
import { LogoutButton } from '../../../../../LogoutButton'
import { PartnerInstitutionLogo, PartnerInstitutionLogoFragments } from '../PartnerInstitutionLogo'
import { PagesMenu, PagesMenuFragments } from './components'

export const HeaderComplexWithPagesFragments = {
  HeaderComplexWithPages_reportSource: gql`
    fragment HeaderComplexWithPages_reportSource on PublicReportSource {
      id

      ...PartnerInstitutionLogo_reportSource
      ...LanguageDropdown_reportSource
      ...PagesMenu_reportSource
    }
    ${LanguageDropdownFragments.LanguageDropdown_reportSource}
    ${PartnerInstitutionLogoFragments.PartnerInstitutionLogo_reportSource}
    ${PagesMenuFragments.PagesMenu_reportSource}
  `,
}

type HeaderComplexWithPagesProps = {
  reportSource: HeaderComplexWithPages_reportSource
  isMenuOpened: boolean
  setIsMenuOpened: (isOpened: boolean) => void
}

export const HeaderComplexWithPages = (props: HeaderComplexWithPagesProps) => {
  const { isMenuOpened, setIsMenuOpened, reportSource } = props
  const { client } = useContext(LayoutContext)
  const isApp = client === 'app'

  return (
    <>
      <PartnerInstitutionLogo reportSource={reportSource} />
      <MediaQuery smallerThan={desktopBreakpoint} styles={{ display: 'none' }}>
        <Flex
          wrap='nowrap'
          mx={64}
          sx={{
            overflow: 'hidden',
            flex: 1,
          }}
        >
          <PagesMenu reportSource={reportSource} />
        </Flex>
      </MediaQuery>
      <Box>
        {reportSource.languages.length > 1 && (
          <MediaQuery
            smallerThan={desktopBreakpoint}
            styles={{
              display: 'none',
            }}
          >
            <Box>
              <LanguageDropdown
                availableLanguages={reportSource.languages}
                defaultLanguage={reportSource.defaultLanguage}
              />
              {isApp && (
                <LogoutButton
                  sx={{
                    color: '#232323',
                  }}
                />
              )}
            </Box>
          </MediaQuery>
        )}
        <MediaQuery
          largerThan={desktopBreakpoint}
          styles={{
            display: 'none',
          }}
        >
          <Burger
            opened={isMenuOpened}
            onClick={() => setIsMenuOpened(!isMenuOpened)}
            color='#232323'
            size={22}
          />
        </MediaQuery>
      </Box>
    </>
  )
}
