import { useLinkExternalConfirm } from '../hooks/useLinkExternalConfirm'

const PARSE_URL_REGEX = /(https?:\/\/[^\s]+\.[^\s,.]+)/g

type TextUrlfyProps = {
  text: string
}

const TextUrlfy = ({ text }: TextUrlfyProps) => {
  const linkExternalConfirm = useLinkExternalConfirm()
  const descriptionParts = text.split(PARSE_URL_REGEX)

  return (
    <>
      {descriptionParts.map(content => {
        if (!PARSE_URL_REGEX.test(content)) {
          return content
        }

        return (
          <a
            key={content}
            href={content}
            target='_blank'
            rel='noopener noreferrer'
            onClick={e => {
              e.preventDefault()
              linkExternalConfirm(content)
            }}
          >
            {content}
          </a>
        )
      })}
    </>
  )
}

export default TextUrlfy
