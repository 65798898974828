import { unstable_usePrompt } from '@faceup/router'

type PromptProps = {
  message: string

  when: boolean
}

export const Prompt = (props: PromptProps) => {
  unstable_usePrompt(props)
  return null
}
