import type { UntitledIconData } from '@faceup/icons'

export const ucDropzone: UntitledIconData = {
  prefix: 'custom',
  name: 'dropzone',
  viewBox: '0 0 38 38',
  paths: [
    {
      shape:
        'M21 5H5a4 4 0 0 0-4 4v20m0 0v4a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4v-8M1 29l9.172-9.172a4 4 0 0 1 5.656 0L21 25m12-8v8m0 0-3.172-3.172a4 4 0 0 0-5.656 0L21 25m0 0 4 4m4-24h8m-4-4v8m-12 4h.02',
      stroke: 'currentColor',
      strokeWidth: '2',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
}
