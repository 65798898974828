// https://www.npmjs.com/package/react-lottie-player#lottieplayerlight
// The default lottie player uses eval. If you don't want eval to be used in your code base, you can instead
// import react-lottie-player/dist/LottiePlayerLight.
// For more discussion see https://github.com/mifi/react-lottie-player/pull/39.
import LottiePlayer from 'react-lottie-player/dist/LottiePlayerLight'

type LottieProps = { animationData: object; shouldPlay: boolean; shouldLoop?: boolean }

export const Lottie = (props: LottieProps) => {
  const { shouldPlay, shouldLoop = false, animationData } = props
  return <LottiePlayer play={shouldPlay} loop={shouldLoop} animationData={animationData} />
}
