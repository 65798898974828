import { deserializePin, serializePin } from './password'
import { RECOVERY_KEY_CONTEXT, RECOVERY_KEY_LENGTH } from './utils/constants'
import { getSodium, handleError } from './utils/general'

export const recoverPrivateKey = async (
  privateKeyRecovery: string,
  recoveryKey: string,
  privateKeyRecoveryNonce: string
) => {
  const sodium = await getSodium()

  try {
    const deserializedRecoveryKey = await deserializeRecoveryKey(recoveryKey)
    const encryptionKey = await convertRecoveryKeyToEncryptionKey(deserializedRecoveryKey)

    const privateKey = sodium.crypto_secretbox_open_easy(
      sodium.from_base64(privateKeyRecovery),
      sodium.from_base64(privateKeyRecoveryNonce),
      sodium.from_base64(encryptionKey),
      'base64'
    )

    return {
      privateKey,
    }
  } catch (e) {
    return handleError(e)
  }
}

export const encryptRecoveryKey = async (recoveryKey: string, passwordKey: string) => {
  const sodium = await getSodium()

  try {
    const recoveryKeyEncryptedNonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES)
    const recoveryKeyEncrypted = sodium.crypto_secretbox_easy(
      sodium.from_base64(recoveryKey),
      recoveryKeyEncryptedNonce,
      sodium.from_base64(passwordKey),
      'base64'
    )

    return {
      recoveryKeyEncryptedNonce: sodium.to_base64(recoveryKeyEncryptedNonce),
      recoveryKeyEncrypted,
    }
  } catch (e) {
    return handleError(e)
  }
}

export const decryptRecoveryKey = async (
  recoveryKeyEncrypted: string,
  recoveryKeyEncryptedNonce: string,
  passwordKey: string
) => {
  const sodium = await getSodium()

  try {
    const recoveryKey = sodium.crypto_secretbox_open_easy(
      sodium.from_base64(recoveryKeyEncrypted),
      sodium.from_base64(recoveryKeyEncryptedNonce),
      sodium.from_base64(passwordKey),
      'base64'
    )

    return { recoveryKey }
  } catch (e) {
    return handleError(e)
  }
}

export const decryptPrivateKey = async (
  privateKeyEncrypted: string,
  nonce: string,
  passwordKey: string
) => {
  try {
    const payload = await decryptRecoveryKey(privateKeyEncrypted, nonce, passwordKey)
    if (typeof payload === 'string') {
      throw new Error(payload)
    }

    return { privateKey: payload.recoveryKey }
  } catch (e) {
    return handleError(e)
  }
}

export const showRecoveryKey = async (
  recoveryKeyEncrypted: string,
  recoveryKeyEncryptedNonce: string,
  passwordKey: string
) => {
  const sodium = await getSodium()
  try {
    const payload = await decryptRecoveryKey(
      recoveryKeyEncrypted,
      recoveryKeyEncryptedNonce,
      passwordKey
    )
    if (typeof payload === 'string') {
      throw new Error(payload)
    }

    return { recoveryKey: await serializeRecoveryKey(sodium.from_base64(payload.recoveryKey)) }
  } catch (e) {
    return handleError(e)
  }
}

export const createSystemRecoveryKey = async (recoveryKey: string, systemPbk: string) => {
  const sodium = await getSodium()
  try {
    const systemRecoveryKey = sodium.crypto_box_seal(
      await deserializeRecoveryKey(recoveryKey),
      sodium.from_base64(systemPbk),
      'base64'
    )

    return { systemRecoveryKey }
  } catch (e) {
    return handleError(e)
  }
}

export const readSystemRecoveryKey = async (
  systemRecoveryKey: string,
  systemPbk: string,
  systemPrk: string
) => {
  const sodium = await getSodium()
  try {
    const recoveryKey = sodium.crypto_box_seal_open(
      sodium.from_base64(systemRecoveryKey),
      sodium.from_base64(systemPbk),
      sodium.from_base64(systemPrk)
    )

    return { recoveryKey: await serializeRecoveryKey(recoveryKey) }
  } catch (e) {
    return handleError(e)
  }
}

export const createRecoveryKey = async (
  privateKeyEncrypted: string,
  privateKeyEncryptedNonce: string,
  passwordKey: string,
  recoveryKeyGenerated?: string
) => {
  const sodium = await getSodium()
  try {
    const recoveryKey = recoveryKeyGenerated
      ? await deserializeRecoveryKey(recoveryKeyGenerated)
      : sodium.randombytes_buf(RECOVERY_KEY_LENGTH)

    const privateKeyRecoveryNonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES)
    const encryptionKey = await convertRecoveryKeyToEncryptionKey(recoveryKey)

    const privateKey = sodium.crypto_secretbox_open_easy(
      sodium.from_base64(privateKeyEncrypted),
      sodium.from_base64(privateKeyEncryptedNonce),
      sodium.from_base64(passwordKey),
      'base64'
    )

    const privateKeyRecovery = sodium.crypto_secretbox_easy(
      sodium.from_base64(privateKey),
      privateKeyRecoveryNonce,
      sodium.from_base64(encryptionKey),
      'base64'
    )

    const payload = await encryptRecoveryKey(sodium.to_base64(recoveryKey), passwordKey)

    if (typeof payload === 'string') {
      throw new Error(payload)
    }

    const { recoveryKeyEncrypted, recoveryKeyEncryptedNonce } = payload
    const recoveryKeyPlain = await serializeRecoveryKey(recoveryKey)

    return {
      privateKeyRecovery: recoveryKeyGenerated ? null : privateKeyRecovery,
      privateKeyRecoveryNonce: recoveryKeyGenerated
        ? null
        : sodium.to_base64(privateKeyRecoveryNonce),
      recoveryKeyEncrypted,
      recoveryKeyEncryptedNonce,
      recoveryKeyPlain,
    }
  } catch (e) {
    return handleError(e)
  }
}

export const serializeRecoveryKey = async (recoveryKey: Uint8Array) => {
  const sodium = await getSodium()

  return serializePin(sodium.to_hex(recoveryKey))
}

export const deserializeRecoveryKey = async (recoveryKey: string) => {
  const sodium = await getSodium()

  return sodium.from_hex(deserializePin(recoveryKey))
}

const convertRecoveryKeyToEncryptionKey = async (recoveryKey: Uint8Array) => {
  const sodium = await getSodium()
  const buffer = new ArrayBuffer(RECOVERY_KEY_LENGTH * 2)
  const encryptionKey = new Uint8Array(buffer)
  encryptionKey.set(recoveryKey, 0)
  encryptionKey.set(recoveryKey, RECOVERY_KEY_LENGTH)

  return sodium.crypto_kdf_derive_from_key(
    RECOVERY_KEY_LENGTH * 2,
    1,
    RECOVERY_KEY_CONTEXT,
    encryptionKey,
    'base64'
  )
}
