import { downloadFile } from '@faceup/utils'
import { readReportKey } from './report'
import {
  FILE_CHUNK_ENCRYPTED_SIZE,
  FILE_CHUNK_SIZE,
  FILE_CHUNK_SUBKEY_ID_LEN,
  FILE_ENCRYPTION_CONTEXT,
} from './utils/constants'
import { getSodium, handleError } from './utils/general'

export type FileDescriptor = {
  url: string
  name: string
  mimetype: string
}

export const loadFileWithReportKey = async (
  { url, name, mimetype }: FileDescriptor,
  recipientKey: string
): Promise<File> => {
  const data = await fetch(url)
  const blob = await data.blob()
  const file = new File([blob], name, { type: mimetype })

  const payload = await readReportKey(recipientKey)

  if (typeof payload === 'string') {
    throw new Error(payload)
  }

  const { reportKey } = payload
  const decryptedFile = await decryptFileWithReportKey(file, reportKey)

  if (typeof decryptedFile === 'string') {
    throw new Error(decryptedFile)
  }
  return decryptedFile
}

export const downloadFileWithReportKey = async (
  file: FileDescriptor,
  recipientKey: string,
  // Define this function if standard download using `.click()` on created element is not available (e.g. mobile app)
  // biome-ignore lint/suspicious/noConfusingVoidType:
  download?: (blob: Blob, name: string) => Promise<string | void>
  // biome-ignore lint/suspicious/noConfusingVoidType:
): Promise<string | void> => {
  try {
    const decryptedFile = await loadFileWithReportKey(file, recipientKey)
    if (download) {
      return await download(decryptedFile, file.name)
    }
    return downloadFile(decryptedFile, file.name)
  } catch (e) {
    return (e as Error).message
  }
}

export const decryptFileWithReportKey = async (file: File, reportKey: Uint8Array) => {
  const sodium = await getSodium()

  try {
    const fileBuffer = await file.arrayBuffer()
    const header = new Uint8Array(
      fileBuffer.slice(0, sodium.crypto_secretstream_xchacha20poly1305_HEADERBYTES)
    )
    const subKeyIdBuffer = new Uint8Array(
      fileBuffer.slice(
        sodium.crypto_secretstream_xchacha20poly1305_HEADERBYTES,
        sodium.crypto_secretstream_xchacha20poly1305_HEADERBYTES + FILE_CHUNK_SUBKEY_ID_LEN
      )
    )
    const subKeyId = subKeyIdBuffer[0] ?? 0

    const fileKey = sodium.crypto_kdf_derive_from_key(
      sodium.crypto_secretstream_xchacha20poly1305_KEYBYTES,
      subKeyId,
      FILE_ENCRYPTION_CONTEXT,
      reportKey
    )

    const state = sodium.crypto_secretstream_xchacha20poly1305_init_pull(header, fileKey)
    const decryptedChunks: Uint8Array[] = []
    const offset = FILE_CHUNK_SUBKEY_ID_LEN + header.length

    let chunkCounter = 0
    do {
      const from = chunkCounter * FILE_CHUNK_ENCRYPTED_SIZE + offset
      const to = (chunkCounter + 1) * FILE_CHUNK_ENCRYPTED_SIZE + offset
      const chunk = new Uint8Array(fileBuffer.slice(from, to))
      const decryptedChunk = sodium.crypto_secretstream_xchacha20poly1305_pull(state, chunk, null)

      decryptedChunks.push(decryptedChunk.message)
      chunkCounter += 1
    } while (chunkCounter * FILE_CHUNK_ENCRYPTED_SIZE < file.size)

    return new File(decryptedChunks, file.name, { type: file.type })
  } catch (e) {
    return handleError(e)
  }
}

// this function is only used on backend, `stream` is not available in browser
export const encryptBufferWithReportKey = async (fileBuffer: Buffer, reportKey: Uint8Array) => {
  try {
    // dynamically import stream to avoid bundling it in browser
    const { Readable } = await import('node:stream')
    const sodium = await getSodium()

    const subkeyId = sodium.randombytes_uniform(255)
    const fileKey = sodium.crypto_kdf_derive_from_key(
      sodium.crypto_secretstream_xchacha20poly1305_KEYBYTES,
      subkeyId,
      FILE_ENCRYPTION_CONTEXT,
      reportKey
    )

    const { header, state } = sodium.crypto_secretstream_xchacha20poly1305_init_push(fileKey)
    const encryptedChunks: Uint8Array[] = []

    let chunkCounter = 0

    do {
      const from = chunkCounter * FILE_CHUNK_SIZE
      const to = (chunkCounter + 1) * FILE_CHUNK_SIZE

      const chunk = new Uint8Array(fileBuffer.slice(from, to))
      const tag =
        to > fileBuffer.byteLength
          ? sodium.crypto_secretstream_xchacha20poly1305_TAG_FINAL
          : sodium.crypto_secretstream_xchacha20poly1305_TAG_MESSAGE

      const encryptedChunk = sodium.crypto_secretstream_xchacha20poly1305_push(
        state,
        chunk,
        null,
        tag
      )

      encryptedChunks.push(encryptedChunk)
      chunkCounter += 1
    } while (chunkCounter * FILE_CHUNK_SIZE < fileBuffer.byteLength)

    const file = Buffer.concat([header, new Uint8Array([subkeyId]), ...encryptedChunks])

    return Readable.from(file)
  } catch {
    return null
  }
}

export const encryptFileWithReportKey = async (file: File, reportKey: Uint8Array) => {
  const sodium = await getSodium()

  try {
    const subkeyId = sodium.randombytes_uniform(255)
    const fileKey = sodium.crypto_kdf_derive_from_key(
      sodium.crypto_secretstream_xchacha20poly1305_KEYBYTES,
      subkeyId,
      FILE_ENCRYPTION_CONTEXT,
      reportKey
    )
    const { header, state } = sodium.crypto_secretstream_xchacha20poly1305_init_push(fileKey)
    const encryptedChunks: Uint8Array[] = []

    let chunkCounter = 0
    const fileBuffer = await file.arrayBuffer()
    do {
      const from = chunkCounter * FILE_CHUNK_SIZE
      const to = (chunkCounter + 1) * FILE_CHUNK_SIZE

      const chunk = new Uint8Array(fileBuffer.slice(from, to))
      const tag =
        to > file.size
          ? sodium.crypto_secretstream_xchacha20poly1305_TAG_FINAL
          : sodium.crypto_secretstream_xchacha20poly1305_TAG_MESSAGE

      const encryptedChunk = sodium.crypto_secretstream_xchacha20poly1305_push(
        state,
        chunk,
        null,
        tag
      )

      encryptedChunks.push(encryptedChunk)
      chunkCounter += 1
    } while (chunkCounter * FILE_CHUNK_SIZE < file.size)

    return new File([header, new Uint8Array([subkeyId]), ...encryptedChunks], file.name, {
      type: file.type,
    })
  } catch (e) {
    return handleError(e)
  }
}
