import type { UntitledIconData } from '../../UntitledIconData'

const usAttachment01: UntitledIconData = {
  prefix: 'us',
  name: 'attachment-01',
  paths: [
    {
      shape:
        'M17.9705 4.18194C16.9942 3.20563 15.4113 3.20563 14.435 4.18194L5.41936 13.1976C3.75963 14.8573 3.75963 17.5482 5.41936 19.208C7.07908 20.8677 9.77004 20.8677 11.4298 19.208L20.4454 10.1923C20.8359 9.80182 21.4691 9.80182 21.8596 10.1923C22.2501 10.5829 22.2501 11.216 21.8596 11.6066L12.844 20.6222C10.4032 23.0629 6.44592 23.0629 4.00514 20.6222C1.56437 18.1814 1.56437 14.2241 4.00514 11.7833L13.0208 2.76773C14.7781 1.01037 17.6274 1.01037 19.3847 2.76773C21.1421 4.52509 21.1421 7.37433 19.3847 9.13169L10.7227 17.7937C9.64872 18.8677 7.90751 18.8677 6.83357 17.7937C5.75963 16.7198 5.75963 14.9786 6.83357 13.9047L14.435 6.30326C14.8255 5.91274 15.4587 5.91274 15.8492 6.30326C16.2397 6.69378 16.2397 7.32695 15.8492 7.71747L8.24778 15.3189C7.95489 15.6118 7.95489 16.0866 8.24778 16.3795C8.54068 16.6724 9.01555 16.6724 9.30844 16.3795L17.9705 7.71747C18.9468 6.74116 18.9468 5.15825 17.9705 4.18194Z',
    },
  ],
}

export default usAttachment01
