import styled from '@emotion/styled'
import { UntitledIcon } from '@faceup/icons'
import { usMicrophone01 } from '@faceup/icons/usMicrophone01'
import { usTrash04 } from '@faceup/icons/usTrash04'
// This button is so specific so we leave it here for now.

import { Box, Button } from '@mantine/core'
import moment from 'moment-timezone'
import { useContext } from 'react'
import { ReportFormContext } from '../../Contexts/ReportFormContext'
import { FormattedMessage, defineMessages } from '../../TypedIntl'

const messages = defineMessages({
  startVoiceRecording: 'FollowUp.voiceRecording.button.start',
  recorded: 'FollowUp.voiceRecording.button.recorded',
})

type Props = {
  open: () => void
}

const VoiceRecordingButton = ({ open }: Props) => {
  const { record, setRecord } = useContext(ReportFormContext)

  const isRecorded = Boolean(record)
  const readableDuration = moment.utc(record?.duration ?? 0).format(`m:ss`)

  return (
    <Wrapper>
      <Box
        sx={theme => ({
          borderRadius: '1rem',
          backgroundColor: theme.white,
          display: 'flex',
        })}
      >
        <Button
          onClick={() => open()}
          leftIcon={<UntitledIcon icon={usMicrophone01} fontSize={18} />}
          variant='light'
          radius='xl'
          styles={{ label: { marginBlock: '0.4rem' } }}
          sx={{ height: 'unset' }}
        >
          {isRecorded ? (
            <>
              <FormattedMessage {...messages.recorded} /> - {readableDuration}
            </>
          ) : (
            <FormattedMessage {...messages.startVoiceRecording} />
          )}
        </Button>
      </Box>
      {isRecorded && <Basket icon={usTrash04} onClick={() => setRecord(null)} />}
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  cursor: pointer;
  transition: 0.3s;
  background: #ff4d4f;
`

const Basket = styled(UntitledIcon)`
  font-size: 1.1rem;
  padding: 0 0.5rem 0 0.25rem;
  color: #fff;
`

export default VoiceRecordingButton
