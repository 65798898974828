import type { MantineSize } from '@mantine/styles/lib/theme/types/MantineSize'

export const sizeSettings = {
  lg: 16,
  md: 16,
  sm: 20,
  xs: 24,
}

export const maxAppWidth = 1350

export const headerPadding = 32

export const desktopBreakpoint = 'sm'
export type LayoutVariant = 'simple' | 'complex'
export type HeaderVariant = 'complex' | 'simple' | 'simple-no-back-button'

export const panelWidth: Record<LayoutVariant, Partial<Record<MantineSize, number>>> = {
  simple: {
    sm: 50,
    md: 150,
  },
  complex: {
    sm: 100,
    md: 261,
  },
}
