import { gql } from '@apollo/client'
import { Box, Burger, Flex, Group, MediaQuery } from '@mantine/core'
import { useContext } from 'react'
import { LayoutContext } from '../../../../../Contexts/LayoutContext'
import type { HeaderComplexWithoutPages_reportSource } from '../../../../../__generated__/globalTypes'
import { desktopBreakpoint } from '../../../../../utils/constants'
import LanguageDropdown, {
  LanguageDropdownFragments,
} from '../../../../LanguageDropdown/LanguageDropdown'
import { LogoutButton } from '../../../../LogoutButton'
import { PartnerInstitutionLogo, PartnerInstitutionLogoFragments } from './PartnerInstitutionLogo'

export const HeaderComplexWithoutPagesFragments = {
  HeaderComplexWithoutPages_reportSource: gql`
    fragment HeaderComplexWithoutPages_reportSource on PublicReportSource {
      id
      ...LanguageDropdown_reportSource
      ...PartnerInstitutionLogo_reportSource
    }
    ${LanguageDropdownFragments.LanguageDropdown_reportSource}
    ${PartnerInstitutionLogoFragments.PartnerInstitutionLogo_reportSource}
  `,
}

type HeaderComplexWithoutPagesProps = {
  reportSource: HeaderComplexWithoutPages_reportSource
  isMenuOpened: boolean
  setIsMenuOpened: (isOpened: boolean) => void
}

export const HeaderComplexWithoutPages = (props: HeaderComplexWithoutPagesProps) => {
  const { reportSource: institution, isMenuOpened, setIsMenuOpened } = props
  const { client } = useContext(LayoutContext)
  const isApp = client === 'app'

  return (
    <Group
      sx={{
        width: '100%',
      }}
      position='apart'
      grow
    >
      <Box />
      <Flex justify='center'>
        <PartnerInstitutionLogo reportSource={institution} />
      </Flex>

      <Flex justify='right'>
        <MediaQuery
          smallerThan={desktopBreakpoint}
          styles={{
            display: 'none',
          }}
        >
          <Box>
            {institution.languages.length > 1 && (
              <LanguageDropdown
                availableLanguages={institution.languages ?? []}
                defaultLanguage={institution.defaultLanguage}
              />
            )}
            {isApp && (
              <LogoutButton
                sx={{
                  color: '#232323',
                }}
              />
            )}
          </Box>
        </MediaQuery>
        <MediaQuery
          largerThan={desktopBreakpoint}
          styles={{
            display: 'none',
          }}
        >
          <Burger
            opened={isMenuOpened}
            onClick={() => setIsMenuOpened(!isMenuOpened)}
            color='#232323'
            size={22}
          />
        </MediaQuery>
      </Flex>
    </Group>
  )
}
