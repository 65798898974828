import { BiometryType, NativeBiometric } from '@capgo/capacitor-native-biometric'
import styled from '@emotion/styled'
import { useNavigate } from '@faceup/router'
import { Button } from '@faceup/ui'
import { Icon, Typography } from '@faceup/ui-base'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../Contexts/UserContext'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import FaceIdIcon from './assets/faceid-icon.svg?react'
import FingerprintIcon from './assets/fingerprint-icon.svg?react'

const { Title } = Typography

const messages = defineMessages({
  titleFingerprint: 'FollowUp.welcome.biometrics.title.fingerprint',
  titleFaceId: 'FollowUp.welcome.biometrics.title.faceId',
  descriptionFingerprint: 'FollowUp.welcome.biometrics.description.fingerprint',
  descriptionFaceId: 'FollowUp.welcome.biometrics.description.faceId',
  enableFingerprint: 'FollowUp.welcome.biometrics.enable.fingerprint',
  enableFaceId: 'FollowUp.welcome.biometrics.enable.faceId',
  disableFingerprint: 'FollowUp.welcome.biometrics.disable.fingerprint',
  disableFaceId: 'FollowUp.welcome.biometrics.disable.faceId',
})

const Biometrics = () => {
  const {
    updateSettings: { setBiometrics },
  } = useContext(UserContext)
  const [type, setType] = useState<'fingerprint' | 'faceId'>('faceId')
  const navigate = useNavigate()

  const verify = async () => {
    try {
      await NativeBiometric.verifyIdentity()
      setTimeout(() => enable(true), 300)
    } catch {
      // ignore empty catch
    }
  }

  const enable = (enabled: boolean) => {
    setBiometrics(enabled)
    navigate(routes => routes.home())
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies(enable):
  useEffect(() => {
    const checkAvailibility = async () => {
      try {
        const config = await NativeBiometric.isAvailable()
        if (!config.isAvailable) {
          enable(false)
        }

        setType(
          [BiometryType.FACE_ID, BiometryType.FACE_AUTHENTICATION].includes(config.biometryType)
            ? 'faceId'
            : 'fingerprint'
        )
      } catch {
        enable(false)
      }
    }

    checkAvailibility()
  }, [])

  return (
    <Wrapper>
      <TopWrapper>
        <BioemtricsIcon component={type === 'faceId' ? FaceIdIcon : FingerprintIcon} />
        <Title level={2}>
          <FormattedMessage
            {...(type === 'faceId' ? messages.titleFaceId : messages.titleFingerprint)}
          />
        </Title>
        <Description>
          <FormattedMessage
            {...(type === 'faceId' ? messages.descriptionFaceId : messages.descriptionFingerprint)}
          />
        </Description>
      </TopWrapper>
      <BottomWrapper>
        <Button isFullWidth onClick={verify}>
          <FormattedMessage
            {...(type === 'faceId' ? messages.enableFaceId : messages.enableFingerprint)}
          />
        </Button>
        <DisableBiometrics onClick={() => enable(false)}>
          <FormattedMessage
            {...(type === 'faceId' ? messages.disableFaceId : messages.disableFingerprint)}
          />
        </DisableBiometrics>
      </BottomWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: nowrap column;
  justify-content: space-between;
  min-height: 100vh;
  max-width: 600px;
  width: 100%;
  margin: auto;
  padding: 1.25rem;
`

const TopWrapper = styled.div`
  text-align: center;
  padding-top: 15%;
  width: 100%;
`

const BottomWrapper = styled.div`
  text-align: center;
  padding-bottom: 1rem;
  width: 100%;
`

const Description = styled.div`
  font-size: 1.2rem;
  margin-bottom: 2rem;
`

const BioemtricsIcon = styled(Icon)`
  font-size: 6.25rem;
  margin-bottom: 2rem;
`

const DisableBiometrics = styled.div`
  margin-top: 2rem;
  font-size: 1.2rem;
  cursor: pointer;
`

export default Biometrics
