export const downloadFile = (file: Blob, name: string) => {
  const anchor = document.createElement('a')
  const url = URL.createObjectURL(file)

  anchor.href = url
  anchor.download = name

  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)

  return URL.revokeObjectURL(url)
}
