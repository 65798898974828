import type { PreviewCustomValues } from '@faceup/report'
import { useNavigate } from '@faceup/router'
import { useEffect, useState } from 'react'
import { isReferrerAkutan } from '../utils/documentReferrer'

export type CustomValues = PreviewCustomValues
export const useCustomValues = () => {
  const [customValues, setCustomValues] = useState<CustomValues>()
  const navigate = useNavigate()
  // biome-ignore lint/correctness/useExhaustiveDependencies(navigate):
  // biome-ignore lint/correctness/useExhaustiveDependencies(setCustomValues): Probably not needed
  useEffect(() => {
    const messageHandler = (e: MessageEvent) => {
      // ignore everything without specified source
      // this accidentally handled e.g. React dev tools messages
      // setting state ad infinitum and leading to infinite renders
      if (!isReferrerAkutan || e.data.source !== 'customization') {
        return
      }
      if (typeof e.data === 'object') {
        setCustomValues(e.data)
      }
    }
    window.addEventListener('message', messageHandler)

    return () => window.removeEventListener('message', messageHandler)
  }, [navigate, setCustomValues])

  return customValues
}
