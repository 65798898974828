import { type Routes, generatePath } from '@faceup/router'
import { useContext } from 'react'
import { LanguageContext } from '../Contexts/LanguageContext'

export const routes: Record<keyof RoutesInterface, string> = {
  home: `:language`,
  report: `:language/report`,
  createReport: `:language/create`,
  checkReport: `:language/check`,
  checkReportForm: `:language/check/form`,
  sentReport: `:language/sent`,
  page: `:language/page/:id?`,
  settingsBiometrics: `:language/settings/biometrics`,
  shortIdCompany: `:language/c/:shortId/check`,
  shortIdSchool: `:language/s/:shortId/check`,
  survey: `:language/survey/:id`,
}

export const useRoutes = (): Routes => {
  const { basename: language } = useContext(LanguageContext)
  return {
    home: () => generatePath(routes.home, { language }),
    report: () => generatePath(routes.report, { language }),
    createReport: () => generatePath(routes.createReport, { language }),
    checkReport: () => generatePath(routes.checkReport, { language }),
    checkReportForm: () => generatePath(routes.checkReportForm, { language }),
    sentReport: () => generatePath(routes.sentReport, { language }),
    page: (id?: string) => generatePath(routes.page, { language, id }),
    settingsBiometrics: () => generatePath(routes.settingsBiometrics, { language }),
    shortIdCompany: (shortId: string) => generatePath(routes.shortIdCompany, { language, shortId }),
    shortIdSchool: (shortId: string) => generatePath(routes.shortIdSchool, { language, shortId }),
    survey: (id: string) => generatePath(routes.survey, { language, id }),
  }
}

interface RoutesInterface {
  home: () => string
  report: () => string
  createReport: () => string
  checkReport: () => string
  checkReportForm: () => string
  sentReport: () => string
  page: (id?: string) => string
  settingsBiometrics: () => string
  shortIdCompany: (shortId: string) => string
  shortIdSchool: (shortId: string) => string
  survey: (id: string) => string
}

declare module '@faceup/router' {
  export interface RoutesOverride extends RoutesInterface {}
}
