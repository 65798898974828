import { gql, useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { Link, useParams } from '@faceup/router'
import { Button } from '@faceup/ui'
import { Space, Typography } from '@faceup/ui-base'
import { Loader } from '@mantine/core'
import { useEffect, useState } from 'react'
import { FormattedMessage, defineMessages } from '../TypedIntl'
import type {
  EditIsVerifiedCompanyVictimMutation,
  EditIsVerifiedCompanyVictimMutationVariables,
} from '../__generated__/globalTypes'
import useRegion from '../utils/useRegion'
import PlainLayout from './PlainLayout'

const { Title, Text } = Typography

const messages = defineMessages({
  continue: 'FollowUp.global.continue',
  successVerification: 'FollowUp.report.notification.successVerification',
  failedVerification: 'FollowUp.report.notification.failedVerification',
  successEmailVerificationInfo: 'FollowUp.report.notification.successVerificationInfo',
  failedEmailVerificationInfo: 'FollowUp.report.notification.failedVerificationInfo',
})

const mutations = {
  EditIsVerifiedCompanyVictim: gql`
    mutation EditIsVerifiedCompanyVictimMutation($input: EditIsVerifiedCompanyVictimInput!) {
      editIsVerifiedCompanyVictim(input: $input) {
        success
      }
    }
  `,
}

const EmailVerification = () => {
  const [success, setSuccess] = useState(true)
  const [wasCalled, setWasCalled] = useState(false)
  const { discoverByToken } = useRegion()
  const { id: token } = useParams<'id'>()

  const [editIsCompanyVerified, { loading }] = useMutation<
    EditIsVerifiedCompanyVictimMutation,
    EditIsVerifiedCompanyVictimMutationVariables
  >(mutations.EditIsVerifiedCompanyVictim, {
    variables: {
      input: {
        token: token ?? '',
      },
    },
    onCompleted: ({ editIsVerifiedCompanyVictim }) => {
      const newSuccess = editIsVerifiedCompanyVictim?.success ?? false
      setSuccess(newSuccess)
    },
  })

  useEffect(() => {
    const fetchToken = async () => {
      if (token && discoverByToken && editIsCompanyVerified && !wasCalled) {
        setWasCalled(true)
        await discoverByToken(token)
        await editIsCompanyVerified()
      }
    }
    fetchToken()
  }, [discoverByToken, editIsCompanyVerified, token, wasCalled])

  return (
    <PlainLayout>
      <Wrapper>
        <Space direction='vertical' align='center' size={40}>
          {loading || !wasCalled ? (
            <Loader />
          ) : (
            <Space direction='vertical' align='center' size='small'>
              <Title level={2} style={{ display: 'inline-block' }}>
                {success ? (
                  <FormattedMessage {...messages.successVerification} />
                ) : (
                  <FormattedMessage {...messages.failedVerification} />
                )}
              </Title>
              <Text style={{ fontSize: 16 }}>
                {success ? (
                  <FormattedMessage {...messages.successEmailVerificationInfo} />
                ) : (
                  <FormattedMessage {...messages.failedEmailVerificationInfo} />
                )}
              </Text>
            </Space>
          )}
          <Link to={routes => routes.report()}>
            <Button loading={loading}>
              <FormattedMessage {...messages.continue} />
            </Button>
          </Link>
        </Space>
      </Wrapper>
    </PlainLayout>
  )
}

const Wrapper = styled.div`
  margin: auto;
`

export default EmailVerification
