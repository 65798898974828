import styled from '@emotion/styled'
import { Typography } from '@faceup/ui-base'

const { Title } = Typography

type Props = {
  children: React.ReactNode
  description?: React.ReactNode
  style?: React.CSSProperties
}

const PageTitle = ({ children, description, ...rest }: Props) => (
  <TitleWrapper {...rest}>
    <Title data-cy='page-title'>{children}</Title>
    {description && <span data-cy='page-description'>{description}</span>}
  </TitleWrapper>
)

const TitleWrapper = styled.div`
  margin-bottom: 40px;
  text-align: center;
  width: 100%;

  h1 {
    margin-bottom: 20px;
  }
`

export default PageTitle
