import type { UntitledIconData } from '../../UntitledIconData'

const ulLogOut01: UntitledIconData = {
  prefix: 'ul',
  name: 'log-out-01',
  paths: [
    {
      shape:
        'M16 17L21 12M21 12L16 7M21 12H9M9 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H9',
    },
  ],
}

export default ulLogOut01
