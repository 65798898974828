import styled from '@emotion/styled'
import Markdown from 'markdown-to-jsx'
import type { ReactNode } from 'react'
import PageTitle from '../../../Components/PageTitle'
import PlainLayout from '../../../Components/PlainLayout'

type LayoutProps = {
  title: ReactNode
  content: string
}

export const Layout = ({ title, content }: LayoutProps) => (
  <PlainLayout>
    <Title>{title}</Title>
    <ContentWrapper>
      <Markdown>{content}</Markdown>
    </ContentWrapper>
  </PlainLayout>
)

const Title = styled(PageTitle)`
  margin: auto;
  max-width: 700px;
`

const ContentWrapper = styled.div`
  align-self: center;
  max-width: 700px;
  text-align: justify;

  h2 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
  }
`
