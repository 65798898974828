import { Typography } from '@faceup/ui-base'
import type { ReactNode } from 'react'

type ReportRowProps = {
  label: ReactNode
  children: ReactNode
  dataCy?: string
}

export const ReportRow = ({ label, children, dataCy }: ReportRowProps) => (
  <div>
    <Typography.Text strong style={{ color: '#688699', display: 'block' }}>
      {label}
    </Typography.Text>
    <Typography.Text
      strong
      style={{ fontSize: 16, whiteSpace: 'pre-wrap' }}
      {...(dataCy && { 'data-cy': dataCy })}
    >
      {children}
    </Typography.Text>
  </div>
)
