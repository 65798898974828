import { gql } from '@apollo/client'
import { defaultLogoSize } from '@faceup/utils'
import { Divider, Group } from '@mantine/core'
import type { PartnerInstitutionLogo_reportSource } from '../../../../../__generated__/globalTypes'
import { useCustomValues } from '../../../../../hooks/useCustomValues'
import InstitutionLogo, { InstitutionLogoFragments } from '../../../../InstitutionLogo'

export const PartnerInstitutionLogoFragments = {
  PartnerInstitutionLogo_reportSource: gql`
    fragment PartnerInstitutionLogo_reportSource on PublicReportSource {
      id
      logoUrl
      logoSize
      institution {
        id

        partner {
          id
          config {
            id
            rectangularLogoUrl
          }

          ...InstitutionLogoFragments_partner
        }

        ...InstitutionLogoFragments_institution
      }
    }
    ${InstitutionLogoFragments.InstitutionLogoFragments_institution}
    ${InstitutionLogoFragments.InstitutionLogoFragments_partner}
  `,
}

type PartnerInstitutionLogoProps = {
  reportSource: PartnerInstitutionLogo_reportSource
}

export const PartnerInstitutionLogo = (props: PartnerInstitutionLogoProps) => {
  const { reportSource } = props
  const customValues = useCustomValues()
  const shownLogoSize = customValues?.logoSize ?? reportSource.logoSize ?? defaultLogoSize
  const logoUrl =
    customValues?.logoBase64 ?? (customValues?.logoBase64 === null ? null : reportSource.logoUrl)
  return (
    <Group noWrap>
      <InstitutionLogo
        institutionOrPartner={reportSource.institution ? reportSource.institution : null}
        logoUrl={logoUrl}
        logoSize={shownLogoSize}
      />
      {reportSource.institution?.partner?.config?.rectangularLogoUrl && (
        <>
          <Divider orientation='vertical' />
          <InstitutionLogo
            institutionOrPartner={reportSource.institution?.partner}
            logoUrl={reportSource.institution?.partner?.config?.rectangularLogoUrl}
            logoSize={shownLogoSize}
          />
        </>
      )}
    </Group>
  )
}
