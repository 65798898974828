import styled from '@emotion/styled'
import { useLocation } from '@faceup/router'
import { Box, Header as MantineHeader, useMantineTheme } from '@mantine/core'
import { useElementSize, useMediaQuery } from '@mantine/hooks'
import { type ReactNode, useEffect, useState } from 'react'
import { FormattedMessage, defineMessages } from '../TypedIntl'
import { desktopBreakpoint, maxAppWidth } from '../utils/constants'
import useNetworkStatus from '../utils/useNetworkStatus'

const messages = defineMessages({
  offlineBanner: 'FollowUp.offline.banner',
})

type HeaderProps = { children: ReactNode }

export const Header = (props: HeaderProps) => {
  const { children } = props
  const [scrolling, setScrolling] = useState(false)
  const networkStatus = useNetworkStatus()
  const { pathname } = useLocation()
  const { ref, height } = useElementSize()
  const { breakpoints } = useMantineTheme()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints[desktopBreakpoint]})`)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY <= 5 && scrolling) {
        setScrolling(false)
      } else if (window.scrollY > 5 && !scrolling) {
        setScrolling(true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [scrolling])

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 105,
        maxWidth: maxAppWidth,
        width: '100%',
        margin: 'auto',
        background: 'transparent',
      }}
    >
      <MantineHeader height={height} withBorder={false} sx={{ zIndex: 105 }}>
        <Box />
      </MantineHeader>
      <HeaderWrapper ref={ref} shadow={scrolling || !isDesktop}>
        <InnerHeader>{children}</InnerHeader>
        {networkStatus === 'offline' && pathname !== '/offline' && (
          <OfflineBanner>
            <FormattedMessage {...messages.offlineBanner} />
          </OfflineBanner>
        )}
      </HeaderWrapper>
    </Box>
  )
}

export const HeaderWrapper = styled('div', { shouldForwardProp: prop => prop !== 'shadow' })<{
  shadow?: boolean
}>`
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.3s;
  z-index: 110;
  box-shadow: ${({ shadow }) => (shadow ? '0px 7px 7px rgba(0, 0, 0, 0.05) !important' : 'none')};
`

export const InnerHeader = styled.div`
  display: flex;
  flex-flow: nowrap row;
  justify-content: space-between;
  align-items: center;

  position: relative;
  max-width: ${maxAppWidth}px;
  width: 100%;
  margin: auto;
  background: transparent;
  padding: 24px;

  h4 {
    line-height: 34px;
  }
`

export const OfflineBanner = styled.div`
  background: #fef2f2;
  width: 100%;
  color: #ef4a45;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  justify-content: center;
  padding: 6px 20px;
`
