import { UntitledIcon } from '@faceup/icons'
import { ulLogOut01 } from '@faceup/icons/ulLogOut01'
import { Button } from '@faceup/ui'
import type { Sx } from '@mantine/styles/lib/theme/types/DefaultProps'
import { useContext } from 'react'
import { UserContext } from '../Contexts/UserContext'
import { FormattedMessage, defineMessages } from '../TypedIntl'

const messages = defineMessages({
  logout: 'Shared.logout',
})

type LogoutButtonProps = {
  sx?: Sx
}

export const LogoutButton = (props: LogoutButtonProps) => {
  const { sx } = props
  const { logout } = useContext(UserContext)

  return (
    <Button
      variant='text'
      iconBefore={<UntitledIcon icon={ulLogOut01} />}
      isCompact
      onClick={logout}
      sx={sx}
    >
      <FormattedMessage {...messages.logout} />
    </Button>
  )
}
