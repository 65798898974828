import type { Mp3Encoder } from '@breezystack/lamejs'

// if you want to know more about the audio encoding (wav), check file history

const inlineProcessor = `
class RecorderProcessor extends AudioWorkletProcessor {
    constructor() {
      super();
      this.bufferSize = 1152;
      this.buffer = new Float32Array(this.bufferSize);
      this.bytesWritten = 0;
      this.bytesWritten = 0;
      this.port.onmessage = (e) => {
        const data = e.data;
        switch (data.action) {
          case "stop":
            this._flush();
            break;
        }
      };
    }
    process(inputs) {
      const samples = inputs[0][0];
      if (!samples)
        return true;
      for (let i = 0; i < samples.length; i++) {
        this.buffer[this.bytesWritten++] = samples[i];
        if (this.bytesWritten >= this.bufferSize) {
          this._flush();
        }
      }
      return true;
    }
    _flush() {
      const buffer = this.bytesWritten < this.bufferSize
        ? this.buffer.slice(0, this.bytesWritten)
        : this.buffer;
      if (buffer.length) {
        this.port.postMessage({
          action: "encode",
          buffer
        });
      }
      this.bytesWritten = 0;
    }
  };
  registerProcessor("recorder.processor", RecorderProcessor);
`

export const createRecorderProcessor = async (context: BaseAudioContext) => {
  try {
    return new AudioWorkletNode(context, 'recorder.processor')
  } catch {
    const workletUrl = URL.createObjectURL(
      new Blob([inlineProcessor], { type: 'application/javascript;charset=utf8' })
    )
    await context.audioWorklet.addModule(workletUrl)
    return new AudioWorkletNode(context, 'recorder.processor')
  }
}

// code for audio recording from here https://github.com/closeio/mic-recorder-to-mp3/pull/44
export class LameEncoder {
  private dataBuffer: Int8Array[] = []
  private mp3Encoder: Mp3Encoder | null = null
  private config: {
    sampleRate?: number
    bitRate?: number
  }
  private maxSamples: number
  private samplesMono: Int16Array | null

  constructor(config: { sampleRate: number; bitRate: number }) {
    this.config = config

    // Audio is processed by frames of 1152 samples per audio channel
    // http://lame.sourceforge.net/tech-FAQ.txt
    this.maxSamples = 1152

    this.samplesMono = null
    this.clearBuffer()
  }

  public get isInitialized() {
    return !!this.mp3Encoder
  }

  public async initialize() {
    // we use this fork, because of `ReferenceError: MPEGMode is not defined` https://github.com/zhuker/lamejs/issues/91
    const { default: lamejs } = await import('@breezystack/lamejs')
    this.mp3Encoder = new lamejs.Mp3Encoder(
      1,
      this.config.sampleRate ?? 0,
      this.config.bitRate ?? 0
    )

    return this
  }

  // Clear active buffer
  public clearBuffer() {
    this.dataBuffer = []
  }

  // Append new audio buffer to current active buffer
  private appendToBuffer(buffer: Uint8Array) {
    this.dataBuffer.push(new Int8Array(buffer))
  }

  // Float current data to 16 bits PCM
  private floatTo16BitPCM(input: Float32Array, output: Int16Array) {
    for (let i = 0; i < input.length; i++) {
      const s = Math.max(-1, Math.min(1, input[i] ?? 0))
      output[i] = s < 0 ? s * 0x8000 : s * 0x7fff
    }
  }

  // Convert buffer to proper format
  private convertBuffer(arrayBuffer: ArrayBuffer) {
    const data = new Float32Array(arrayBuffer)
    // @ts-expect-error arrayBuffer has length
    const out = new Int16Array(arrayBuffer.length)
    this.floatTo16BitPCM(data, out)

    return out
  }

  // Encode and append current buffer to dataBuffer
  public encode(arrayBuffer: ArrayBuffer) {
    if (!this.mp3Encoder) {
      throw new Error('LameEncoder does not exist')
    }

    this.samplesMono = this.convertBuffer(arrayBuffer)
    let remaining = this.samplesMono.length

    for (let i = 0; remaining >= 0; i += this.maxSamples) {
      const left = this.samplesMono.subarray(i, i + this.maxSamples)
      const mp3buffer = this.mp3Encoder.encodeBuffer(left)
      this.appendToBuffer(mp3buffer)
      remaining -= this.maxSamples
    }
  }

  // Return full dataBuffer
  public finish() {
    if (!this.mp3Encoder) {
      throw new Error('LameEncoder does not exist')
    }

    this.appendToBuffer(this.mp3Encoder.flush())

    return this.dataBuffer
  }
}
