import { type FFmpeg, createFFmpeg } from '@ffmpeg/ffmpeg'

type WindowWithFFmpeg = typeof window & {
  ffmpeg: FFmpeg
}

export const getFFmpeg = async () => {
  const w = window as WindowWithFFmpeg
  w.ffmpeg = w.ffmpeg || (await loadFFmpeg())
  return w.ffmpeg
}

const loadFFmpeg = async () => {
  const ffmpeg = createFFmpeg({
    corePath: '/scripts/ffmpeg-core.js',
  })
  await ffmpeg.load()
  return ffmpeg
}
