import {
  ALLOWED_FILE_MIME_TYPE_EXTENSION_MAP,
  ALLOWED_FILE_MIME_TYPE_EXTENSION_PLUS_DEPRECATED_MAP,
  ALLOWED_IMAGE_MIME_TYPE_EXTENSION_MAP,
} from '../validationConfig'

export const hasFileValidExtension = (
  filename: string,
  mimeType: string,
  type: 'all' | 'image' | 'all+deprecated' = 'all'
) => {
  // we are unable to use `.at()`, because it is not transpiled
  const extension = (filename.split('.')[filename.split('.').length - 1] ?? '').toLowerCase()
  const fileMimetypes =
    type === 'all+deprecated'
      ? ALLOWED_FILE_MIME_TYPE_EXTENSION_PLUS_DEPRECATED_MAP
      : type === 'all'
        ? ALLOWED_FILE_MIME_TYPE_EXTENSION_MAP
        : ALLOWED_IMAGE_MIME_TYPE_EXTENSION_MAP
  const validExtensions = fileMimetypes[mimeType as keyof typeof fileMimetypes] ?? []

  return validExtensions.includes(extension)
}
