import { useLocation } from '@faceup/router'
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'

export const useMenuOpen = (): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const location = useLocation()

  /**
   * Close menu on route change
   */

  // biome-ignore lint/correctness/useExhaustiveDependencies(location): Needed for closing the menu on route change
  useEffect(() => {
    setMenuOpen(false)
  }, [location])

  return [menuOpen, setMenuOpen]
}
