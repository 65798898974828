import type { UntitledIconData } from '@faceup/icons'
import { ulAlertCircle } from '@faceup/icons/ulAlertCircle'
import { ulAnnotationInfo } from '@faceup/icons/ulAnnotationInfo'
import { ulAnnouncement01 } from '@faceup/icons/ulAnnouncement01'
import { ulBookOpen01 } from '@faceup/icons/ulBookOpen01'
import { ulBuilding05 } from '@faceup/icons/ulBuilding05'
import { ulBuilding08 } from '@faceup/icons/ulBuilding08'
import { ulCertificate01 } from '@faceup/icons/ulCertificate01'
import { ulClipboardCheck } from '@faceup/icons/ulClipboardCheck'
import { ulFile02 } from '@faceup/icons/ulFile02'
import { ulFile05 } from '@faceup/icons/ulFile05'
import { ulFileCheck02 } from '@faceup/icons/ulFileCheck02'
import { ulFileHeart03 } from '@faceup/icons/ulFileHeart03'
import { ulFileLock03 } from '@faceup/icons/ulFileLock03'
import { ulFileQuestion03 } from '@faceup/icons/ulFileQuestion03'
import { ulFileShield03 } from '@faceup/icons/ulFileShield03'
import { ulFlag02 } from '@faceup/icons/ulFlag02'
import { ulHeartCircle } from '@faceup/icons/ulHeartCircle'
import { ulHelpCircle } from '@faceup/icons/ulHelpCircle'
import { ulHome01 } from '@faceup/icons/ulHome01'
import { ulHomeLine } from '@faceup/icons/ulHomeLine'
import { ulInfoCircle } from '@faceup/icons/ulInfoCircle'
import { ulLifeBuoy01 } from '@faceup/icons/ulLifeBuoy01'
import { ulMedicalSquare } from '@faceup/icons/ulMedicalSquare'
import { ulPhone } from '@faceup/icons/ulPhone'
import { ulShieldTick } from '@faceup/icons/ulShieldTick'
import { ulUsers02 } from '@faceup/icons/ulUsers02'
import type { PageIcon } from '@faceup/utils'

export const pagesIcons: Record<PageIcon | string, UntitledIconData> = {
  home: ulHome01,
  homeLine: ulHomeLine,
  heartCircle: ulHeartCircle,
  helpCircle: ulHelpCircle,
  infoCircle: ulInfoCircle,
  lifeBuoy: ulLifeBuoy01,
  building1: ulBuilding05,
  building2: ulBuilding08,
  medicalSquare: ulMedicalSquare,
  announcement: ulAnnouncement01,
  alertCircle: ulAlertCircle,
  file1: ulFile05,
  file2: ulFile02,
  fileCheck: ulFileCheck02,
  fileQuestion: ulFileQuestion03,
  fileHeart: ulFileHeart03,
  clipboardCheck: ulClipboardCheck,
  certificate: ulCertificate01,
  bookOpen: ulBookOpen01,
  flag: ulFlag02,
  fileShield: ulFileShield03,
  fileLock: ulFileLock03,
  shieldTick: ulShieldTick,
  phone: ulPhone,
  annotationInfo: ulAnnotationInfo,
  users: ulUsers02,
}
