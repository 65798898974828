import { Network } from '@capacitor/network'
import { useEffect, useState } from 'react'

const useNetworkStatus = () => {
  const [networkStatus, setNetworkStatus] = useState<undefined | boolean>(undefined)

  useEffect(() => {
    const checkStatus = async () => {
      const status = await Network.getStatus()
      setNetworkStatus(status.connected)
    }

    checkStatus()
  }, [])

  useEffect(() => {
    Network.addListener('networkStatusChange', status => {
      setNetworkStatus(status.connected)
    })

    return () => {
      Network.removeAllListeners()
    }
  }, [])

  // Network status is uppon load undefined
  if (networkStatus) {
    return 'online'
  }

  return networkStatus === undefined ? undefined : 'offline'
}

export default useNetworkStatus
