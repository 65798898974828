import type { UntitledIconData } from '../../UntitledIconData'

const ulTranslate02: UntitledIconData = {
  prefix: 'ul',
  name: 'translate-02',
  paths: [
    {
      shape:
        'M5 8L10 13M4 14L10 8L12 5M2 5H14M7 2H8M12.913 17H20.087M12.913 17L11 21M12.913 17L15.7783 11.009C16.0092 10.5263 16.1246 10.2849 16.2826 10.2086C16.4199 10.1423 16.5801 10.1423 16.7174 10.2086C16.8754 10.2849 16.9908 10.5263 17.2217 11.009L20.087 17M20.087 17L22 21',
    },
  ],
}

export default ulTranslate02
