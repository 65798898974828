import { App } from '@capacitor/app'
import { Dialog } from '@capacitor/dialog'
import { SplashScreen } from '@capacitor/splash-screen'
import { type BundleInfo, CapacitorUpdater } from '@capgo/capacitor-updater'
import { version } from '../../package.json'
import { isPurePWA } from '../Contexts/LayoutProvider'

// we have to use nntb domain, because only on Android, requests to app.faceup.com is intercepted and server returns files as local ones (eg. error 404)
// we need to get fresh version for `OTA` update - https://github.com/ionic-team/capacitor/pull/2146#issuecomment-791392573
// the domain has to be different from the one in capacitor.config.ts, but we have to allow it for CORS (capacitor://domain_from_capacitor_config_ts)
const APP_BUILD_SOURCE = `https://www.app.${
  import.meta.env.VITE_ENVIRONMENT === 'production' ? '' : `${import.meta.env.VITE_ENVIRONMENT}.`
}nntb.cz`

// global variable to be able to mutate it
let newVersion: BundleInfo = {
  id: '',
  version: '',
  downloaded: '',
  checksum: '',
  status: 'success',
}

const updateAppCheck = async (isActive: boolean) => {
  if (isActive) {
    try {
      const response = await fetch(`${APP_BUILD_SOURCE}/version.json`)
      const { version: latestVersion } = await response.json()

      const { bundle: currentBundle } = await CapacitorUpdater.current()
      const currentVersion = currentBundle.id === 'builtin' ? version : currentBundle.version

      if (shouldUpdate(latestVersion, currentVersion) && newVersion.version !== latestVersion) {
        newVersion = await CapacitorUpdater.download({
          url: `${APP_BUILD_SOURCE}/build.zip`,
          version: latestVersion,
        })

        // set app new version; in the ideal world, we would like to set it in condition `!isActive && newVersion.version`
        SplashScreen.show()
        try {
          await CapacitorUpdater.set(newVersion)
          await deleteUnusedVersions(newVersion.version)
        } catch {
          // ignore empty catch
        } finally {
          SplashScreen.hide()
        }
      }
    } catch {
      // ignore empty catch
    }
  }
}

// Over The Air Update
const OTAUpdate = async () => {
  // Update is allowed only in the mobile app
  if (!isPurePWA()) {
    return
  }

  CapacitorUpdater.notifyAppReady()
  // fire update check manually because appStateChange is not fired on app start, but only after state change
  await updateAppCheck(true)
  App.addListener('appStateChange', ({ isActive }) => updateAppCheck(isActive))
}

const deleteUnusedVersions = async (excludeBuildVersion: string) => {
  const { bundles } = await CapacitorUpdater.list()

  await Promise.all(
    bundles
      .filter(bundle => excludeBuildVersion !== bundle.version)
      .map(bundle => CapacitorUpdater.delete({ id: bundle.id }))
  )
}

const showUpdateDialog = async (): Promise<void> => {
  await Dialog.confirm({
    title: 'Update Available',
    message: 'Please update to the latest version of the app',
  })

  return showUpdateDialog()
}

const shouldUpdate = (latest: string, current: string) => {
  const [latestMajor, ...latestMinor] = latest.split('.').map(Number)
  const [currentMajor, ...currentMinor] = current.split('.').map(Number)

  // latest contains new binaries -> update via store required
  if ((latestMajor ?? 0) > (currentMajor ?? 0)) {
    showUpdateDialog()

    return false
  }

  return latestMinor > currentMinor
}

export default OTAUpdate
