import { FormattedMessage, defineMessages } from '../../../TypedIntl'
import { ReportSentContent } from './ReportSentContent'

const messages = defineMessages({
  testingDescription: 'FollowUp.SchoolReportSent.testingDescription',
})

export const ReportSentTestingCompany = () => (
  <ReportSentContent description={<FormattedMessage {...messages.testingDescription} />} />
)
