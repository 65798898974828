import type { UntitledIconData } from '@faceup/icons'

export const ucShieldSuccess: UntitledIconData = {
  prefix: 'custom',
  name: 'shieldSuccess',
  viewBox: '0 0 20 20',
  paths: [
    {
      shape:
        'M7.323 1.134 1.49 3.727C.887 3.994.5 4.59.5 5.25v3.917c0 6.525 5.366 9.571 7.5 10 2.134-.429 7.5-3.475 7.5-10V5.25c0-.658-.387-1.256-.99-1.523L8.677 1.134a1.664 1.664 0 0 0-1.354 0zM6.577 13.09 4.27 10.781a.834.834 0 0 1 1.178-1.178l1.72 1.719 4.237-4.238a.834.834 0 0 1 1.178 1.179l-4.826 4.826a.834.834 0 0 1-1.179 0z',
      fill: 'currentColor',
    },
  ],
}
