import { useState } from 'react'

export type TextSelection = { start: number; end: number; string: string }

type TextSelectProps = {
  text: string
  onTextSelect: (selection: TextSelection) => void
}

export const TextSelect = (props: TextSelectProps) => {
  const [isTextSelecting, setIsTextSelecting] = useState<boolean>(false)
  const { text, onTextSelect } = props
  return (
    <div
      onMouseDown={() => setIsTextSelecting(true)}
      onMouseUp={() => {
        if (isTextSelecting) {
          const selection = window.getSelection()
          if (selection && selection.anchorOffset !== selection.focusOffset) {
            onTextSelect({
              start:
                selection.anchorOffset > selection.focusOffset
                  ? selection.focusOffset
                  : selection.anchorOffset,
              end:
                selection.focusOffset > selection.anchorOffset
                  ? selection.focusOffset
                  : selection.anchorOffset,
              string: selection.toString(),
            })
          }
          setIsTextSelecting(false)
        }
      }}
    >
      {text}
    </div>
  )
}
