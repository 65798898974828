import { storageGet, storageSet } from '@faceup/utils'

const CUSTOMIZE_CLIENT = 'CUSTOMIZE_CLIENT'

export type Clients = 'iframe' | 'jobka' | 'app'
const CLIENTS: Clients[] = ['jobka', 'iframe']

const useCustomizationByURLParams = (search: string) => {
  const urlParams = new URLSearchParams(search)

  const customizeClient = () => {
    const client = urlParams.get('client')

    if (client && CLIENTS.includes(client as Clients)) {
      storageSet('sessionStorage', CUSTOMIZE_CLIENT, client)
      return client as Clients
    }
    return (storageGet('sessionStorage', CUSTOMIZE_CLIENT) as Clients) ?? null
  }

  return {
    customizeClient,
  }
}

export default useCustomizationByURLParams
