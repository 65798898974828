import { UntitledIcon } from '@faceup/icons'
import { ulArrowLeft } from '@faceup/icons/ulArrowLeft'
import { Box } from '@mantine/core'

export const ArrowGoBack = () => (
  <Box
    sx={theme => ({
      color: theme.black,
      fontSize: 24,
    })}
  >
    <UntitledIcon icon={ulArrowLeft} />
  </Box>
)
