import { UntitledIcon } from '@faceup/icons'
import { Link, useMatchPath } from '@faceup/router'
import { Button, pagesIcons, usePageTemplate } from '@faceup/ui'
import type { ChannelPageType, PageIcon } from '@faceup/utils'
import { Box } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { useEffect } from 'react'
import { getPageRouteCallback } from '../../../../../../../../utils/urls'

type MenuItemProps = {
  page: {
    id: string
    icon: string | null
    title: string | null
  } & ({ __typename: 'Page'; type?: never } | { __typename: 'ChannelPage'; type: ChannelPageType })
  onChangeElementWidth: (width: number) => void
}

export const MenuItem = (props: MenuItemProps) => {
  const { page, onChangeElementWidth } = props
  const { fillObject } = usePageTemplate()
  const matchPath = useMatchPath()
  const { ref, width } = useElementSize()

  useEffect(() => {
    onChangeElementWidth(width)
  }, [width, onChangeElementWidth])

  const { id, icon, title } = fillObject(page)
  const untitledIcon = pagesIcons[icon as PageIcon]
  const routeCallback = getPageRouteCallback(page)
  const isItemActive = matchPath(routeCallback)
  return (
    <div ref={ref}>
      <Box
        sx={{
          '.mantine-Button-leftIcon': {
            marginRight: '0.5rem',
          },
        }}
      >
        <Button
          key={id}
          component={Link}
          to={routeCallback}
          iconBefore={untitledIcon ? <UntitledIcon icon={untitledIcon} /> : undefined}
          variant='text'
          color={isItemActive ? undefined : 'dark'}
        >
          {title}
        </Button>
      </Box>
    </div>
  )
}
