export const locales = {
  Af: () => import('antd/es/locale/en_GB'), // no support
  Am: () => import('antd/es/locale/en_GB'), // no support
  Ar: () => import('antd/es/locale/ar_EG'),
  Az: () => import('antd/es/locale/az_AZ'),
  Bg: () => import('antd/es/locale/bg_BG'),
  Bn: () => import('antd/es/locale/bn_BD'),
  Bs: () => import('antd/es/locale/en_GB'), // no support
  Ca: () => import('antd/es/locale/ca_ES'),
  Cs: () => import('antd/es/locale/cs_CZ'),
  Cy: () => import('antd/es/locale/en_GB'), // no support
  Da: () => import('antd/es/locale/da_DK'),
  De: () => import('antd/es/locale/de_DE'),
  El: () => import('antd/es/locale/el_GR'),
  En_Gb: () => import('antd/es/locale/en_GB'),
  En_Us: () => import('antd/es/locale/en_US'),
  Es: () => import('antd/es/locale/es_ES'),
  Es_Mx: () => import('antd/es/locale/en_GB'), // no support
  Et: () => import('antd/es/locale/et_EE'),
  Fa: () => import('antd/es/locale/fa_IR'),
  Fa_Af: () => import('antd/es/locale/en_GB'), // no support
  Fi: () => import('antd/es/locale/fi_FI'),
  Fr: () => import('antd/es/locale/fr_BE'),
  Fr_Ca: () => import('antd/es/locale/fr_CA'),
  Ga: () => import('antd/es/locale/ga_IE'),
  Gu: () => import('antd/es/locale/en_GB'), // no support
  Ha: () => import('antd/es/locale/en_GB'), // no support
  He: () => import('antd/es/locale/he_IL'),
  Hi: () => import('antd/es/locale/hi_IN'),
  Hr: () => import('antd/es/locale/hr_HR'),
  Ht: () => import('antd/es/locale/en_GB'), // no support
  Hu: () => import('antd/es/locale/hu_HU'),
  Hy: () => import('antd/es/locale/hy_AM'),
  Id: () => import('antd/es/locale/id_ID'),
  Is: () => import('antd/es/locale/is_IS'),
  It: () => import('antd/es/locale/it_IT'),
  Ja: () => import('antd/es/locale/ja_JP'),
  Ka: () => import('antd/es/locale/ka_GE'),
  Kk: () => import('antd/es/locale/kk_KZ'),
  Kn: () => import('antd/es/locale/kn_IN'),
  Ko: () => import('antd/es/locale/ko_KR'),
  Lt: () => import('antd/es/locale/lt_LT'),
  Lv: () => import('antd/es/locale/lv_LV'),
  Mk: () => import('antd/es/locale/mk_MK'),
  Ml: () => import('antd/es/locale/ml_IN'),
  Mn: () => import('antd/es/locale/mn_MN'),
  Mr: () => import('antd/es/locale/en_GB'), // no support
  Ms: () => import('antd/es/locale/ms_MY'),
  Mt: () => import('antd/es/locale/en_GB'), // no support
  Nb: () => import('antd/es/locale/nb_NO'),
  Nl: () => import('antd/es/locale/nl_NL'),
  Pa: () => import('antd/es/locale/en_GB'), // no support
  Pl: () => import('antd/es/locale/pl_PL'),
  Ps: () => import('antd/es/locale/en_GB'), // no support
  Pt: () => import('antd/es/locale/pt_BR'),
  Pt_Pt: () => import('antd/es/locale/pt_PT'),
  Ro: () => import('antd/es/locale/ro_RO'),
  Ru: () => import('antd/es/locale/ru_RU'),
  Si: () => import('antd/es/locale/en_GB'), // no support
  Sk: () => import('antd/es/locale/sk_SK'),
  Sl: () => import('antd/es/locale/sl_SI'),
  So: () => import('antd/es/locale/en_GB'), // no support
  Sq: () => import('antd/es/locale/en_GB'), // no support
  Sr: () => import('antd/es/locale/sr_RS'),
  Sv: () => import('antd/es/locale/sv_SE'),
  Sw: () => import('antd/es/locale/en_GB'), // no support
  Ta: () => import('antd/es/locale/ta_IN'),
  Te: () => import('antd/es/locale/en_GB'), // no support
  Th: () => import('antd/es/locale/th_TH'),
  Tl: () => import('antd/es/locale/en_GB'), // no support
  Tr: () => import('antd/es/locale/tr_TR'),
  Uk: () => import('antd/es/locale/uk_UA'),
  Ur: () => import('antd/es/locale/ur_PK'),
  Uz: () => import('antd/es/locale/en_GB'), // no support
  Vi: () => import('antd/es/locale/vi_VN'),
  Zh: () => import('antd/es/locale/zh_CN'),
  Zh_Tw: () => import('antd/es/locale/zh_TW'),
  Eu: () => import('antd/es/locale/en_GB'), // no support
  Be: () => import('antd/es/locale/en_GB'), // no support
  Ceb: () => import('antd/es/locale/en_GB'), // no support
  Ny: () => import('antd/es/locale/en_GB'), // no support
  Co: () => import('antd/es/locale/en_GB'), // no support
  Fy_Nl: () => import('antd/es/locale/en_GB'), // no support
  Gl: () => import('antd/es/locale/gl_ES'),
  Haw: () => import('antd/es/locale/en_GB'), // no support
  Hmn: () => import('antd/es/locale/en_GB'), // no support
  Ig: () => import('antd/es/locale/en_GB'), // no support
  Jv: () => import('antd/es/locale/en_GB'), // no support
  Km: () => import('antd/es/locale/km_KH'),
  Rw: () => import('antd/es/locale/en_GB'), // no support
  Ku: () => import('antd/es/locale/en_GB'), // no support
  Ky: () => import('antd/es/locale/en_GB'), // no support
  Lo: () => import('antd/es/locale/en_GB'), // no support
  Lb: () => import('antd/es/locale/en_GB'), // no support
  Mg: () => import('antd/es/locale/en_GB'), // no support
  Mi: () => import('antd/es/locale/en_GB'), // no support
  My_Mm: () => import('antd/es/locale/en_GB'), // no support
  Ne: () => import('antd/es/locale/ne_NP'),
  Or: () => import('antd/es/locale/en_GB'), // no support
  Sm: () => import('antd/es/locale/en_GB'), // no support
  Gd: () => import('antd/es/locale/en_GB'), // no support
  St: () => import('antd/es/locale/en_GB'), // no support
  Sn: () => import('antd/es/locale/en_GB'), // no support
  Sd: () => import('antd/es/locale/en_GB'), // no support
  Su: () => import('antd/es/locale/en_GB'), // no support
  Tg: () => import('antd/es/locale/en_GB'), // no support
  Tt: () => import('antd/es/locale/en_GB'), // no support
  Tk: () => import('antd/es/locale/tk_TK'),
  Ug: () => import('antd/es/locale/en_GB'), // no support
  Xh: () => import('antd/es/locale/en_GB'), // no support
  Yi: () => import('antd/es/locale/en_GB'), // no support
  Yo: () => import('antd/es/locale/en_GB'), // no support
  Zu: () => import('antd/es/locale/en_GB'), // no support
}
