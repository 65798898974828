import { CustomizationContext, CustomizationProvider, FeatureFlagProvider } from '@faceup/ui'
import { DEFAULT_LANGUAGE, languageEnumToBasename } from '@faceup/utils'
import { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import AppProviders from './AppProviders'
import { LanguageContext } from './Contexts/LanguageContext'
import LanguageProvider from './Contexts/LanguageProvider'
import LayoutProvider from './Contexts/LayoutProvider'
import ReportFormProvider from './Contexts/ReportFormProvider'
import ReportSourceProvider from './Contexts/ReportSourceProvider'
import UserProvider from './Contexts/UserProvider'
import CustomApolloProvider from './CustomApolloProvider'
import AppRouter from './Pages/AppRouter'
import OTAUpdate from './utils/OTAUpdate'

// TODO!!!
// if user is logged and domain is NNTB and their country is CZ/SK, redirect them to NNTB

const App = () => {
  useEffect(() => {
    OTAUpdate()
  }, [])

  return (
    <LayoutProvider>
      <CustomizationProvider>
        <LanguageProvider>
          <UserProvider>
            <LanguageContext.Consumer>
              {({ language, messages, direction, antLocale }) => (
                <ReportFormProvider>
                  <IntlProvider
                    locale={languageEnumToBasename(language ?? DEFAULT_LANGUAGE)}
                    key={language}
                    messages={messages}
                    onError={() => {
                      // Ignore errors
                    }}
                  >
                    <CustomApolloProvider>
                      <FeatureFlagProvider>
                        <ReportSourceProvider>
                          <CustomizationContext.Consumer>
                            {({ colors: { primaryColor } }) => (
                              <AppProviders
                                direction={direction}
                                primaryColor={primaryColor}
                                antLocale={antLocale}
                              >
                                <AppRouter />
                              </AppProviders>
                            )}
                          </CustomizationContext.Consumer>
                        </ReportSourceProvider>
                      </FeatureFlagProvider>
                    </CustomApolloProvider>
                  </IntlProvider>
                </ReportFormProvider>
              )}
            </LanguageContext.Consumer>
          </UserProvider>
        </LanguageProvider>
      </CustomizationProvider>
    </LayoutProvider>
  )
}

export default App
