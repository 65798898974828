import { UnstyledButton } from '@mantine/core'
import type { ReactNode } from 'react'
import { type RedactTextType, useRedaction } from './RedactionProvider'
import { TextToRedact, getText } from './TextToRedact'

type UsernameRedactProps = {
  children: ReactNode
  type: RedactTextType
  text: string
}

export const UsernameRedact = (props: UsernameRedactProps) => {
  const { children, type, text: originalText } = props
  const { isRedactionActive, texts, redactText } = useRedaction()

  if (!isRedactionActive) {
    return <>{children}</>
  }

  if (type.type === 'sender') {
    return (
      <TextToRedact type={type} text={originalText}>
        {originalText}
      </TextToRedact>
    )
  }

  const redactedText = getText(texts, type)
  const text = redactedText ?? originalText
  const shownName = text === originalText ? text : `User ${text}`

  return (
    <UnstyledButton
      sx={theme => ({
        color: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        fontFamily: 'inherit',
        '&:hover': {
          backgroundColor: theme.colors.dark[2],
        },
      })}
      onClick={() => {
        redactText(
          type,
          {
            start: 0,
            end: text.length,
            variant: redactedText === undefined ? 'redacted' : 'notRedacted',
          },
          originalText
        )
      }}
    >
      {shownName}
    </UnstyledButton>
  )
}
