import { Center, Loader } from '@mantine/core'

export const FullScreenLoader = () => (
  <Center
    sx={{
      height: '100vh',
    }}
  >
    <Loader />
  </Center>
)
