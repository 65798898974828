import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { prehashPassword } from '@faceup/crypto'
import { type ReactNode, useContext, useEffect, useState } from 'react'
import useReportAuth from '../utils/useReportAuth'
import useStorage, { StorageKeys } from '../utils/useStorage'
import { LayoutContext } from './LayoutContext'
import { UserContext, type UserInstitution, type UserSettings } from './UserContext'

const LOGOUT_TIMEOUT = 30000 // 30s
const PIN_SALT = 'cZuUtYfvMpUSgjLKSzDvgw' // RANDOM SALT

type Props = {
  children: ReactNode
}

const UserProvider = ({ children }: Props) => {
  const storage = useStorage()
  const { logout: reportLogout } = useReportAuth()
  const { client } = useContext(LayoutContext)
  const item = storage.get(StorageKeys.User)
  const data = item ? JSON.parse(item) : null

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [authPin, setAuthPin] = useState<string | null>(data?.authPin)
  const [institution, setInstitution] = useState<UserInstitution | null>(data?.institution)
  const [settings, setSettings] = useState<UserSettings>(data?.settings ?? {})

  useEffect(() => {
    // Handles logout if app is no longer active
    let logoutTimeout: number
    App.addListener('appStateChange', ({ isActive }) => {
      if (!isActive) {
        logoutTimeout = window.setTimeout(() => setIsAuthenticated(false), LOGOUT_TIMEOUT)
      } else {
        clearTimeout(logoutTimeout)
      }
    })
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies(storage.remove):
  // biome-ignore lint/correctness/useExhaustiveDependencies(storage.set):
  useEffect(() => {
    const data = {
      authPin,
      institution,
      settings,
    }

    const filteredData = Object.entries(data).reduce(
      // @ts-expect-error key indexing
      // biome-ignore lint/style/noCommaOperator: TODO: Refactor
      (acc, [key, val]) => (val === null ? acc : ((acc[key] = val), acc)),
      {}
    )

    if (Object.keys(filteredData).length) {
      storage.set(StorageKeys.User, JSON.stringify(filteredData))
    } else {
      storage.remove(StorageKeys.User)
    }
  }, [authPin, institution, settings])

  const updateSettings = (key: string, value: unknown) => {
    const newSettings = settings && { [key]: value }
    setSettings(newSettings)
  }

  const prehashPin = async (pin: string) =>
    (await prehashPassword({ password: pin, salt: PIN_SALT })).passwordKeyPrehash

  const authenticate = async (pin: string) => {
    const prehashedPin = await prehashPin(pin)
    const isPinValid = prehashedPin === authPin
    setIsAuthenticated(isPinValid)
    return isPinValid
  }

  const signUp = async (pin: string) => {
    setAuthPin(await prehashPin(pin))
    setIsAuthenticated(true)
  }

  const logout = () => {
    reportLogout()
    setIsAuthenticated(false)
    setInstitution(null)
    setSettings({})
    setAuthPin(null)
    storage.remove(StorageKeys.User)
  }

  const isSetUp = () => {
    if (client !== 'app') {
      return true
    }

    // Hide biometrics for android
    if (Capacitor.getPlatform() !== 'ios') {
      return true
    }

    return settings?.biometrics !== undefined
  }

  return (
    <UserContext.Provider
      value={{
        isAuthenticated: isAuthenticated || client !== 'app',
        login: {
          hasAccount: Boolean(authPin),
          authenticate,
          // When logging with biometrics, no PIN or other information is required, therefore directl login is applied
          biometricsAuthentication: () => setIsAuthenticated(true),
          signUp,
        },
        logout,
        // Institution
        reportSource: institution,
        setReportSource: setInstitution,
        settings,
        updateSettings: {
          setBiometrics: (enabled: boolean) => updateSettings('biometrics', enabled),
        },
        isSetUp,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
