import { transitionDuration } from '@faceup/ui'
import { Box } from '@mantine/core'
import type { ReactNode } from 'react'
import { useRedaction } from './RedactionProvider'

type NotRedactableWrapperProps = {
  children: ReactNode
}

export const NotRedactableWrapper = (props: NotRedactableWrapperProps) => {
  const { children } = props
  const { isRedactionActive } = useRedaction()
  return (
    <Box
      sx={{
        cursor: isRedactionActive ? 'not-allowed' : undefined,
      }}
    >
      <Box
        sx={{
          ...(isRedactionActive ? { opacity: 0.5, pointerEvents: 'none', userSelect: 'none' } : {}),
          transition: `opacity ${transitionDuration}`,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
