import AntIcon from '@ant-design/icons'

export {
  ExclamationCircleFilled,
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  LogoutOutlined,
  TagsOutlined,
  PaperClipOutlined,
  CloseOutlined,
  UserAddOutlined,
  WarningTwoTone,
  ZoomInOutlined,
  ZoomOutOutlined,
  FilterOutlined,
  TeamOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  FlagFilled,
  FlagOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  CaretRightOutlined,
  DownloadOutlined,
  FormOutlined,
  UploadOutlined,
  MessageOutlined,
  DownOutlined,
  SettingOutlined,
  EyeInvisibleOutlined,
  PrinterOutlined,
  CalendarOutlined,
  FileOutlined,
  FileTextOutlined,
  NotificationOutlined,
  SolutionOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'

export const Icon = AntIcon
