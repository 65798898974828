import { gql, useQuery } from '@apollo/client'
import { useNavigate } from '@faceup/router'
import { convertBasenameToLanguage } from '@faceup/utils'
import { useContext, useEffect } from 'react'
import { FullScreenLoader } from '../../Components/FullScreenLoader'
import { InstitutionLayout } from '../../Components/InstitutionLayout'
import { LanguageContext } from '../../Contexts/LanguageContext'
import { UserContext } from '../../Contexts/UserContext'
import type {
  DefaultPageRedirectQuery,
  DefaultPageRedirectQueryVariables,
} from '../../__generated__/globalTypes'
import { getPageRouteCallback } from '../../utils/urls'

const query = gql`
  query DefaultPageRedirectQuery(
    $reportSourceId: PublicReportSourceGlobalId!
    $language: Language
  ) {
    publicReportSource(reportSourceId: $reportSourceId) {
      id
      pages(language: $language) {
        ... on Page {
          id
        }
      }
    }
  }
`

const DefaultPageRedirect = () => {
  const { reportSource: userInstitution } = useContext(UserContext)
  const navigate = useNavigate()
  const { basename } = useContext(LanguageContext)

  const { data, loading } = useQuery<DefaultPageRedirectQuery, DefaultPageRedirectQueryVariables>(
    query,
    {
      variables: {
        reportSourceId: userInstitution?.defaultFormId ?? '',
        language: convertBasenameToLanguage(basename),
      },
      skip: !userInstitution?.defaultFormId,
    }
  )

  useEffect(() => {
    const homepage =
      data?.publicReportSource?.pages[0]?.__typename === 'Page'
        ? data?.publicReportSource?.pages[0]
        : null

    if (homepage) {
      navigate(getPageRouteCallback(homepage))
    }
  }, [data, navigate])

  if (loading) {
    return <FullScreenLoader />
  }

  return <InstitutionLayout />
}

export default DefaultPageRedirect
