import { Link } from '@faceup/router'
import { Button, Lottie } from '@faceup/ui'
import { Col, Row } from '@faceup/ui-base'
import { Box, Center, Stack } from '@mantine/core'
import type { ReactNode } from 'react'
import PageTitle from '../../../../Components/PageTitle'
import { FormattedMessage, defineMessages } from '../../../../TypedIntl'
import { useInstitution } from '../../../../hooks/useInstitution'
import { sizeSettings } from '../../../../utils/constants'
import { getPageRouteCallback } from '../../../../utils/urls'
import lottieCheckmark from './assets/lottie-checkmark.json'

const messages = defineMessages({
  title: 'Shared.CompanyReportSent.title',
  backToHomepage: 'FollowUp.header.backToHomepage',
})

type ReportSentContentProps = {
  children?: ReactNode
  description?: ReactNode
}

export const ReportSentContent = (props: ReportSentContentProps) => {
  const { children, description } = props
  const institutionContext = useInstitution()

  return (
    <Row justify='center' data-cy='ReportSent'>
      <Col {...sizeSettings}>
        <PageTitle description={description}>
          <Center>
            <Box
              sx={{
                width: '4rem',
              }}
            >
              <Lottie animationData={lottieCheckmark} shouldPlay />
            </Box>
          </Center>
          <FormattedMessage {...messages.title} />
        </PageTitle>
        <Stack spacing='2rem'>
          {children}
          <Center>
            <Button
              component={Link}
              to={getPageRouteCallback(institutionContext?.homepage)}
              variant='tertiary'
              data-cy='report-sent-button-back-to-homepage'
            >
              <FormattedMessage {...messages.backToHomepage} />
            </Button>
          </Center>
        </Stack>
      </Col>
    </Row>
  )
}
