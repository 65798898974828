import styled from '@emotion/styled'
import { UntitledIcon } from '@faceup/icons'
import { usMicrophone01 } from '@faceup/icons/usMicrophone01'
import { usPlay } from '@faceup/icons/usPlay'
import { usSend01 } from '@faceup/icons/usSend01'
import { usStop } from '@faceup/icons/usStop'
import { usTrash04 } from '@faceup/icons/usTrash04'
import { Space } from '@faceup/ui-base'
import { LameEncoder, createRecorderProcessor } from '@faceup/utils'
import { ActionIcon } from '@mantine/core'
import { useTimeout } from '@mantine/hooks'
import { type RefObject, useContext, useEffect, useRef } from 'react'
import { RecordingStatus, ReportFormContext } from '../../Contexts/ReportFormContext'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { useMedia } from '../../mq'
import VoiceRecordingWaveCanvas from './VoiceRecordingWaveCanvas'

const messages = defineMessages({
  title: 'FollowUp.voiceRecording.title',
  description: 'FollowUp.voiceRecording.description',
})

type Props = {
  mediaStream: MediaStream | null
  setMediaStream: (stream: MediaStream | null) => void
  close: () => void
  audioRef: RefObject<HTMLAudioElement>
}

const makeDistortionCurve = (sampleRate = 44100, amount = 50) => {
  const curve = new Float32Array(sampleRate)
  const deg = Math.PI / 180
  for (let i = 0; i < sampleRate; i++) {
    const x = (i * 2) / sampleRate - 1
    curve[i] = ((3 + amount) * x * 20 * deg) / (Math.PI + amount * Math.abs(x))
  }
  return curve
}

// inspired by https://voicechanger.io/voicemaker/transformers/anonymousTransformer.js
const voiceDistortion = (stream: MediaStream) => {
  const oscilationFrequency = 20
  const distortion = 10

  const audioContext = new AudioContext()

  const inputNode = audioContext.createMediaStreamSource(stream)
  const outputNode = audioContext.createMediaStreamDestination()

  const waveShaper = audioContext.createWaveShaper()
  waveShaper.curve = makeDistortionCurve(44100, distortion)

  const oscillator = audioContext.createOscillator()
  oscillator.frequency.value = oscilationFrequency
  oscillator.type = 'sawtooth'

  const oscillatorGain = audioContext.createGain()
  oscillatorGain.gain.value = 0.005

  const delay = audioContext.createDelay()
  delay.delayTime.value = 0.01

  oscillator.start(0)
  oscillator.connect(oscillatorGain)
  oscillatorGain.connect(delay.delayTime)

  inputNode.connect(waveShaper)
  waveShaper.connect(delay)
  delay.connect(outputNode)

  return outputNode.stream
}

const VoiceRecordingBody = ({ close, mediaStream, setMediaStream, audioRef }: Props) => {
  const { record, setRecord, setRecordingStatus, recordingStatus } = useContext(ReportFormContext)
  const isMdDown = useMedia('mdDown')
  const { start: startTimeout, clear: clearTimeout } = useTimeout(
    () => stopRecording(),
    15 * 1000 * 60 // 15 minutes
  )

  const recordingStartTime = useRef<number>(0)
  const recordingContext = useRef<AudioContext | null>(null)
  const microphone = useRef<MediaStreamAudioSourceNode | null>(null)
  const processor = useRef<AudioWorkletNode | null>(null)
  const lameEncoder = useRef<LameEncoder>(new LameEncoder({ bitRate: 128, sampleRate: 44100 }))

  // biome-ignore lint/correctness/useExhaustiveDependencies(setRecordingStatus):
  useEffect(() => {
    const initLameEncoder = async () => {
      if (!lameEncoder.current.isInitialized) {
        setRecordingStatus(RecordingStatus.Initializing)
        await lameEncoder.current.initialize()
      }
      setRecordingStatus(RecordingStatus.Idle)
    }

    initLameEncoder()
  }, [])

  const addMicrophoneListener = async (context: AudioContext, stream: MediaStream) => {
    // Prevent the weird noise once you start listening to the microphone https://github.com/closeio/mic-recorder-to-mp3/blob/master/src/mic-recorder.js#L34-L37
    let timerToStart: ReturnType<typeof setTimeout> | null = null
    timerToStart = setTimeout(() => (timerToStart = null), 300)

    microphone.current = context.createMediaStreamSource(stream)

    processor.current = await createRecorderProcessor(context)
    processor.current.port.onmessage = event => {
      if (event.data.action === 'encode') {
        if (timerToStart) {
          return
        }

        lameEncoder.current.encode(event.data.buffer)
      }
    }

    microphone.current.connect(processor.current)
    processor.current.connect(context.destination)
  }

  const getFinalVoiceRecordingBlob = async () => {
    const finalBuffer = lameEncoder.current.finish()

    if (finalBuffer.length === 0) {
      throw new Error('No buffer to send')
    }

    const audioBlob = new Blob(finalBuffer, { type: 'audio/mp3' })
    lameEncoder.current.clearBuffer()

    return audioBlob
  }

  const source = record?.data ? URL.createObjectURL(record.data) : ''

  const playRecord = () => {
    setRecordingStatus(RecordingStatus.Playing)
    audioRef.current?.play()
  }

  const pauseRecord = () => {
    setRecordingStatus(RecordingStatus.Paused)
    audioRef.current?.pause()
  }

  const startRecording = async () => {
    if (recordingStatus === RecordingStatus.Recording) {
      return
    }

    // code for audio recording from here https://github.com/closeio/mic-recorder-to-mp3/pull/44
    recordingContext.current = new AudioContext()
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    setMediaStream(stream)
    const distortedStream = voiceDistortion(stream)
    // if you want to disable distorting voice, pass `stream` instead of `distortedStream`
    await addMicrophoneListener(recordingContext.current, distortedStream)

    recordingStartTime.current = Date.now()
    startTimeout()
    setRecordingStatus(RecordingStatus.Recording)
  }

  const stopRecording = async () => {
    if (recordingStatus !== RecordingStatus.Recording) {
      return
    }

    if (processor.current && microphone.current) {
      // Clean up the Web Audio API resources.
      processor.current.port.postMessage({ action: 'stop' })
      microphone.current.disconnect()
      processor.current.disconnect()

      // If all references using context are destroyed, context is closed
      // automatically. DOMException is fired when trying to close again
      if (recordingContext.current && recordingContext.current.state !== 'closed') {
        recordingContext.current.close()
      }
    }

    const startTime = recordingStartTime.current
    recordingStartTime.current = 0
    setRecordingStatus(startTime ? RecordingStatus.Idle : RecordingStatus.Paused)

    if (startTime) {
      const duration = Date.now() - startTime
      const data = await getFinalVoiceRecordingBlob()

      setRecord({
        data,
        duration,
      })
    }

    setRecordingStatus(RecordingStatus.Paused)

    if (mediaStream) {
      clearTimeout()
      mediaStream.getAudioTracks().forEach(track => track.stop())
    }
  }

  const isRecorded = Boolean(record)

  return (
    <Wrapper>
      <div style={{ padding: isMdDown ? '7rem 2rem 0' : 0 }}>
        <FormattedMessage {...messages.description} />
      </div>

      <VoiceRecordingWaveCanvas
        width={isMdDown ? 400 : 600}
        height={isMdDown ? 200 : 300}
        status={recordingStatus}
        startTime={recordingStartTime.current ?? 0}
        stream={mediaStream ?? null}
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      />

      {isRecorded ? (
        <>
          <audio ref={audioRef} onEnded={() => setRecordingStatus(RecordingStatus.Paused)}>
            <source src={source} type='audio/mpeg' />
          </audio>
          <Space size={40} align='center'>
            <ActionIcon
              size='xl'
              radius='xl'
              color='red'
              onClick={() => {
                setRecord(null)
                setRecordingStatus(RecordingStatus.Idle)
              }}
            >
              <Icon icon={usTrash04} />
            </ActionIcon>
            <ActionIcon
              size='xl'
              radius='xl'
              color='dark'
              variant='outline'
              onClick={recordingStatus === RecordingStatus.Playing ? pauseRecord : playRecord}
            >
              <Icon icon={recordingStatus === RecordingStatus.Playing ? usStop : usPlay} />
            </ActionIcon>
            <ActionIcon
              color='primary'
              size='xl'
              radius='xl'
              onClick={() => {
                pauseRecord()
                close()
              }}
            >
              <Icon icon={usSend01} />
            </ActionIcon>
          </Space>
        </>
      ) : (
        <ActionIcon
          loading={recordingStatus === RecordingStatus.Initializing}
          color='primary'
          size='xl'
          radius='xl'
          variant='filled'
          onClick={
            recordingStatus === RecordingStatus.Initializing
              ? undefined
              : recordingStatus === RecordingStatus.Recording
                ? stopRecording
                : startRecording
          }
        >
          <Icon icon={usMicrophone01} />
        </ActionIcon>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: nowrap column;
  align-items: center;
  text-align: center;
`

const Icon = styled(UntitledIcon)`
  font-size: 24px;
`

export default VoiceRecordingBody
