import useStorage, { StorageKeys } from './useStorage'

const useAuth = () => {
  const storage = useStorage()

  const setJwt = async (jwt: string) => {
    storage.set(StorageKeys.JWT, jwt)
  }

  const removeJwt = async () => {
    storage.remove(StorageKeys.JWT)
  }

  const getJwt = () => storage.get(StorageKeys.JWT)

  return { setJwt, getJwt, removeJwt }
}

export default useAuth
