import type { UntitledIconData } from '../../UntitledIconData'

const ulX: UntitledIconData = {
  prefix: 'ul',
  name: 'x',
  paths: [
    {
      shape: 'M17 7L7 17M7 7L17 17',
    },
  ],
}

export default ulX
