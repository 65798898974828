import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  from,
  split,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { notification } from '@faceup/ui-base'
import {
  DEFAULT_LANGUAGE,
  buildUrl,
  getApiUrlByCurrentDomain,
  regionHeaderName,
} from '@faceup/utils'
// @ts-expect-error Ignore types https://github.com/jaydenseric/apollo-upload-client/releases/tag/v18.0.0#:~:text=Implemented%20TypeScript%20types%20via%20JSDoc%20comments.
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { type ReactNode, useContext } from 'react'
import { LanguageContext } from './Contexts/LanguageContext'
import { sharedMessages } from './Shared/translations'
import { useIntl } from './TypedIntl'
import { possibleTypes } from './__generated__/possibleTypes.json'
import useAuth from './utils/auth'
import useNetworkStatus from './utils/useNetworkStatus'
import useStorage, { StorageKeys } from './utils/useStorage'

const CustomApolloProvider = ({ children }: { children?: ReactNode }) => {
  const networkStatus = useNetworkStatus()
  const { language } = useContext(LanguageContext)
  const { formatMessage } = useIntl()
  const { getJwt } = useAuth()
  const { get } = useStorage()

  // inspiration
  // > https://www.apollographql.com/docs/react/advanced/subscriptions.html
  // + file upload https://github.com/jaydenseric/apollo-upload-client#function-createuploadlink
  const httpLink = createUploadLink({
    uri: getApiUrlByCurrentDomain(import.meta.env.VITE_API_URL ?? ''),
    credentials: 'include',
  })

  const wsLink = new WebSocketLink({
    uri: buildUrl(
      getApiUrlByCurrentDomain(import.meta.env.VITE_WS_API_URL ?? ''),
      get(StorageKeys.Region) ? { [regionHeaderName]: get(StorageKeys.Region) as string } : {}
    ),
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: () => ({
        token: getJwt() || '',
        language: language || DEFAULT_LANGUAGE,
        origin: window.location.origin,
        ...(get(StorageKeys.Region) && { [regionHeaderName]: get(StorageKeys.Region) }),
      }),
    },
  })

  // we need to change authorization `token` if the user changes the token (eg logging to another report in the same session)
  // because `token` in connectionParams is initialized only once, we need custom middleware to close the connection to reflect these changes
  // https://github.com/apollographql/apollo-link/issues/197
  const subscriptionMiddleware = {
    applyMiddleware: (_options: WebSocketLink.Configuration, next: () => void) => {
      // @ts-expect-error 'subscriptionClient' is private, but we need a direct access (working!)
      const subscriptionClient = wsLink.subscriptionClient
      // if the token does not match, we need to get a new token and close the connection
      if (subscriptionClient?.connectionParams?.token !== getJwt()) {
        // change the token and close the connection, because of `reconnect: true`, the client reconnect with a new token
        // initialized wsLink does not have direct `connectionParams` - we save it only for the condition above
        // only close the connection if we have a token
        if (subscriptionClient?.connectionParams?.token) {
          subscriptionClient?.close(false)
        }
        subscriptionClient.connectionParams.token = getJwt()
      }
      next()
    },
  }
  // @ts-expect-error apply middleware to wsLink which detects JWT change and closes the connection
  wsLink.subscriptionClient.use([subscriptionMiddleware])

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext((context: Record<string, unknown>) => {
      const token = getJwt()
      return {
        ...context,
        headers: {
          authorization: token ? `Bearer ${token}` : '',
          language,
          ...(get(StorageKeys.Region) && { [regionHeaderName]: get(StorageKeys.Region) }),
        },
      }
    })

    return forward(operation)
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    const suppressError =
      (window.location.pathname.includes('/check') &&
        graphQLErrors?.[0]?.message === 'Invalid login credentials') ||
      (window.location.pathname.includes('/report') &&
        graphQLErrors?.[0]?.message ===
          'Cant add any new messages to the follow up chat, report was already marked as solved')

    if (suppressError || networkStatus !== 'online') {
      return
    }

    console.error(graphQLErrors)
    notification.error({
      message: formatMessage(sharedMessages.apiError),
      description: networkError?.message ?? graphQLErrors?.[0]?.message ?? '',
    })
  })

  const link = from([
    errorLink,
    split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query)

        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
      },
      wsLink,
      authLink.concat(httpLink)
    ),
  ])

  const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache({ possibleTypes }),
  })

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default CustomApolloProvider
