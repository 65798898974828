import type { UntitledIconData } from '../../UntitledIconData'

const ulLifeBuoy01: UntitledIconData = {
  prefix: 'ul',
  name: 'life-buoy-01',
  paths: [
    {
      shape:
        'M9.13626 9.13628L4.92893 4.92896M4.92893 19.0711L9.16797 14.8321M14.8611 14.8638L19.0684 19.0711M19.0684 4.92896L14.8287 9.16862M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z',
    },
  ],
}

export default ulLifeBuoy01
