import { Text, Title } from '@mantine/core'
import { FormattedMessage, defineMessages } from '../../../TypedIntl'

const messages = defineMessages({
  title: 'FollowUp.pageNotFound.title',
  description: 'FollowUp.pageNotFound.description',
})

const PageNotFound = () => (
  <>
    <Title ta='center'>
      <FormattedMessage {...messages.title} />
    </Title>
    <Text ta='center'>
      <FormattedMessage {...messages.description} />
    </Text>
  </>
)

export default PageNotFound
