import { UntitledIcon } from '@faceup/icons'
import { ulEye } from '@faceup/icons/ulEye'
import { ulEyeOff } from '@faceup/icons/ulEyeOff'
import { Avatar, UserAvatar } from '@faceup/ui'
import { UnstyledButton, useMantineTheme } from '@mantine/core'
import type { ReactNode } from 'react'
import { type RedactTextType, useRedaction } from './RedactionProvider'
import { getText } from './TextToRedact'

type UsernameAvatarRedactProps = {
  children: ReactNode
  type: RedactTextType
  text: string
}

export const UsernameAvatarRedact = (props: UsernameAvatarRedactProps) => {
  const { children, type, text: originalText } = props
  const { isRedactionActive, texts, redactText } = useRedaction()
  const theme = useMantineTheme()

  if (!isRedactionActive) {
    return <>{children}</>
  }

  const redactedText = getText(texts, type)
  const text = redactedText ?? originalText

  if (type.type === 'sender') {
    return (
      <UserAvatar
        user={{
          name: text,
        }}
      />
    )
  }

  const shownName = text === originalText ? text : `User ${text}`

  return (
    <UnstyledButton
      sx={{
        display: 'flex',
        height: 'fit-content',
        '& .ant-avatar': {
          display: 'none',
        },
        '&:hover': {
          '& .ant-avatar': {
            display: 'flex',
          },
          '& .mantine-Avatar-root': {
            display: 'none',
          },
        },
      }}
      onClick={() => {
        redactText(
          type,
          {
            start: 0,
            end: text.length,
            variant: redactedText === undefined ? 'redacted' : 'notRedacted',
          },
          originalText
        )
      }}
    >
      <Avatar
        style={{
          backgroundColor: theme.colors.dark[2],
        }}
      >
        <UntitledIcon icon={text === originalText ? ulEyeOff : ulEye} />
      </Avatar>
      <UserAvatar
        user={{
          name: shownName,
        }}
      />
    </UnstyledButton>
  )
}
