import { gql } from '@apollo/client'
import type { ReportSentFreeSchool_publicReportSource } from '../../../__generated__/globalTypes'
import { useReportSentData, useReportSentDataFragments } from '../hooks'
import { ReportSentContent } from './ReportSentContent'
import { SchoolSentCollapse } from './SchoolSentCollapse'

export const ReportSentFreeSchoolFragments = {
  ReportSentFreeSchool_publicReportSource: gql`
    fragment ReportSentFreeSchool_publicReportSource on PublicReportSource {
      id

      ...useReportSentData_publicReportSource
    }

    ${useReportSentDataFragments.useReportSentData_publicReportSource}
  `,
}

type ReportSentFreeSchoolProps = {
  publicReportSource: ReportSentFreeSchool_publicReportSource
}

export const ReportSentFreeSchool = (props: ReportSentFreeSchoolProps) => {
  const { publicReportSource } = props
  const { reportSentDescription } = useReportSentData(publicReportSource)
  return (
    <ReportSentContent description={reportSentDescription}>
      <SchoolSentCollapse />
    </ReportSentContent>
  )
}
