import { Dialog } from '@capacitor/dialog'
import { NativeBiometric } from '@capgo/capacitor-native-biometric'
import styled from '@emotion/styled'
import { Icon, Typography } from '@faceup/ui-base'
import { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../Contexts/UserContext'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import ShieldIcon from './assets/shield-icon.svg?react'

const { Title } = Typography

const messages = defineMessages({
  signUpTitle: 'FollowUp.welcome.createPin',
  signUpDescription: 'FollowUp.welcome.createPinDescription',
  confirmTitle: 'FollowUp.welcome.createPinConfirmTitle',
  confirmDescription: 'FollowUp.welcome.createPinConfirmDescription',
  loginTitle: 'FollowUp.welcome.loginTitle',
  continue: 'FollowUp.global.continue',
  pinDiffers: 'FollowUp.welcome.pinDiffers',
  resetApp: 'FollowUp.welcome.reset',
  forgottenPin: 'FollowUp.welcome.forgottenPin',
  forgottenPinTitle: 'FollowUp.welcome.forgottenPin.modal.title',
  forgottenPinDecription: 'FollowUp.welcome.forgottenPin.modal.description',
})

type LoginType = 'signUp' | 'confirm' | 'login'

const Welcome = () => {
  const {
    login: { authenticate, biometricsAuthentication, hasAccount, signUp },
    logout,
    settings,
  } = useContext(UserContext)
  const [loginType, _setLoginType] = useState<LoginType>(hasAccount ? 'login' : 'signUp')
  const [pin, _setPin] = useState('')
  const [pinError, _setPinError] = useState(false)
  const [temporaryPin, _setTemporaryPin] = useState('')
  const pinRef = useRef(pin)
  const loginTypeRef = useRef(loginType)
  const temporaryPinRef = useRef(temporaryPin)
  const pinErrorRef = useRef(pinError)
  const { formatMessage } = useIntl()

  // Because of using states in event listeners
  const setPin = (value: string) => {
    pinRef.current = value
    _setPin(value)
    if (validateForm()) {
      setTimeout(() => handleButton(), 300)
    }
  }

  const setTemporaryPin = (value: string) => {
    temporaryPinRef.current = value
    _setTemporaryPin(value)
  }

  const setLoginType = (loginType: LoginType) => {
    loginTypeRef.current = loginType
    _setLoginType(loginType)
  }

  const setPinError = (value: boolean) => {
    pinErrorRef.current = value
    _setPinError(value)
  }

  const hiddenInputRef = useRef<HTMLInputElement>(null)
  const validateForm = () => pinRef.current.length === 4

  const handleButton = async () => {
    switch (loginTypeRef.current) {
      case 'signUp':
        setTemporaryPin(pinRef.current)
        setLoginType('confirm')
        setPin('')
        return
      case 'confirm':
        if (pinRef.current !== temporaryPinRef.current) {
          setPinError(true)
          setPin('')
          return
        }

        signUp(pinRef.current)
        return
      default:
        if (!(await authenticate(pinRef.current))) {
          setPinError(true)
          setPin('')
          return
        }
    }
  }

  const handleLogout = async () => {
    const { value } = await Dialog.confirm({
      title: formatMessage(messages.forgottenPinTitle),
      message: formatMessage(messages.forgottenPinDecription),
    })

    if (value) {
      logout()
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies(biometricsAuthentication):
  // biome-ignore lint/correctness/useExhaustiveDependencies(settings?.biometrics):
  useEffect(() => {
    const focusInput = () => hiddenInputRef?.current?.focus()

    const biometrics = async () => {
      try {
        const config = await NativeBiometric.isAvailable()

        if (config.isAvailable) {
          await NativeBiometric.verifyIdentity()
          // successfuly authenticates
          setTimeout(biometricsAuthentication, 500)
        }
      } catch {
        setTimeout(focusInput, 300)
      }
    }

    document.body.addEventListener('click', focusInput)

    if (settings?.biometrics) {
      biometrics()
    } else {
      focusInput()
    }

    return () => {
      document.body.removeEventListener('click', focusInput)
    }
  }, [])

  return (
    <>
      <TopWrapper>
        <Shield component={ShieldIcon} />
        <Title style={{ fontSize: 24, marginBottom: loginType === 'login' ? 42 : 12 }}>
          <FormattedMessage {...messages[`${loginType}Title`]} />
        </Title>
        {loginType !== 'login' && (
          <Description>
            <FormattedMessage {...messages[`${loginType}Description`]} />
          </Description>
        )}
        <CodeWrapper>
          <HiddenInput
            ref={hiddenInputRef}
            pattern='[0-9]*'
            type='number'
            value={pin}
            onChange={({ target: { value } }) => {
              if (value.length <= 4) {
                setPin(value)
              }
            }}
          />
          {Array.from({ length: 4 }).map((_, i) => (
            <InputWrapper
              // biome-ignore lint/suspicious/noArrayIndexKey:
              key={i}
              filled={pin.length > i}
            >
              <InputCode filled={pin.length > i} />
            </InputWrapper>
          ))}
        </CodeWrapper>
        {pinError && (
          <PinError>
            <FormattedMessage {...messages.pinDiffers} />
          </PinError>
        )}
      </TopWrapper>
      {hasAccount && (
        <ForgottenPin onClick={handleLogout}>
          <FormattedMessage {...messages.forgottenPin} />
        </ForgottenPin>
      )}
    </>
  )
}

const TopWrapper = styled.div`
  text-align: center;
  padding-top: 15%;
  width: 100%;
`
const Description = styled.div`
  font-size: 1.2rem;
  margin-bottom: 2rem;
`

const PinError = styled.div`
  font-size: 1.2rem;
  margin-top: 1.5rem;
  color: #ef4a45;
`

const Shield = styled(Icon)`
  font-size: 100px;
  margin-bottom: 2rem;
`

const HiddenInput = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

const CodeWrapper = styled.div`
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  justify-content: center;
`

const InputCode = styled.div<{ filled: boolean }>`
  border: none;
  border-radius: 50%;
  width: 1.4rem;
  height: 1.4rem;
  margin: 4px;

  background-color: ${({ filled }) => (filled ? '#0e9af7' : 'transparent')};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const InputWrapper = styled.div<{ filled: boolean }>`
  border: 2px solid #0e9af7;
  border-radius: 50%;
  margin: 0.5rem;

  border-color: ${({ filled }) => (filled ? '#0e9af7' : '#d7dde1')};
`
const ForgottenPin = styled.div`
  color: #0e9af7;
  font-weight: 600;
  font-size: 16px;
`

export default Welcome
