import { deletePersonalKeys, saveSSOKeys } from '@faceup/crypto'
import { useLocalStorage } from '@faceup/ui'
import { type ReactNode, createContext } from 'react'
import type { ReportStatusType } from '../__generated__/globalTypes'
import useAuth from '../utils/auth'

const storageKey = 'savedReports'
const contentMaxLength = 64

export type SavedReport = {
  keys: {
    jwt: string
    publicKey: string
    privateKey: string
  }
  id: string
  createdAt: string
  category: string
  content: string
  status: ReportStatusType
}

type SavedReportsContextProps = {
  reports: SavedReport[]
  addReport: (report: SavedReport) => void
  removeReport: (report: SavedReport) => void
  loginIntoReport: (report: SavedReport) => void
  hasSomeSavedReports: boolean
}

export const SavedReportsContext = createContext<SavedReportsContextProps>({
  reports: [],
  addReport: () => null,
  removeReport: () => null,
  loginIntoReport: () => null,
  hasSomeSavedReports: false,
})

type SavedReportsProviderProps = {
  children: ReactNode
}

export const SavedReportsProvider = (props: SavedReportsProviderProps) => {
  const { children } = props
  const [reports, setReports] = useLocalStorage<string, SavedReport[]>(storageKey, [])
  const { setJwt } = useAuth()

  const addReport = (report: SavedReport) => {
    setReports([
      ...reports.filter(({ id }) => id !== report.id),
      {
        ...report,
        content:
          report.content.length > contentMaxLength
            ? `${report.content.slice(0, contentMaxLength)}…`
            : report.content,
      },
    ])
  }

  const removeReport = (report: SavedReport) => {
    setReports([...reports.filter(({ id }) => id !== report.id)])
  }

  const loginIntoReport = async (report: SavedReport) => {
    await setJwt(report.keys.jwt)
    deletePersonalKeys()
    await saveSSOKeys({
      publicKey: report.keys.publicKey,
      privateKey: report.keys.privateKey,
      rememberMe: true,
    })
  }

  const hasSomeSavedReports = reports.length > 0

  return (
    <SavedReportsContext.Provider
      value={{
        reports,
        addReport,
        removeReport,
        loginIntoReport,
        hasSomeSavedReports,
      }}
    >
      {children}
    </SavedReportsContext.Provider>
  )
}
