import { gql, useMutation } from '@apollo/client'
import type { ExtractTypename } from '@faceup/utils'
import { Box } from '@mantine/core'
import type {
  EditEmailCompanyVictimMutation,
  EditEmailCompanyVictimMutationVariables,
  ReportQuery,
} from '../__generated__/globalTypes'
import { EmailNotificationsCheckboxAbstract } from './EmailNotificationsCheckboxAbstract'

type ReportQuery_companyVictimViewer = NonNullable<
  ExtractTypename<NonNullable<ReportQuery['companyVictimViewer']>, 'CompanyVictim'>
>

const mutations = {
  EditEmailCompanyVictim: gql`
    mutation EditEmailCompanyVictimMutation($input: EditEmailCompanyVictimInput!) {
      editEmailCompanyVictim(input: $input) {
        companyVictim {
          id
          email
        }
      }
    }
  `,
}

type Props = {
  viewer: ReportQuery_companyVictimViewer | null
}

const EmailNotificationsCheckbox = ({ viewer }: Props) => {
  const [editCompanyEmail] = useMutation<
    EditEmailCompanyVictimMutation,
    EditEmailCompanyVictimMutationVariables
  >(mutations.EditEmailCompanyVictim)
  return (
    <Box
      sx={{
        maxWidth: '400px',
      }}
    >
      <EmailNotificationsCheckboxAbstract
        isEmailNotificationEnabled={
          viewer?.report?.reportSource?.config?.__typename === 'FormReportSourceConfiguration'
            ? viewer.report.reportSource.config.emailNotificationEnabled
            : false
        }
        onSubmit={async ({ email }) => {
          const { data } = await editCompanyEmail({
            variables: {
              input: {
                email,
              },
            },
          })
          return Boolean(data)
        }}
        email={viewer?.email ?? null}
        isEmailVerified={viewer?.isEmailVerified ?? undefined}
      />
    </Box>
  )
}

export default EmailNotificationsCheckbox
