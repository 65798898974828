import styled from '@emotion/styled'
import { useLayoutEffect, useState } from 'react'

export const mq = {
  xsDown: `@media (max-width: 479px)`,
  xsUp: `@media (min-width: 480px)`,
  smDown: `@media (max-width: 575px)`,
  smUp: `@media (min-width: 576px)`,
  mdDown: `@media (max-width: 767px)`,
  mdUp: `@media (min-width: 768px)`,
  lgDown: `@media (max-width: 991px)`,
  lgUp: `@media (min-width: 992px)`,
  xlDown: `@media (max-width: 1199px)`,
  xlUp: `@media (min-width: 1200px)`,
  xxlDown: `@media (max-width: 1599px)`,
  xxlUp: `@media (min-width: 1600 px)`,
}

type MqTypes = keyof typeof mq

type Props = {
  children: React.ReactNode
  hideWhen: MqTypes
}

export const useMedia = (key: MqTypes): boolean => {
  const [isActive, setIsActive] = useState(false)

  useLayoutEffect(() => {
    const onResizeHandler = () => {
      const params = mq[key].substring(6)
      const { matches } = window.matchMedia(params.trim())
      setIsActive(matches)
    }

    onResizeHandler()

    window.addEventListener('load', onResizeHandler)
    window.addEventListener('resize', onResizeHandler)

    return () => {
      window.removeEventListener('load', onResizeHandler)
      window.removeEventListener('resize', onResizeHandler)
    }
  }, [key])

  return isActive
}

export const HideMedia = ({ children, hideWhen }: Props) => (
  <Wrapper breakpoint={hideWhen}>{children}</Wrapper>
)

const Wrapper = styled.div<{ breakpoint: keyof typeof mq }>`
  ${({ breakpoint }) => mq[breakpoint]} {
    display: none;
  }
`
