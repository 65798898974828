import { useModal } from '@faceup/ui-base'
import { useContext } from 'react'
import { UiContext } from '../UiProvider/UiProvider'

export const useLinkExternalConfirm = () => {
  const { linkExternalConfirm } = useContext(UiContext)
  const modal = useModal()

  return (url: string) => {
    modal.confirm({
      title: linkExternalConfirm?.confirmRedirectMessageTitle ?? 'Leaving',
      content:
        linkExternalConfirm?.confirmRedirectMessageContent ??
        'Are you sure you want to leave this page and follow the link?',
      okText: linkExternalConfirm?.redirectOkButton ?? 'Follow link',
      onOk: () => window.open(url, '_blank', 'noopener noreferrer')?.focus(),
      cancelText: linkExternalConfirm?.redirectCancelButton ?? 'Go back',
    })
  }
}
