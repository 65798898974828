import {
  type FieldValues,
  type FormItemWrapperProps,
  type UseControllerProps,
  useCustomController,
} from '@faceup/form'
import { FormItem, useAwait } from '@faceup/ui'
import { Input, type InputProps, useUiBaseConfig } from '@faceup/ui-base'
import { Loader } from '@mantine/core'

export type SurveyTextInputProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<InputProps & FormItemWrapperProps, 'value' | 'defaultValue' | 'placeholder'> & {
    placeholder?: string | Promise<string>
    loading?: boolean
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  }
export const SurveyTextInput = <T extends FieldValues>(props: SurveyTextInputProps<T>) => {
  const {
    formItemProps,
    inputProps: { onChange: fieldOnChange, placeholder, loading, ...inputProps },
  } = useCustomController(props)
  const awaitedPlaceholder = useAwait(placeholder)

  const { token } = useUiBaseConfig()

  return (
    <FormItem {...formItemProps} variant='black'>
      <Input.TextArea
        {...inputProps}
        placeholder={awaitedPlaceholder}
        onChange={e => {
          fieldOnChange(e)
          props.onChange?.(e)
        }}
        style={{
          border: '1px solid',
          borderColor: token.colorBorderSecondary,
          borderRadius: '8px',
        }}
        icon={loading ? <Loader size='sm' /> : inputProps.icon}
      />
    </FormItem>
  )
}
