import { Box } from '@mantine/core'
import type { ReactNode } from 'react'
import { InputSkeleton, type InputSkeletonPublicProps } from './InputSkeleton'

type InputFakeProps = {
  value?: ReactNode
} & InputSkeletonPublicProps

export const InputFake = ({ value, ...props }: InputFakeProps) => (
  <InputSkeleton role='textbox' {...props}>
    <Box sx={{ width: '100%' }}>{value}</Box>
  </InputSkeleton>
)
