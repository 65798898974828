import type { UntitledIconData } from '@faceup/icons'

export const ucShieldWarning: UntitledIconData = {
  prefix: 'custom',
  name: 'shieldWarning',
  viewBox: '0 0 20 20',
  paths: [
    {
      shape:
        'M14.51 3.727 8.677 1.134a1.664 1.664 0 0 0-1.354 0L1.49 3.727C.887 3.994.5 4.59.5 5.25v3.917c0 6.525 5.366 9.571 7.5 10 2.134-.429 7.5-3.475 7.5-10V5.25c0-.658-.387-1.256-.99-1.523zM8.833 14.583c0 .23-.186.417-.416.417h-.834a.417.417 0 0 1-.416-.417v-.833c0-.23.186-.417.416-.417h.834c.23 0 .416.187.416.417v.833zm0-3.333c0 .23-.186.417-.416.417h-.834a.417.417 0 0 1-.416-.417V5.417c0-.23.186-.417.416-.417h.834c.23 0 .416.187.416.417v5.833z',
      fill: 'currentColor',
    },
  ],
}
