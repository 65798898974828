import { useSearchParams } from '@faceup/router'
import { Box } from '@mantine/core'
import { type ReactNode, useEffect, useState } from 'react'
import { isReferrerAkutan } from '../utils/documentReferrer'

type PointerEventsManagerProps = {
  children: ReactNode
}

/**
 * @description This components allows to disable pointer events primarily for Akutan customization.
 * Chrome is okay with `pointer-events: none` on iframe element,
 * but Firefox also disables scrolling and scrollbar.
 * We want content to be scrollable but not clickable.
 */
const PointerEventsManager = ({ children }: PointerEventsManagerProps) => {
  const [pointerEventsEnabled, setPointerEventsEnabled] = useState<boolean>(true)
  const [params] = useSearchParams()

  // biome-ignore lint/correctness/useExhaustiveDependencies(setPointerEventsEnabled): Probably not needed
  useEffect(() => {
    const hasKeyAndReferrer = document.referrer && params.has('disablePointerEvents')
    if (hasKeyAndReferrer && isReferrerAkutan) {
      setPointerEventsEnabled(false)
    }
  }, [params, setPointerEventsEnabled])

  return (
    <Box sx={pointerEventsEnabled ? undefined : { cursor: 'not-allowed' }}>
      <Box sx={pointerEventsEnabled ? undefined : { pointerEvents: 'none' }}>{children}</Box>
    </Box>
  )
}

export default PointerEventsManager
