import styled from '@emotion/styled'
import { FormattedMessage, defineMessages } from '../TypedIntl'
import { ReportStatusType } from '../__generated__/globalTypes'

const messages = defineMessages<ReportStatusType>({
  Archived: 'Shared.report.status.Archived',
  Open: 'Shared.report.status.Open',
  Closed: 'Shared.report.status.Closed',
})

type ReportStatusProps = {
  status: ReportStatusType
}

export const ReportStatus = (props: ReportStatusProps) => {
  const { status } = props
  return (
    <StatusIndicator type={status} data-cy='report-info-status'>
      <FormattedMessage {...messages[status]} />
    </StatusIndicator>
  )
}

const StatusIndicator = styled.span<{ type?: ReportStatusType | null }>`
  font-weight: 600;
  padding: 6px 8px;
  border-radius: 6px;
  background: ${({ type }) =>
    type === ReportStatusType.Archived
      ? '#062d461f'
      : type === ReportStatusType.Closed
        ? '#0cce6b14'
        : '#fdedd3'};
  color: ${({ type }) =>
    type === ReportStatusType.Archived
      ? '#688699'
      : type === ReportStatusType.Closed
        ? '#0cce6b'
        : '#f7990e'};
`
