import { createContext } from 'react'

export type RecordType = { data: Blob; duration: number }

export enum RecordingStatus {
  Idle = 'Idle',
  Recording = 'Recording',
  Playing = 'Playing',
  Paused = 'Paused',
  Initializing = 'Initializing',
}

type State = {
  victimPin: string | null
  setVictimPin: (pin: string | null) => void
  createdAt: Date | null
  setCreatedAt: (date: Date) => void
  record: RecordType | null
  setRecord: (record: RecordType | null) => void
  buildRecordFile: () => Promise<File | null>
  recordingStatus: RecordingStatus
  setRecordingStatus: (status: RecordingStatus) => void
  tag: string | null
  setTag: (id: string) => void
  isAnonymous: boolean
  setIsAnonymous: (isReportAnonymous: boolean) => void
}

export const ReportFormContext = createContext<State>({
  victimPin: null,
  setVictimPin: () => undefined,
  createdAt: null,
  setCreatedAt: () => undefined,
  record: null,
  setRecord: () => undefined,
  buildRecordFile: () => Promise.resolve(null),
  recordingStatus: RecordingStatus.Idle,
  setRecordingStatus: () => undefined,
  tag: null,
  setTag: () => undefined,
  isAnonymous: true,
  setIsAnonymous: () => undefined,
})
