import { ReportSentContent } from './ReportSentContent'
import { SchoolSentCollapse } from './SchoolSentCollapse'

export const ReportSentUnregisteredSchool = () => (
  <ReportSentContent
    description={
      <>
        Bohužel, tvoje škola není v Nenech to být zaregistrována. Nemůžeme tedy zaručit, že se budou
        upozorněním zabývat. Zkusíme se s nimi ale spojit, aby se mu věnovat začali.
      </>
    }
  >
    <SchoolSentCollapse />
  </ReportSentContent>
)
