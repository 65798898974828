import { gql } from '@apollo/client'
import { type IsCheckReportAvailable_reportSource, Plan } from '../__generated__/globalTypes'

/**
 * I think this whole hook can be replaced with one boolean field in GQL on ReportSource.
 */
export const IsCheckReportAvailableFragments = {
  IsCheckReportAvailable_reportSource: gql`
    fragment IsCheckReportAvailable_reportSource on PublicReportSource {
      id
      institution {
        id
        billing {
          id
          plan
        }
      }
    }
  `,
}

export const useIsCheckReportAvailable = () => ({
  isCheckReportAvailable: (reportSource: IsCheckReportAvailable_reportSource): boolean =>
    reportSource.institution?.billing?.plan
      ? ![Plan.Unregistered, Plan.Free].includes(reportSource.institution.billing.plan)
      : true,
})
