import type { UntitledIconData } from '../../UntitledIconData'

const usSend01: UntitledIconData = {
  prefix: 'us',
  name: 'send-01',
  paths: [
    {
      shape:
        'M21.5951 1.4575C21.1993 1.32529 20.8308 1.4209 20.6476 1.47411C20.448 1.5321 20.2101 1.625 19.9714 1.7182L3.01429 8.3356C2.74746 8.43969 2.48901 8.54051 2.28953 8.63914C2.11718 8.72436 1.75084 8.91405 1.54635 9.30766C1.32085 9.74172 1.32115 10.2585 1.54717 10.6923C1.75212 11.0856 2.11868 11.2749 2.29113 11.3599C2.49072 11.4583 2.74924 11.5588 3.01619 11.6626L7.73621 13.4981C8.08833 13.6351 8.26439 13.7035 8.43954 13.7077C8.59436 13.7115 8.74793 13.6792 8.88814 13.6134C9.04676 13.539 9.18034 13.4054 9.44749 13.1383L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L10.8617 14.5525C10.5946 14.8197 10.461 14.9532 10.3866 15.1119C10.3208 15.2521 10.2885 15.4056 10.2923 15.5605C10.2965 15.7356 10.3649 15.9117 10.5019 16.2638L12.3374 20.9837C12.4412 21.2507 12.5417 21.5093 12.6401 21.7089C12.7251 21.8813 12.9144 22.2479 13.3077 22.4528C13.7415 22.6788 14.2583 22.6791 14.6923 22.4536C15.086 22.2492 15.2756 21.8828 15.3609 21.7105C15.4595 21.511 15.5603 21.2526 15.6644 20.9858L22.2818 4.02861C22.375 3.7899 22.4679 3.55198 22.5259 3.35235C22.5791 3.16916 22.6747 2.8007 22.5425 2.40493C22.3931 1.95779 22.0422 1.60688 21.5951 1.4575Z',
    },
  ],
}

export default usSend01
