import { gql } from '@apollo/client'
import type { LogoSize } from '@faceup/utils'
import { Box, type Sx } from '@mantine/core'
import { useContext } from 'react'
import { LayoutContext } from '../Contexts/LayoutContext'
import type {
  InstitutionLogoFragments_institution,
  InstitutionLogoFragments_partner,
} from '../__generated__/globalTypes'
import FaceUpLogo from '../assets/logo-fu.svg'
import NNTBLogo from '../assets/logo-nntb.svg'
import useConfigForProject from '../hooks/useConfigForProject'

export const InstitutionLogoFragments = {
  InstitutionLogoFragments_institution: gql`
    fragment InstitutionLogoFragments_institution on PublicCompany {
      id
      name
    }
  `,
  InstitutionLogoFragments_partner: gql`
    fragment InstitutionLogoFragments_partner on PartnerInstitution {
      id
      name
      config {
        id
        rectangularLogoUrl
      }
    }
  `,
}

const logoSizeStyle: Record<LogoSize, Sx> = {
  md: {
    maxWidth: 170,
    maxHeight: 44,
  },
  lg: {
    maxWidth: 170 * 1.5,
    maxHeight: 44 * 1.5,
  }, // header size 42 px
  xl: {
    maxWidth: 170 * 2,
    maxHeight: 44 * 2,
  }, // header size 54 px
}

type InstitutionLogoProps = {
  logoSize: LogoSize
  logoUrl: string | null
  institutionOrPartner:
    | InstitutionLogoFragments_institution
    | InstitutionLogoFragments_partner
    | null
}

const InstitutionLogo = (props: InstitutionLogoProps) => {
  const { logoSize, logoUrl, institutionOrPartner } = props
  const { theme } = useContext(LayoutContext)
  const { isNntb } = useConfigForProject()

  return (
    <Box
      component='img'
      src={logoUrl ?? (isNntb ? NNTBLogo : FaceUpLogo)}
      alt={institutionOrPartner?.name ?? theme}
      sx={{
        ...logoSizeStyle[logoSize],
        width: 'auto',
      }}
    />
  )
}

export default InstitutionLogo
