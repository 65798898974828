import { useLocation, useMatchPath } from '@faceup/router'
import { useCallback, useEffect } from 'react'
import { defineMessages, useIntl } from '../TypedIntl'
import { routes } from '../hooks/useRoutes'

const messages = defineMessages({
  report: 'FollowUp.pages.report',
  survey: 'FollowUp.pages.survey',
})

const setDocumentTitle = (title: string, domain?: string) =>
  (document.title = `${title} ${domain ? `| ${domain}` : ''}`)

const useGetTitle = () => {
  const { formatMessage } = useIntl()
  const matchPath = useMatchPath()

  const getTitle = useCallback(() => {
    if (
      matchPath(routes.survey, {
        wildcard: true,
      })
    ) {
      return formatMessage(messages.survey)
    }
    return formatMessage(messages.report)
  }, [formatMessage, matchPath])

  return getTitle
}

const NavigationChange = () => {
  const { pathname } = useLocation()
  const getTitle = useGetTitle()

  const domain = 'FaceUp'

  // biome-ignore lint/correctness/useExhaustiveDependencies(pathname):
  // biome-ignore lint/correctness/useExhaustiveDependencies(domain):
  useEffect(() => {
    const title = getTitle()
    if (title) {
      setDocumentTitle(title, domain)
    }
  }, [pathname, domain, getTitle])

  return null
}

export default NavigationChange
