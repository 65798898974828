import { fontWeightBold } from '@faceup/ui'
import { Accordion, Text, useMantineTheme } from '@mantine/core'
import { type ReactNode, useState } from 'react'

const helps: {
  id: string
  title: ReactNode
  content: ReactNode
}[] = [
  {
    id: '1',
    title: 'Co se bude dít dál?',
    content: (
      <>
        Pokud vše půjde, jak má, mělo by se tvé upozornění dostat k takové osobě na tvé škole, která
        má s řešením šikany a podobných problémů zkušenosti. Ta to nenechá být a zkusí udělat
        maximum proto, aby pomohla. Pokud by se nic nedělo, můžeš jí skrz NNTB napsat ještě jednou,
        nebo na škole vyhledat vašeho metodika prevence, případně školního psychologa a o problémech
        jim říct osobně.
      </>
    ),
  },
  {
    id: '2',
    title: 'Chceš si o problému popovídat?',
    content: (
      <>
        Pokud si budeš chtít o problému promluvit, obrať se na{' '}
        <a href='https://www.linkabezpeci.cz/' target='_blank' rel='noopener noreferrer'>
          Linku bezpečí
        </a>
        . V případě život ohrožující situace zavolejte linku 112.
      </>
    ),
  },
]

export const SchoolSentCollapse = () => {
  const [activeKey, setActiveKey] = useState<string | null>(null)
  const theme = useMantineTheme()

  return (
    <Accordion
      value={activeKey}
      onChange={setActiveKey}
      styles={{
        label: {
          fontWeight: fontWeightBold,
        },
      }}
    >
      {helps.map(({ id, title, content }) => (
        <Accordion.Item key={id} value={id}>
          <Accordion.Control
            sx={{
              color: activeKey === id ? theme.colors.primary[7] : undefined,
            }}
          >
            <Text>{title}</Text>
          </Accordion.Control>
          <Accordion.Panel>{content}</Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  )
}
