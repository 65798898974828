import { UnstyledButton } from '@mantine/core'
import type { ReactNode } from 'react'
import { type RedactTextType, useRedaction } from './RedactionProvider'
import { getText } from './TextToRedact'

type AttachmentRedactProps = {
  children: ReactNode
  type: RedactTextType
  text: string
}

export const AttachmentRedact = (props: AttachmentRedactProps) => {
  const { children, type, text: originalText } = props
  const { isRedactionActive, texts, redactText } = useRedaction()

  if (isRedactionActive) {
    const redactedText = getText(texts, type)
    const text = redactedText ?? originalText

    return (
      <UnstyledButton
        sx={theme => ({
          color: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          fontFamily: 'inherit',
          '&:hover': {
            backgroundColor: theme.colors.dark[2],
          },
        })}
        onClick={() => {
          redactText(
            type,
            {
              start: 0,
              end: text.length,
              variant:
                redactedText === undefined || redactedText === originalText
                  ? 'redacted'
                  : 'notRedacted',
            },
            originalText
          )
        }}
      >
        {text}
      </UnstyledButton>
    )
  }

  return <>{children}</>
}
