import { useMessage } from '@faceup/ui-base'
import {
  ALLOWED_FILE_MIME_TYPE_EXTENSION_MAP,
  ATTACHMENT_MAX_FILES_COUNT,
  ATTACHMENT_MAX_FILE_SIZE,
  hasFileValidExtension,
  removeFileMetadata,
} from '@faceup/utils'
import type { CSSProperties, ReactNode } from 'react'
import { useDropzone as useDefDropzone } from 'react-dropzone'
import AttachmentUploadList, {
  type Attachment,
  type AttachmentUploadListProps,
  type EditingAttachmentsProps,
  type NotEditingAttachmentsProps,
} from '../AttachmentUploadList/AttachmentUploadList'

type HandleDropTranslations = {
  tooManyFiles: string
  tooLargeFile: string
  unsupportedMimetype: string
}

export type DropzoneProps = {
  attachments: Attachment[]
  setAttachments: (attachmnets: Attachment[]) => void
  disabled?: boolean
  translations: HandleDropTranslations & AttachmentUploadListProps['translations']
  style?: CSSProperties
  children: (open: () => void, isMobile: boolean) => ReactNode
} & (EditingAttachmentsProps | NotEditingAttachmentsProps)

const Dropzone = (props: DropzoneProps) => {
  const {
    translations,
    attachments,
    alreadyUploadedAttachments,
    markAttachmentForDeletion,
    setAttachments,
  } = props
  const handleDrop = useHandleDrop()

  const { getRootProps, getInputProps, open } = useDefDropzone({
    onDrop: (acceptedFiles: File[]) =>
      handleDrop(acceptedFiles, translations, attachments, setAttachments),
    noClick: true,
    noKeyboard: true,
    disabled: props.disabled,
  })

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      navigator.userAgent
    )
  const acceptMimeTypes = Object.keys(ALLOWED_FILE_MIME_TYPE_EXTENSION_MAP).join(',')

  return (
    <>
      <div
        {...getRootProps({
          className: 'dropzone',
          disabled: props.disabled,
          style: { marginBottom: '1rem', textAlign: 'left', ...props.style },
        })}
      >
        <input {...getInputProps()} accept={acceptMimeTypes} />
        {props.children(open, isMobile)}
      </div>

      <AttachmentUploadList
        translations={translations}
        attachments={attachments}
        setAttachments={setAttachments}
        alreadyUploadedAttachments={alreadyUploadedAttachments}
        markAttachmentForDeletion={markAttachmentForDeletion}
      />
    </>
  )
}

export const useHandleDrop = () => {
  const message = useMessage()
  const handleDrop = async (
    acceptedFiles: File[],
    translations: HandleDropTranslations,
    attachments: Attachment[],
    setAttachments: (attachments: Attachment[]) => void
  ) => {
    if (acceptedFiles.length > ATTACHMENT_MAX_FILES_COUNT) {
      message.error({ content: translations.tooManyFiles, className: 'cy-dropzone-too-many-files' })
      return
    }

    const filesArray = acceptedFiles.filter(file => {
      if (file.size > ATTACHMENT_MAX_FILE_SIZE) {
        message.error({
          content: translations.tooLargeFile,
          className: 'cy-dropzone-too-large-file',
        })
        return false
      }

      if (
        !Object.keys(ALLOWED_FILE_MIME_TYPE_EXTENSION_MAP).includes(file.type) ||
        !hasFileValidExtension(file.name, file.type)
      ) {
        message.error({
          content: translations.unsupportedMimetype,
          className: 'cy-dropzone-unsupported-mimetype',
        })
        return false
      }

      return !attachments.find(f => f.compareKey === generateFileCompareKey(file))
    })

    if (attachments.length + filesArray.length > ATTACHMENT_MAX_FILES_COUNT) {
      message.error({ content: translations.tooManyFiles, className: 'cy-dropzone-too-many-files' })
      return
    }

    const temp: Attachment[] = filesArray.map(file => ({
      file,
      compareKey: generateFileCompareKey(file),
      loading: true,
      processed: false,
    }))
    setAttachments([...attachments, ...temp])

    await Promise.all(
      temp.map(async attachment => {
        const { file, processed } = await removeFileMetadata(attachment.file)
        attachment.file = file
        attachment.processed = processed
        attachment.loading = false

        setAttachments([...attachments, ...temp])
      })
    )
  }
  return handleDrop
}

export const generateFileCompareKey = (file: File) =>
  `${file.type}; ${file.name}; ${file.lastModified}`

export default Dropzone
