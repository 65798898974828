import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { Stack, Text } from '@mantine/core'
import type { ReactNode } from 'react'

type ButtonContentProps = {
  icon: UntitledIconData
  label: ReactNode
}

export const ButtonContent = (props: ButtonContentProps) => {
  const { icon, label } = props
  return (
    <Stack my='sm' mx={4} spacing={6}>
      <UntitledIcon icon={icon} size={20} />
      <Text
        ta='center'
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {label}
      </Text>
    </Stack>
  )
}
