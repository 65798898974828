import { deletePersonalKeys } from '@faceup/crypto'
import useAuth from './auth'

const useReportAuth = () => {
  const { removeJwt, getJwt } = useAuth()

  const logout = async () => {
    removeJwt()
    deletePersonalKeys()
  }

  const isAuthenticated = () => Boolean(getJwt())

  return { logout, isAuthenticated }
}

export default useReportAuth
