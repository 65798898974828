import { gql } from '@apollo/client'
import { useManageReportCustomInputs } from '@faceup/report'
import type { Attachment } from '@faceup/ui'
import { LONG_STRING_MAX_LENGTH, SHORT_STRING_MAX_LENGTH, STRING_MIN_LENGTH } from '@faceup/utils'
import { useCallback, useContext, useMemo, useState } from 'react'
import { ReportFormContext } from '../../../Contexts/ReportFormContext'
import { UserContext } from '../../../Contexts/UserContext'
import {
  Institution,
  type useManageCreateReportForm_publicCompany,
  type useManageCreateReportForm_publicReportSource,
} from '../../../__generated__/globalTypes'
import { useRecipients } from './useRecipients'

export const useManageCreateReportFormFragments = {
  useManageCreateReportForm_publicReportSource: gql`
    fragment useManageCreateReportForm_publicReportSource on PublicReportSource {
      id
      isDefault
      config {
        ... on FormReportSourceConfiguration {
          id
          recipientsOnReportForm
        }
      }

      organizationalUnits {
        id
        name
      }
    }
  `,
  useManageCreateReportForm_publicCompany: gql`
    fragment useManageCreateReportForm_publicCompany on PublicCompany {
      id
      name
    }
  `,
}

type UseManageCreateReportFormProps = {
  institution: useManageCreateReportForm_publicCompany | null
  reportSource: useManageCreateReportForm_publicReportSource | null
}

export const useManageCreateReportForm = ({
  institution,
  reportSource,
}: UseManageCreateReportFormProps) => {
  const { record, setRecord, buildRecordFile, isAnonymous, setIsAnonymous } =
    useContext(ReportFormContext)

  const { reportSource: institutionFromContext } = useContext(UserContext)

  const [attachments, setAttachments] = useState<Attachment[]>([])

  const valueWithError = useCallback(
    <T>(v: { value: T; error: boolean }) => ({ ...v, error: true }),
    []
  )

  const [categoryInput, setCategoryInput] = useState<{
    value: string | undefined
    error: boolean
  }>({
    value: undefined,
    error: false,
  })

  const [nameInput, setNameInput] = useState({
    value: '',
    error: false,
  })

  const [moreInfoInput, setMoreInfoInput] = useState({
    value: '',
    error: false,
  })

  const [senderNameInput, setSenderNameInput] = useState({
    value: '',
    error: false,
  })

  const [isVoiceRecordingVisible, setIsVoiceRecordingVisible] = useState(false)

  const selectableOrganizationUnits = useMemo(
    () => reportSource?.organizationalUnits ?? [],
    [reportSource?.organizationalUnits]
  )

  const [organizationUnitInput, setOrganizationUnitInput] = useState({
    value: selectableOrganizationUnits.length > 1 ? '' : selectableOrganizationUnits?.[0]?.id,
    error: false,
  })

  const selectedOrganizationalUnit = useMemo(
    () =>
      selectableOrganizationUnits.find(
        selectable => selectable?.id === organizationUnitInput.value
      ) ?? institution,
    [institution, organizationUnitInput.value, selectableOrganizationUnits]
  )

  const isSchoolDefaultForm =
    institutionFromContext?.type === Institution.School && reportSource?.isDefault

  const formConfig =
    reportSource?.config?.__typename === 'FormReportSourceConfiguration'
      ? reportSource.config
      : null
  const { recipients, changeRecipient, areRecipientsLoading } = useRecipients(
    selectedOrganizationalUnit?.id,
    categoryInput.value,
    reportSource?.id,
    formConfig?.recipientsOnReportForm
  )

  const {
    customFormItems,
    setCustomFormItems,
    validateCustomFormItems,
    resetCustomFormItems,
    getValuesForMutation,
  } = useManageReportCustomInputs(categoryInput.value ?? '', reportSource?.id ?? null)

  const clearForm = useCallback(() => {
    setNameInput({ value: '', error: false })
    setMoreInfoInput({ value: '', error: false })
    setAttachments([])
    setRecord(null)
    setCategoryInput({ value: undefined, error: false })
    setSenderNameInput({ value: '', error: false })
    resetCustomFormItems()
  }, [setRecord, resetCustomFormItems])

  const validateForm = useCallback(async () => {
    let ok = true
    if (!categoryInput.value) {
      setCategoryInput(valueWithError)

      ok = false
    }

    if (
      !record &&
      (moreInfoInput.value.length < STRING_MIN_LENGTH ||
        moreInfoInput.value.length > LONG_STRING_MAX_LENGTH) &&
      !isSchoolDefaultForm
    ) {
      setMoreInfoInput(valueWithError)

      ok = false
    }

    if (
      !isAnonymous &&
      (senderNameInput.value.length < STRING_MIN_LENGTH ||
        senderNameInput.value.length > SHORT_STRING_MAX_LENGTH) &&
      !isSchoolDefaultForm
    ) {
      setSenderNameInput(valueWithError)

      ok = false
    }

    if (
      (nameInput.value.trim().length < STRING_MIN_LENGTH ||
        !nameInput.value.trim().includes(' ') ||
        nameInput.value.trim().length > SHORT_STRING_MAX_LENGTH) &&
      isSchoolDefaultForm
    ) {
      setNameInput(valueWithError)

      ok = false
    }

    if (selectableOrganizationUnits.length > 1 && !organizationUnitInput.value) {
      setOrganizationUnitInput(valueWithError)

      ok = false
    }

    if (!(await validateCustomFormItems())) {
      ok = false
    }

    return ok
  }, [
    categoryInput.value,
    record,
    moreInfoInput.value.length,
    isSchoolDefaultForm,
    isAnonymous,
    senderNameInput.value,
    nameInput.value,
    selectableOrganizationUnits.length,
    organizationUnitInput.value,
    valueWithError,
    validateCustomFormItems,
  ])

  return {
    categoryInput,
    setCategoryInput,
    moreInfoInput,
    setMoreInfoInput,
    senderNameInput,
    setSenderNameInput,
    nameInput,
    setNameInput,
    attachments,
    setAttachments,
    customFormItems,
    setCustomFormItems,
    getValuesForMutation,
    clearForm,
    record,
    setRecord,
    buildRecordFile,
    isVoiceRecordingVisible,
    setIsVoiceRecordingVisible,
    validateForm,
    organizationUnitInput,
    setOrganizationUnitInput,
    selectedOrganizationalUnit,
    selectableOrganizationUnits,
    isAnonymous,
    setIsAnonymous,
    recipients,
    areRecipientsLoading,
    changeRecipient,
    isSchoolDefaultForm,
  }
}
