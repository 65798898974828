import { useNavigateGoBack } from '@faceup/router'
import { Button } from '@faceup/ui'
import { Box, Image, Stack, Text, Title } from '@mantine/core'
import PlainLayout from '../../Components/PlainLayout'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import OfflineIllustration from './assets/offline-illustration.svg'

const messages = defineMessages({
  title: 'FollowUp.offline.title',
  description: 'FollowUp.offline.description',
  button: 'FollowUp.offline.button',
})

const Offline = () => {
  const navigateGoBack = useNavigateGoBack()

  return (
    <PlainLayout>
      <Box
        sx={{
          margin: 'auto',
        }}
      >
        <Stack align='center' spacing={40}>
          <Image src={OfflineIllustration} width='auto' height={180} />
          <Stack align='center' spacing='sm'>
            <Title order={3}>
              <FormattedMessage {...messages.title} />
            </Title>

            <Text fz={16}>
              <FormattedMessage {...messages.description} />
            </Text>
          </Stack>
          <Button variant='secondary' onClick={() => navigateGoBack(-1)}>
            <FormattedMessage {...messages.button} />
          </Button>
        </Stack>
      </Box>
    </PlainLayout>
  )
}

export default Offline
