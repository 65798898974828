import { FileOpener } from '@capacitor-community/file-opener'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { notification } from '@faceup/ui-base'
import { blobToBase64 } from '@faceup/utils'
import { defineMessages, useIntl } from '../TypedIntl'

const messages = defineMessages({
  title: 'FollowUp.attachments.download.error.title',
  description: 'FollowUp.attachments.download.error.description',
})

// This file downloader should be used only in mobile app, though it should
// work fine in browser as well, it is better (for user) to let browser handle
// this itself
const useMobileFileDownload = () => {
  const { formatMessage } = useIntl()

  const downloadHandler = async (content: Blob, name: string) => {
    try {
      const hasPermissions = await Filesystem.checkPermissions()
      if (hasPermissions.publicStorage !== 'granted') {
        await Filesystem.requestPermissions()
      }

      const file = await Filesystem.writeFile({
        path: name,
        data: await blobToBase64(content),
        directory: Directory.External,
      })
      await FileOpener.open({ filePath: file.uri })
    } catch {
      notification.error({
        message: formatMessage(messages.title),
        description: formatMessage(messages.description),
      })
    }
  }

  return downloadHandler
}

export default useMobileFileDownload
