import { gql } from '@apollo/client'
import { omitNullInArray } from '@faceup/utils'
import { Box, Divider, Footer as MantineFooter } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import type { InstitutionLayoutWebFooter_reportSource } from '../../../../__generated__/globalTypes'
import LanguageDropdown from '../../../LanguageDropdown'
import { LanguageDropdownFragments } from '../../../LanguageDropdown/LanguageDropdown'
import { InstitutionLayoutMenuItemsFragments, MenuItems } from '../../../MenuItems'

export const InstitutionLayoutWebFooterFragments = {
  InstitutionLayoutWebFooter_reportSource: gql`
    fragment InstitutionLayoutWebFooter_reportSource on PublicReportSource {
      id

      ...InstitutionLayoutMenuItems_reportSource
      ...LanguageDropdown_reportSource
    }
    ${InstitutionLayoutMenuItemsFragments.InstitutionLayoutMenuItems_reportSource}
    ${LanguageDropdownFragments.LanguageDropdown_reportSource}
  `,
}

type WebFooterProps = {
  reportSource: InstitutionLayoutWebFooter_reportSource
  shouldShowLanguagesDropdown: boolean
}

export const WebFooter = ({ reportSource, shouldShowLanguagesDropdown }: WebFooterProps) => {
  const { ref: refFooter, height: heightFooter } = useElementSize()
  const { ref: refLanguageDropdown, height: heightLanguageDropdown } = useElementSize()
  const height = heightFooter + (shouldShowLanguagesDropdown ? heightLanguageDropdown : 0)

  return (
    <MantineFooter height={height}>
      <Box
        ref={refLanguageDropdown}
        sx={
          shouldShowLanguagesDropdown
            ? {}
            : {
                visibility: 'hidden',
                position: 'absolute',
                pointerEvents: 'none',
              }
        }
      >
        <Box px='xl' py='sm'>
          <LanguageDropdown
            availableLanguages={omitNullInArray(reportSource.languages)}
            defaultLanguage={reportSource.defaultLanguage}
          />
        </Box>
        <Divider color='gray.2' />
      </Box>

      <Box ref={refFooter}>
        <Box p='xl'>
          <MenuItems publicCompany={reportSource} direction='vertical' />
        </Box>
      </Box>
    </MantineFooter>
  )
}
