import { useCallback, useState } from 'react'

type StorageType = 'localStorage' | 'sessionStorage'

// https://usehooks.com/useLocalStorage

export const useStorage = <T extends string, D>(
  key: T,
  storageType: StorageType,
  initialValue: D
) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<D>(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }
    try {
      // Get from storage by key
      const item = window[storageType].getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.error(error)
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage or sessionStorage.
  const setValue = useCallback(
    (value: D | ((val: D) => D)) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value
        // Save state
        setStoredValue(valueToStore)
        // Save to storage
        if (typeof window !== 'undefined') {
          window[storageType].setItem(key, JSON.stringify(valueToStore))
        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.error(error)
      }
    },
    [key, storageType, storedValue]
  )

  return [storedValue, setValue] as const
}
