import { encryptFileWithReportKey } from './files'
import { readReportKey, readReportKeyWithoutContext } from './report'
import { getSodium, handleError } from './utils/general'

export const readComment = async (
  ciphertext: string | null,
  nonce: string,
  recipientKey: string
) => {
  if (!ciphertext) {
    return { text: '' }
  }

  const sodium = await getSodium()

  try {
    const payload = await readReportKey(recipientKey)

    if (typeof payload === 'string') {
      throw new Error(payload)
    }

    const { reportKey } = payload
    const decryptedText = sodium.crypto_secretbox_open_easy(
      sodium.from_base64(ciphertext),
      sodium.from_base64(nonce),
      reportKey,
      'text'
    )

    return { text: decryptedText }
  } catch (e) {
    return handleError(e)
  }
}

export const createCommentWithoutContext = async (
  text: string,
  recipientKey: string,
  recipientPublicKey: string,
  recipientPrivateKey: string
) => {
  const sodium = await getSodium()

  try {
    const nonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES)
    const payload = await readReportKeyWithoutContext(
      recipientKey,
      recipientPublicKey,
      recipientPrivateKey
    )
    if (typeof payload === 'string') {
      throw new Error(payload)
    }

    const { reportKey } = payload
    const ciphertext = sodium.crypto_secretbox_easy(text, nonce, reportKey, 'base64')

    return {
      ciphertext,
      nonce: sodium.to_base64(nonce),
    }
  } catch (e) {
    return handleError(e)
  }
}

export const createComment = async (
  text: string,
  recipientKey: string,
  attachments: File[] = []
) => {
  const sodium = await getSodium()

  try {
    const nonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES)
    const payload = await readReportKey(recipientKey)
    if (typeof payload === 'string') {
      throw new Error(payload)
    }

    const { reportKey } = payload
    const ciphertext = sodium.crypto_secretbox_easy(text, nonce, reportKey, 'base64')
    const encryptedAttachments = await Promise.all(
      attachments.map(attachment => encryptFileWithReportKey(attachment, reportKey))
    )

    const erroredAttachment = encryptedAttachments.find(
      attachment => typeof attachment === 'string'
    ) as string

    if (erroredAttachment) {
      throw new Error(erroredAttachment)
    }

    return {
      ciphertext,
      nonce: sodium.to_base64(nonce),
      attachments: encryptedAttachments as File[],
    }
  } catch (e) {
    return handleError(e)
  }
}
