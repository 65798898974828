import moment from 'moment-timezone'
import { useCallback, useEffect, useRef } from 'react'
import { RecordingStatus } from '../../Contexts/ReportFormContext'
import { defineMessages, useIntl } from '../../TypedIntl'

type Props = {
  stream: MediaStream | null
  status: RecordingStatus
  startTime: number
} & React.DetailedHTMLProps<React.CanvasHTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement>

type Position = {
  x: number
  y: number
  width: number
  height: number
}

/**
 * This changes how uniform the wave is.
 * If the number is low, we have only few bar charts.
 * If the number is higher, you get more of a wave.
 */
const FAST_FOURIER_TRANSFORM_SIZE = 128
const BAR_WIDTH = 5
const REDRAW_TIME = 50
const MAX_DRAWN_BARS = 100

const statusMessages = defineMessages<Exclude<RecordingStatus, RecordingStatus.Recording>>({
  Idle: 'FollowUp.voiceRecording.status.Idle',
  Playing: 'FollowUp.voiceRecording.status.Playing',
  Paused: 'FollowUp.voiceRecording.status.Paused',
  Initializing: 'FollowUp.voiceRecording.status.Initializing',
})

const VoiceRecordingWaveCanvas = ({
  stream,
  status,
  startTime,
  width: canvasWidth,
  height: canvasHeight,
  ...props
}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const { formatMessage } = useIntl()

  const drawChartLine = (
    context: CanvasRenderingContext2D,
    { width, height }: Pick<Position, 'width' | 'height'>
  ) => {
    context.strokeStyle = '#b5c0c8'
    context.lineWidth = 1
    context.beginPath()
    context.moveTo(0, height / 2)
    context.lineTo(width, height / 2)
    context.stroke()
  }

  const drawRoundedRectangle = (
    context: CanvasRenderingContext2D,
    { x, y, width, height }: Position
  ) => {
    const radius = width / 2

    context.beginPath()
    context.moveTo(x + radius, y)
    context.arcTo(x + width, y, x + width, y + height, radius)
    context.arcTo(x + width, y + height, x, y + height, radius)
    context.arcTo(x, y + height, x, y, radius)
    context.arcTo(x, y, x + width, y, radius)
    context.closePath()

    context.fillStyle = '#0e9af7'
    context.fill()
  }

  const createConnectedAnalyser = (stream: MediaStream) => {
    const audioContext = new AudioContext()
    const source = audioContext.createMediaStreamSource(stream)
    const analyser = audioContext.createAnalyser()

    analyser.fftSize = FAST_FOURIER_TRANSFORM_SIZE

    source.connect(analyser)

    return analyser
  }

  const clearCanvas = (
    context: CanvasRenderingContext2D,
    { width, height }: Pick<Position, 'width' | 'height'>
  ) => {
    context.fillStyle = '#fff'
    context.fillRect(0, 0, width, height)
    context.fill()
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies(drawRoundedRectangle):
  const drawBars = useCallback(
    (
      context: CanvasRenderingContext2D,
      aggregatedValues: number[],
      { width, height }: Pick<Position, 'width' | 'height'>
    ) => {
      aggregatedValues.forEach((value, index, array) => {
        // we need to adjust the height of the bar to at least 3 pixels, because we do not get dot, but weird shape
        const barHeight = Math.max(value, 3)

        drawRoundedRectangle(context, {
          height: barHeight,
          width: BAR_WIDTH,
          x: width - (array.length - index) * (BAR_WIDTH * 2),
          y: height / 2 - barHeight / 2,
        })
      })
    },
    []
  )

  const drawText = useCallback(
    (
      context: CanvasRenderingContext2D,
      text: string,
      { width, height }: Pick<Position, 'width' | 'height'>
    ) => {
      context.font = '16px Inter'
      context.fillStyle = '#b5c0c8'
      const textWidth = context.measureText(text).width
      context.fillText(text, width / 2 - textWidth / 2, height - 20)
    },
    []
  )

  const formatDuration = (start: number) => moment.utc(Date.now() - start).format('m:ss')

  // biome-ignore lint/correctness/useExhaustiveDependencies(clearCanvas):
  // biome-ignore lint/correctness/useExhaustiveDependencies(drawChartLine):
  // biome-ignore lint/correctness/useExhaustiveDependencies(createConnectedAnalyser):
  // biome-ignore lint/correctness/useExhaustiveDependencies(formatDuration):
  useEffect(() => {
    const width = Number(canvasWidth ?? 0)
    const height = Number(canvasHeight ?? 0)
    const canvas = canvasRef.current
    if (!canvas) {
      return
    }
    const ratio = window.devicePixelRatio
    canvas.width = width * ratio
    canvas.height = height * ratio
    canvas.style.width = `${width}px`
    canvas.style.height = `${height}px`
    canvas.getContext('2d')?.scale(ratio, ratio)
    // we need to get scaled context
    const context = canvas.getContext('2d')

    if (!context) {
      return
    }

    let animationFrameId = 0
    let intervalId = 0

    clearCanvas(context, { width, height })
    drawChartLine(context, { width, height })
    drawText(
      context,
      status === RecordingStatus.Recording
        ? formatDuration(startTime)
        : formatMessage(statusMessages[status]),
      { width, height }
    )

    if (!stream || status !== RecordingStatus.Recording) {
      return
    }

    const analyser = createConnectedAnalyser(stream)
    const newValues = new Uint8Array(analyser.frequencyBinCount)
    const barAggregatedValues: number[] = []

    const drawChart = () => {
      analyser.getByteFrequencyData(newValues)

      const aggregatedNewValues =
        newValues.reduce((acc, value) => acc + value, 0) / newValues.length

      barAggregatedValues.push(aggregatedNewValues)
      if (barAggregatedValues.length > MAX_DRAWN_BARS) {
        barAggregatedValues.shift()
      }

      clearCanvas(context, { width, height })
      drawChartLine(context, { width, height })
      drawBars(context, barAggregatedValues, { width, height })
      drawText(
        context,
        status === RecordingStatus.Recording
          ? formatDuration(startTime)
          : formatMessage(statusMessages[status]),
        { width, height }
      )
    }

    intervalId = window.setInterval(() => {
      animationFrameId = requestAnimationFrame(drawChart)
    }, REDRAW_TIME)

    return () => {
      clearInterval(intervalId)
      cancelAnimationFrame(animationFrameId)
    }
  }, [stream, drawBars, drawText, status, startTime, formatMessage, canvasWidth, canvasHeight])

  return <canvas ref={canvasRef} {...props} />
}

export default VoiceRecordingWaveCanvas
