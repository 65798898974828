import { createContext } from 'react'
import type { Clients } from '../utils/useCustomizationByURLParams'

export enum Theme {
  NNTB = 'NNTB',
  FaceUp = 'FaceUp',
}

type State = {
  theme: Theme
  client: Clients | null
  setClient: (client: Clients | null) => void
}

export const LayoutContext = createContext<State>({
  theme: Theme.FaceUp,
  client: null,
  setClient: () => undefined,
})
