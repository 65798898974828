import '@faceup/moment'
import { Device } from '@capacitor/device'
import { locales } from '@faceup/ui-base'
import { convertBasenameToLanguage } from '@faceup/utils'

export type BaseTranslations = Awaited<ReturnType<(typeof translations)['messages']['En_Us']>>

const translations = {
  messages: {
    Af: () => import('./Translations/af.json'),
    Am: () => import('./Translations/am.json'),
    Ar: () => import('./Translations/ar.json'),
    Az: () => import('./Translations/az.json'),
    Bg: () => import('./Translations/bg.json'),
    Bn: () => import('./Translations/bn.json'),
    Bs: () => import('./Translations/bs.json'),
    Ca: () => import('./Translations/ca.json'),
    Cs: () => import('./Translations/cs.json'),
    Cy: () => import('./Translations/cy.json'),
    Da: () => import('./Translations/da.json'),
    De: () => import('./Translations/de.json'),
    El: () => import('./Translations/el.json'),
    En_Gb: () => import('./Translations/en-GB.json'),
    En_Us: () => import('./Translations/en-US.json'),
    Es: () => import('./Translations/es.json'),
    Es_Mx: () => import('./Translations/es-MX.json'),
    Et: () => import('./Translations/et.json'),
    Fa: () => import('./Translations/fa.json'),
    Fa_Af: () => import('./Translations/fa-AF.json'),
    Fi: () => import('./Translations/fi.json'),
    Fr: () => import('./Translations/fr.json'),
    Fr_Ca: () => import('./Translations/fr-CA.json'),
    Ga: () => import('./Translations/ga.json'),
    Gu: () => import('./Translations/gu.json'),
    Ha: () => import('./Translations/ha.json'),
    He: () => import('./Translations/he.json'),
    Hi: () => import('./Translations/hi.json'),
    Hr: () => import('./Translations/hr.json'),
    Ht: () => import('./Translations/ht.json'),
    Hu: () => import('./Translations/hu.json'),
    Hy: () => import('./Translations/hy.json'),
    Id: () => import('./Translations/id.json'),
    Is: () => import('./Translations/is.json'),
    It: () => import('./Translations/it.json'),
    Ja: () => import('./Translations/ja.json'),
    Ka: () => import('./Translations/ka.json'),
    Kk: () => import('./Translations/kk.json'),
    Kn: () => import('./Translations/kn.json'),
    Ko: () => import('./Translations/ko.json'),
    Lt: () => import('./Translations/lt.json'),
    Lv: () => import('./Translations/lv.json'),
    Mk: () => import('./Translations/mk.json'),
    Ml: () => import('./Translations/ml.json'),
    Mn: () => import('./Translations/mn.json'),
    Mr: () => import('./Translations/mr.json'),
    Ms: () => import('./Translations/ms.json'),
    Mt: () => import('./Translations/mt.json'),
    Nb: () => import('./Translations/nb.json'),
    Nl: () => import('./Translations/nl.json'),
    Pa: () => import('./Translations/pa.json'),
    Pl: () => import('./Translations/pl.json'),
    Ps: () => import('./Translations/ps.json'),
    Pt: () => import('./Translations/pt.json'),
    Pt_Pt: () => import('./Translations/pt-PT.json'),
    Ro: () => import('./Translations/ro.json'),
    Ru: () => import('./Translations/ru.json'),
    Si: () => import('./Translations/si.json'),
    Sk: () => import('./Translations/sk.json'),
    Sl: () => import('./Translations/sl.json'),
    So: () => import('./Translations/so.json'),
    Sq: () => import('./Translations/sq.json'),
    Sr: () => import('./Translations/sr.json'),
    Sv: () => import('./Translations/sv.json'),
    Sw: () => import('./Translations/sw.json'),
    Ta: () => import('./Translations/ta.json'),
    Te: () => import('./Translations/te.json'),
    Th: () => import('./Translations/th.json'),
    Tl: () => import('./Translations/fil.json'), // Weird key name, but we want to use ISO 639-1
    Tr: () => import('./Translations/tr.json'),
    Uk: () => import('./Translations/uk.json'),
    Ur: () => import('./Translations/ur.json'),
    Uz: () => import('./Translations/uz.json'),
    Vi: () => import('./Translations/vi.json'),
    Zh: () => import('./Translations/zh-Hans.json'),
    Zh_Tw: () => import('./Translations/zh-Hant-TW.json'),
    Eu: () => import('./Translations/eu.json'),
    Be: () => import('./Translations/be.json'),
    Ceb: () => import('./Translations/ceb.json'),
    Ny: () => import('./Translations/ny.json'),
    Co: () => import('./Translations/co.json'),
    Fy_Nl: () => import('./Translations/fy-NL.json'),
    Gl: () => import('./Translations/gl.json'),
    Haw: () => import('./Translations/haw.json'),
    Hmn: () => import('./Translations/hmn.json'),
    Ig: () => import('./Translations/ig.json'),
    Jv: () => import('./Translations/jv.json'),
    Km: () => import('./Translations/km.json'),
    Rw: () => import('./Translations/rw.json'),
    Ku: () => import('./Translations/ku.json'),
    Ky: () => import('./Translations/ky.json'),
    Lo: () => import('./Translations/lo.json'),
    Lb: () => import('./Translations/lb.json'),
    Mg: () => import('./Translations/mg.json'),
    Mi: () => import('./Translations/mi.json'),
    My_Mm: () => import('./Translations/my-MM.json'),
    Ne: () => import('./Translations/ne.json'),
    Or: () => import('./Translations/or.json'),
    Sm: () => import('./Translations/sm.json'),
    Gd: () => import('./Translations/gd.json'),
    St: () => import('./Translations/st.json'),
    Sn: () => import('./Translations/sn.json'),
    Sd: () => import('./Translations/sd.json'),
    Su: () => import('./Translations/su.json'),
    Tg: () => import('./Translations/tg.json'),
    Tt: () => import('./Translations/tt.json'),
    Tk: () => import('./Translations/tk.json'),
    Ug: () => import('./Translations/ug.json'),
    Xh: () => import('./Translations/xh.json'),
    Yi: () => import('./Translations/yi.json'),
    Yo: () => import('./Translations/yo.json'),
    Zu: () => import('./Translations/zu.json'),
  },
  ant: locales,
}

export default translations

export const getDeviceInfo = async () => {
  const { platform } = await Device.getInfo()
  const { value: language } = await Device.getLanguageCode()

  return {
    platform,
    language: convertBasenameToLanguage(language),
  }
}
