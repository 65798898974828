const MENU_ITEMS_GAP = 24

export const countVisibleElements = (elementsWidth: number[], wrapperWidth: number) => {
  let totalWidth = 0
  let visibleElements = 0

  for (const elementWidth of elementsWidth) {
    if (totalWidth + elementWidth + MENU_ITEMS_GAP > wrapperWidth) {
      break
    }

    totalWidth += elementWidth + MENU_ITEMS_GAP
    visibleElements += 1
  }

  return visibleElements
}
