import { gql } from '@apollo/client'
import { useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import type { Header_reportSource } from '../../../../__generated__/globalTypes'
import { type HeaderVariant, desktopBreakpoint } from '../../../../utils/constants'
import { Header as HeaderComponent } from '../../../Header'
import {
  HeaderComplexWithPages,
  HeaderComplexWithPagesFragments,
  HeaderComplexWithoutPages,
  HeaderComplexWithoutPagesFragments,
  HeaderSimple,
  HeaderSimpleFragments,
} from './components'

export const HeaderFragments = {
  Header_reportSource: gql`
    fragment Header_reportSource on PublicReportSource {
      id
      pages(language: $language) {
        ... on Page {
          id
        }
      }
      ...HeaderComplexWithPages_reportSource
      ...HeaderComplexWithoutPages_reportSource
      ...HeaderSimple_reportSource
    }
    ${HeaderComplexWithPagesFragments.HeaderComplexWithPages_reportSource}
    ${HeaderComplexWithoutPagesFragments.HeaderComplexWithoutPages_reportSource}
    ${HeaderSimpleFragments.HeaderSimple_reportSource}
  `,
}

type HeaderProps = (
  | {
      noMenu?: never
      isMenuOpened: boolean
      setIsMenuOpened: (isOpened: boolean) => void
    }
  | {
      noMenu: true
      isMenuOpened?: never
      setIsMenuOpened?: never
    }
) & {
  reportSource: Header_reportSource
  variant: HeaderVariant
}

export const Header = (props: HeaderProps) => {
  const { isMenuOpened, setIsMenuOpened, reportSource, variant, noMenu } = props
  const { breakpoints } = useMantineTheme()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints[desktopBreakpoint]})`)

  if (variant === 'simple-no-back-button') {
    return (
      <HeaderComponent>
        <HeaderSimple reportSource={reportSource} isHiddenBackButton />
      </HeaderComponent>
    )
  }

  if (variant === 'simple') {
    return (
      <HeaderComponent>
        <HeaderSimple reportSource={reportSource} />
      </HeaderComponent>
    )
  }

  if (reportSource.pages.length > 1 && isDesktop && !noMenu) {
    return (
      <HeaderComponent>
        <HeaderComplexWithPages
          reportSource={reportSource}
          isMenuOpened={isMenuOpened}
          setIsMenuOpened={setIsMenuOpened}
        />
      </HeaderComponent>
    )
  }

  return (
    <HeaderComponent>
      {' '}
      {!noMenu && (
        <HeaderComplexWithoutPages
          reportSource={reportSource}
          isMenuOpened={isMenuOpened}
          setIsMenuOpened={setIsMenuOpened}
        />
      )}
    </HeaderComponent>
  )
}
