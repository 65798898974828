import type { UntitledIconData } from '@faceup/icons'

export const ucEmptyChat: UntitledIconData = {
  prefix: 'custom',
  name: 'emptyChat',
  viewBox: '0 0 108 90',
  paths: [
    {
      shape:
        'M65.554 22.518H42.823a1.956 1.956 0 0 0-1.954 1.953v50.52l-.26.08-5.577 1.707a1.043 1.043 0 0 1-1.301-.691l-16.587-54.18a1.043 1.043 0 0 1 .692-1.302l8.593-2.63 24.911-7.626 8.593-2.631a1.04 1.04 0 0 1 1.301.69l4.24 13.85.08.26Z',
      fill: '#F2F2F2',
    },
    {
      shape:
        'm70.514 22.257-5.11-16.69a2.213 2.213 0 0 0-2.766-1.47l-12.08 3.698-24.911 7.627-12.081 3.699a2.216 2.216 0 0 0-1.47 2.765L29.561 78.93a2.217 2.217 0 0 0 2.766 1.47l8.281-2.535.26-.081v-.272l-.26.08-8.358 2.559a1.956 1.956 0 0 1-2.44-1.296L12.347 21.809a1.952 1.952 0 0 1 1.296-2.44l12.08-3.699 24.911-7.625 12.081-3.7a1.956 1.956 0 0 1 2.44 1.296l5.086 16.616.081.26h.271l-.08-.26Z',
      fill: '#3F3D56',
    },
    {
      shape:
        'M27.979 20.648a1.174 1.174 0 0 1-1.122-.829l-1.678-5.48a1.172 1.172 0 0 1 .778-1.464l22.917-7.016a1.174 1.174 0 0 1 1.464.777l1.678 5.48a1.173 1.173 0 0 1-.778 1.465l-22.917 7.016c-.11.034-.226.051-.342.051Z',
      fill: 'currentColor',
    },
    {
      shape: 'M36.768 9.855a2.605 2.605 0 1 0 0-5.21 2.605 2.605 0 0 0 0 5.21Z',
      fill: 'currentColor',
    },
    { shape: 'M36.768 8.899a1.65 1.65 0 1 0 0-3.3 1.65 1.65 0 0 0 0 3.3Z', fill: '#fff' },
    {
      shape:
        'M90.496 79.83H46.47a1.108 1.108 0 0 1-1.108-1.107V25.97a1.108 1.108 0 0 1 1.107-1.107h44.027a1.108 1.108 0 0 1 1.107 1.108v52.753a1.108 1.108 0 0 1-1.107 1.107Z',
      fill: '#E6E6E6',
    },
    {
      shape:
        'M70.242 22.258h-27.42a2.217 2.217 0 0 0-2.214 2.214v53.119l.26-.08V24.472a1.956 1.956 0 0 1 1.955-1.954h27.5l-.081-.26Zm23.902 0H42.823a2.217 2.217 0 0 0-2.215 2.214V84.13a2.217 2.217 0 0 0 2.215 2.215h51.32a2.217 2.217 0 0 0 2.215-2.215V24.472a2.217 2.217 0 0 0-2.214-2.214Zm1.953 61.871a1.957 1.957 0 0 1-1.953 1.954H42.823a1.957 1.957 0 0 1-1.954-1.954V24.472a1.956 1.956 0 0 1 1.954-1.954h51.32a1.957 1.957 0 0 1 1.954 1.954V84.13Z',
      fill: '#3F3D56',
    },
    {
      shape:
        'M80.467 27.989H56.499a1.174 1.174 0 0 1-1.172-1.172v-5.732a1.174 1.174 0 0 1 1.172-1.172h23.968a1.174 1.174 0 0 1 1.172 1.172v5.732a1.174 1.174 0 0 1-1.172 1.172Z',
      fill: 'currentColor',
    },
    {
      shape: 'M68.483 20.304a2.605 2.605 0 1 0 0-5.21 2.605 2.605 0 0 0 0 5.21Z',
      fill: 'currentColor',
    },
    { shape: 'M68.483 19.285a1.587 1.587 0 1 0 0-3.174 1.587 1.587 0 0 0 0 3.174Z', fill: '#fff' },
  ],
}
