import { gql } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulInfoCircle } from '@faceup/icons/ulInfoCircle'
import { List, useMantineTheme } from '@mantine/core'
import type { ReportSentGeneral_publicReportSource } from '../../../../__generated__/globalTypes'
import { useReportSentData, useReportSentDataFragments } from '../../hooks'
import { ReportSentContent } from '../ReportSentContent'
import { PinForm, PinFormFragments } from './components'

export const ReportSentGeneralFragments = {
  ReportSentGeneral_publicReportSource: gql`
    fragment ReportSentGeneral_publicReportSource on PublicReportSource {
      id

      ...useReportSentData_publicReportSource
      ...PinForm_publicReportSource
    }

    ${useReportSentDataFragments.useReportSentData_publicReportSource}
    ${PinFormFragments.PinForm_publicReportSource}
  `,
}

type ReportSentGeneralProps = {
  reportSource: ReportSentGeneral_publicReportSource
}

export const ReportSentGeneral = (props: ReportSentGeneralProps) => {
  const { reportSource } = props
  const theme = useMantineTheme()
  const { reportSentDescription } = useReportSentData(reportSource)

  return (
    <ReportSentContent>
      <PinForm reportSource={reportSource} />
      <List
        size='sm'
        icon={<UntitledIcon icon={ulInfoCircle} color={theme.colors.prussianBlue[6]} />}
      >
        <List.Item>{reportSentDescription}</List.Item>
      </List>
    </ReportSentContent>
  )
}
