import { type ReactNode, useCallback, useState } from 'react'
import { type CustomizationColor, CustomizationContext } from './CustomizationContext'
import { DEFAULT_COLORS } from './defaultStyles'

type Props = {
  children: ReactNode
}

// https://stackoverflow.com/a/56266358
const isValidColor = (strColor: string) => {
  const s = new Option().style
  s.color = strColor
  return s.color !== ''
}

const CustomizationProvider = ({ children }: Props) => {
  const [colors, setColors] = useState(DEFAULT_COLORS)
  const [isBrandingHidden, changeIsBrandingHidden] = useState(false)

  const changeColors = useCallback(({ primaryColor, darkColor }: CustomizationColor) => {
    setColors({
      primaryColor:
        primaryColor && isValidColor(primaryColor) ? primaryColor : DEFAULT_COLORS.primaryColor,
      darkColor: darkColor && isValidColor(darkColor) ? darkColor : undefined,
    })
  }, [])

  return (
    <CustomizationContext.Provider
      value={{
        colors,
        changeColors,
        isBrandingHidden,
        changeIsBrandingHidden,
      }}
    >
      {children}
    </CustomizationContext.Provider>
  )
}

export default CustomizationProvider
