import { Language } from '@faceup/utils'
import { type ReactNode, useCallback, useEffect, useState } from 'react'
import { getDeviceInfo } from '../locales'
import type { Clients } from '../utils/useCustomizationByURLParams'
import { LayoutContext, Theme } from './LayoutContext'

type Props = {
  children: ReactNode
}

const LayoutProvider = ({ children }: Props) => {
  const [client, setClient] = useState<Clients | null>(isPurePWA() ? 'app' : null)
  const [theme, setTheme] = useState(isNNTBDomain() ? Theme.NNTB : Theme.FaceUp)

  const changeThemeByDeviceInfo = useCallback(async () => {
    const { platform, language } = await getDeviceInfo()

    if (platform !== 'web') {
      if ([Language.Cs, Language.Sk].includes(language)) {
        setTheme(Theme.NNTB)
      } else {
        setTheme(Theme.FaceUp)
      }
    }
  }, [])

  useEffect(() => {
    changeThemeByDeviceInfo()
  }, [changeThemeByDeviceInfo])

  return (
    <LayoutContext.Provider
      value={{
        theme,
        client,
        setClient,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

const isNNTBDomain = () => {
  const domain = window.location.host
  return domain.endsWith('nntb.cz') || domain.endsWith('nntb.sk')
}

// https://stackoverflow.com/a/52695341/6544122
export const isPurePWA = () =>
  Boolean(
    window.matchMedia('(display-mode: standalone)').matches ||
      // @ts-expect-error Standalone
      window.navigator['standalone'] ||
      document.referrer.includes('android-app://') ||
      document.referrer.includes('capacitor://') ||
      window.navigator.userAgent.includes('FaceUpApp')
  )

export default LayoutProvider
