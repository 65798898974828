import { gql, useMutation, useQuery } from '@apollo/client'
import {
  deserializePin,
  getCurrentEncryptionVersion,
  parsePinToParts,
  prehashPassword,
  processVersionMigration,
  savePersonalKeys,
} from '@faceup/crypto'
import { Link, useNavigate } from '@faceup/router'
import { ArrowGoBack, Button, Form } from '@faceup/ui'
import { Col, Icon, Input, Row, useModal } from '@faceup/ui-base'
import { Box, Center } from '@mantine/core'
import { useContext, useState } from 'react'
import PageTitle from '../../Components/PageTitle'
import { LayoutContext } from '../../Contexts/LayoutContext'
import { UserContext } from '../../Contexts/UserContext'
import CloseIcon from '../../Shared/assets/close-icon.svg?react'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import type {
  MigrateVictimEncryptionMutation,
  MigrateVictimEncryptionMutationVariables,
  SystemInfoCheckReportQuery,
  VictimLoginMutation,
  VictimLoginMutationVariables,
} from '../../__generated__/globalTypes'
import { usePreLogin } from '../../hooks/usePreLogin'
import { useSavedReportsContext } from '../../hooks/useSavedReportsContext'
import { useMedia } from '../../mq'
import useAuth from '../../utils/auth'
import { sizeSettings } from '../../utils/constants'

const messages = defineMessages({
  title: 'FollowUp.CheckReport.title',
  description: 'FollowUp.CheckReport.description',
  pinLabel: 'FollowUp.CheckReport.pinLabel',
  pinError: 'FollowUp.CheckReport.pinError',
  pinHint: 'FollowUp.CheckReport.pinHint',
  forgottenPin: 'FollowUp.CheckReport.forgottenPin',
  forgottenPinTitle: 'FollowUp.CheckReport.forgottenPin.title',
  forgottenPinDescription: 'FollowUp.CheckReport.forgottenPin.description',
})

const mutation = {
  VictimLogin: gql`
    mutation VictimLoginMutation($input: VictimLoginInput!) {
      victimLogin(input: $input) {
        token
        publicKey
        privateKey
        nonce
      }
    }
  `,
  MigrateVictimEncryption: gql`
    mutation MigrateVictimEncryptionMutation($input: MigrateVictimEncryptionInput!) {
      migrateVictimEncryption(input: $input) {
        isValid
      }
    }
  `,
}

const query = {
  systemInfo: gql`
    query SystemInfoCheckReportQuery {
      systemInfo {
        id
        publicKey
      }
    }
  `,
}

const CheckReport = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { setJwt } = useAuth()
  const { hasSomeSavedReports } = useSavedReportsContext()
  const { client } = useContext(LayoutContext)
  const { performPreLogin, loading: loadingPreLogin } = usePreLogin()
  const [pinInput, setPinInput] = useState({
    value: '',
    error: false,
  })
  const { reportSource } = useContext(UserContext)
  const isLgDown = useMedia('lgDown')
  const modal = useModal()

  const systemInfo = useQuery<SystemInfoCheckReportQuery>(query.systemInfo)?.data?.systemInfo

  const [loginReport, { loading: loadingReport }] = useMutation<
    VictimLoginMutation,
    VictimLoginMutationVariables
  >(mutation.VictimLogin, {
    onError: ({ message }) => {
      if (message === 'Invalid login credentials') {
        setPinInput({ ...pinInput, error: true })
      }
    },
  })

  const [migrateVictimEncryption, { loading: loadingMigration }] = useMutation<
    MigrateVictimEncryptionMutation,
    MigrateVictimEncryptionMutationVariables
  >(mutation.MigrateVictimEncryption)

  const loading = loadingReport || loadingPreLogin || loadingMigration

  const resetForm = () => {
    setPinInput({ value: '', error: false })
  }

  const isApp = client === 'app'

  return (
    <>
      <Row justify='center'>
        <Col {...sizeSettings}>
          {isApp && hasSomeSavedReports && (
            <Box sx={{ position: 'absolute' }}>
              <Link to={routes => routes.checkReport()}>
                <ArrowGoBack />
              </Link>
            </Box>
          )}
          <PageTitle description={<FormattedMessage {...messages.description} />}>
            <FormattedMessage {...messages.title} />
          </PageTitle>
        </Col>
      </Row>
      <Row justify='center'>
        <Col {...sizeSettings}>
          <Form
            onSubmit={async () => {
              if (pinInput.value.trim().length < 8) {
                setPinInput({ ...pinInput, error: true })
                return
              }

              const { passwordKey, passwordKeyPrehash } = await performPreLogin(pinInput.value)
              const pin = deserializePin(pinInput.value)
              const { identity, version, password } = parsePinToParts(pin)

              const loginResponse = await loginReport({
                variables: {
                  input: {
                    identity,
                    passwordPrehash: passwordKeyPrehash,
                    version,
                    reportPassword: reportSource?.password,
                    rememberMe: false,
                    organizationalUnitId: reportSource?.institutionId ?? '',
                  },
                },
              })

              if (!loginResponse.data) {
                setPinInput({ ...pinInput, error: true })
                return
              }

              await setJwt(loginResponse?.data?.victimLogin?.token ?? '')

              const login = loginResponse?.data?.victimLogin
              if (passwordKey && version === getCurrentEncryptionVersion()) {
                await savePersonalKeys({
                  publicKey: login?.publicKey ?? '',
                  privateKey: login?.privateKey ?? '',
                  nonce: login?.nonce ?? '',
                  passwordKey,
                  rememberMe: isApp,
                  version,
                })
              } else {
                const result = await processVersionMigration({
                  password,
                  systemPublicKey: systemInfo?.publicKey ?? '',
                  privateKey: login?.privateKey ?? '',
                  publicKey: login?.publicKey ?? '',
                  version,
                })

                if (typeof result === 'string') {
                  return
                }

                const {
                  newNonce,
                  newPasswordPrehash,
                  newPrivateKeyEncrypted,
                  newSalt,
                  currentPasswordPrehash,
                } = result

                await migrateVictimEncryption({
                  variables: {
                    input: {
                      newNonce,
                      newPasswordPrehash,
                      newPrivateKeyEncrypted,
                      newSalt,
                      currentPasswordPrehash,
                    },
                  },
                })

                const { passwordKey: newPasswordKey } = await prehashPassword({
                  password,
                  salt: newSalt,
                })

                await savePersonalKeys({
                  publicKey: login?.publicKey ?? '',
                  privateKey: newPrivateKeyEncrypted,
                  nonce: newNonce,
                  passwordKey: newPasswordKey,
                  rememberMe: isApp,
                  version: getCurrentEncryptionVersion(),
                })
              }

              resetForm()
              navigate(routes => routes.report())
            }}
          >
            <Form.Item
              withAsterisk
              label={<FormattedMessage {...messages.pinLabel} />}
              hint={formatMessage(messages.pinHint)}
              {...(pinInput.error && {
                errorMessage: <FormattedMessage {...messages.pinError} />,
              })}
            >
              <Input
                autoComplete='off'
                data-cy='check-report-input'
                size='large'
                value={pinInput.value}
                maxLength={50}
                onChange={({ target: { value } }) => {
                  setPinInput({
                    value:
                      // do not format input, while deleting spaces (Backspace)
                      pinInput.value.endsWith(' ') && pinInput.value.trim() === value
                        ? value
                        : value
                            .split(' ')
                            .join('')
                            .replace(/(.{4})/g, '$1 '),
                    error: false,
                  })
                }}
              />
            </Form.Item>
            <Form.Item>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  data-cy='check-report-button'
                  isFullWidth={isLgDown}
                  type='submit'
                  loading={loading}
                >
                  <FormattedMessage {...sharedMessages.continue} />
                </Button>
              </div>
            </Form.Item>
          </Form>
          <Center>
            <Button
              variant='text'
              onClick={() =>
                modal.info({
                  // we have to use `formatMessage`, because of context
                  icon: false,
                  closable: true,
                  maskClosable: true,
                  centered: true,
                  okButtonProps: { style: { display: 'none' } },
                  closeIcon: <Icon component={CloseIcon} />,
                  title: formatMessage(messages.forgottenPinTitle),
                  content: formatMessage(messages.forgottenPinDescription, {
                    i: text => <i>{text}</i>,
                  }),
                })
              }
            >
              <FormattedMessage {...messages.forgottenPin} />
            </Button>
          </Center>
        </Col>
      </Row>
    </>
  )
}

export default CheckReport
