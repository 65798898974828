import { gql } from '@apollo/client'
import { Navbar as MantineNavbar, MediaQuery } from '@mantine/core'
import type { InstitutionLayoutNavbar_reportSource } from '../../../../__generated__/globalTypes'
import { type LayoutVariant, desktopBreakpoint, panelWidth } from '../../../../utils/constants'
import { InstitutionLayoutNavbarContentFragments, NavbarContent } from './NavbarContent'

export const InstitutionLayoutNavbarFragments = {
  InstitutionLayoutNavbar_reportSource: gql`
    fragment InstitutionLayoutNavbar_reportSource on PublicReportSource {
      id

      ...InstitutionLayoutNavbarContent_reportSource
    }
    ${InstitutionLayoutNavbarContentFragments.InstitutionLayoutNavbarContent_reportSource}
  `,
}

type NavbarProps = {
  reportSource: InstitutionLayoutNavbar_reportSource
  isOpened: boolean
  variant: LayoutVariant
}

export const Navbar = ({ reportSource, isOpened, variant }: NavbarProps) => (
  <MantineNavbar
    width={panelWidth[variant]}
    hiddenBreakpoint={desktopBreakpoint}
    hidden={!isOpened}
    withBorder={false}
    p='xs'
  >
    <MediaQuery
      largerThan={desktopBreakpoint}
      styles={{
        display: 'none',
      }}
    >
      <MantineNavbar.Section grow my='xs' px='0'>
        <NavbarContent reportSource={reportSource} />
      </MantineNavbar.Section>
    </MediaQuery>
  </MantineNavbar>
)
