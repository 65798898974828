import { ChannelPageType, Institution, Plan } from '@faceup/utils'
import { useContext } from 'react'
import { UiContext } from '../UiProvider/UiProvider'

export type PagesTranslationVariants =
  | 'freeSchool'
  | 'testingSchool'
  | 'unregisteredSchool'
  | 'school'
  | 'company'
  | 'surveyBeforeSend'
  | 'formItems'
  | 'surveyAfterSend'

export type PagesTranslationItems = 'title' | 'content' | 'navigationTitle'

type PageTemplate<D extends object = object> = D &
  Partial<Record<'title' | 'content', string | null>> &
  ({ __typename: 'Page'; type?: never } | { __typename: 'ChannelPage'; type: ChannelPageType })

export type PageTranslations<D extends object = object> = D &
  Record<PagesTranslationItems, string | undefined>
const usePageTemplate = (
  forceTranslations?: Record<PagesTranslationVariants, Record<PagesTranslationItems, string>>
) => {
  const { pages } = useContext(UiContext)
  const getDefaultTranslations = (): PageTranslations => {
    if (!pages) {
      return {
        title: '',
        navigationTitle: '',
        content: '',
      }
    }

    const { mother, isDefaultForm } = pages
    const translations = forceTranslations ?? pages.translations

    const { type, isTesting, plan } = mother

    if (type === Institution.School && isDefaultForm) {
      if (isTesting) {
        return {
          title: translations.testingSchool.title,
          navigationTitle: translations.testingSchool.title,
          content: translations.testingSchool.content,
        }
      }

      if (plan === Plan.Unregistered) {
        return {
          title: translations.unregisteredSchool.title,
          navigationTitle: translations.unregisteredSchool.title,
          content: translations.unregisteredSchool.content,
        }
      }

      if (plan === Plan.Free) {
        return {
          title: translations.freeSchool.title,
          navigationTitle: translations.freeSchool.title,
          content: translations.freeSchool.content,
        }
      }

      return {
        title: translations.school.title,
        navigationTitle: translations.school.title,
        content: translations.school.content,
      }
    }

    return {
      title: translations.company.title,
      navigationTitle: translations.company.title,
      content: translations.company.content,
    }
  }

  const getDefaultSurveyPageTranslations = <D extends object>(
    page: PageTemplate<D>
  ): PageTranslations<D> => {
    if (page.__typename !== 'ChannelPage') {
      throw Error("Can't get survey translations for non ChannelPage")
    }
    if (!pages) {
      return {
        ...page,
        title: '',
        content: '',
        navigationTitle: '',
      }
    }
    const translations = forceTranslations ?? pages.translations
    const returnObjects: Record<ChannelPageType, () => PageTranslations<D>> = {
      [ChannelPageType.BeforeSend]: () => ({
        ...page,
        title: translations?.surveyBeforeSend.title,
        content: translations?.surveyBeforeSend.content,
        navigationTitle: translations?.surveyBeforeSend.navigationTitle,
      }),
      [ChannelPageType.FormItems]: () => ({
        ...page,
        title: translations?.formItems.title,
        content: translations?.formItems.content,
        navigationTitle: translations?.formItems.navigationTitle,
      }),
      [ChannelPageType.AfterSend]: () => ({
        ...page,
        title: translations?.surveyAfterSend.title,
        content: translations?.surveyAfterSend.content,
        navigationTitle: translations?.surveyAfterSend.navigationTitle,
      }),
    }

    return returnObjects[page.type]()
  }

  return {
    fillObject: <D extends object>(page: PageTemplate<D>): PageTranslations<D> => {
      if (page.__typename === 'ChannelPage') {
        const defaultSurveyTranslations = getDefaultSurveyPageTranslations(page)
        return {
          ...page,
          // if undefined, probably not returned from gql, so left as is
          title:
            page.title === undefined ? undefined : page.title ?? defaultSurveyTranslations?.title,
          navigationTitle: defaultSurveyTranslations.navigationTitle,
          content:
            page.content === undefined
              ? undefined
              : page.content ?? defaultSurveyTranslations?.content,
        }
      }
      const defaultValues = getDefaultTranslations()
      return {
        ...page,
        // if undefined, probably not returned from gql, so left as is
        title: page.title === undefined ? undefined : page.title ?? defaultValues.title,
        navigationTitle: undefined,
        content: page.content === undefined ? undefined : page.content ?? defaultValues.content,
      }
    },
    isTitleDefault: (title: string) => {
      const { title: defaultTitle } = getDefaultTranslations()
      return title === defaultTitle
    },
    isContentDefault: (content: string) => {
      const { content: defaultContent } = getDefaultTranslations()
      return content === defaultContent
    },
    setIsDefaultForm: pages?.setIsDefaultForm ?? (() => undefined),
  }
}

export default usePageTemplate
