import { Button, ButtonGroup, Paper, Prompt, type Sx, Text, useThemeColors } from '@faceup/ui'
import { Affix, Box, Group, Transition } from '@mantine/core'
import { useId } from '@mantine/hooks'
import type { ReactNode } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useFormLocalization } from '../FormLocalizationProvider'
import { FormItemsWrapper } from './FormItemsWrapper'
import { type ReactHookFormProps, useFormLogic } from './useFormLogic'

export type FormProps<FV extends FieldValues = FieldValues> = {
  children: ReactNode
  buttonsPosition?: 'affix' | 'under' | 'under-center'
  isUnsavedAlertDisabled?: boolean
} & ReactHookFormProps<FV>

export const Form = <FV extends FieldValues = FieldValues>(props: FormProps<FV>) => {
  const { children, buttonsPosition = 'affix', isUnsavedAlertDisabled } = props
  const {
    modalForm,
    unsavedChanges,
    leaveUnsavedChanges,
    submitButton: { save },
  } = useFormLocalization()
  const {
    submitButtonText,
    onSubmit,
    isLoading,
    additionalButtons,
    isSubmitButtonDisabled,
    dirtyFieldsCount,
    reset,
  } = useFormLogic(props)
  const formId = useId()

  // We need to allow redirect if onSubmit succeeds, IDK if this can be written in a better way
  const showUnsavedAlert = !isSubmitButtonDisabled && !isLoading
  const { getColorFromTheme } = useThemeColors()

  return (
    <Box
      id={formId}
      component='form'
      onSubmit={onSubmit}
      sx={{
        width: '100%',
      }}
    >
      <Prompt when={showUnsavedAlert && !isUnsavedAlertDisabled} message={leaveUnsavedChanges} />
      {buttonsPosition === 'affix' ? (
        <>
          {' '}
          <FormItemsWrapper>{children}</FormItemsWrapper>
          <Affix position={{ bottom: '3rem', right: '1rem' }}>
            <Transition transition='slide-up' mounted={!isSubmitButtonDisabled}>
              {transitionStyles => (
                <Paper
                  radius='8px'
                  sx={({ getColorFromTheme }) => ({
                    padding: '0.5rem 0.5rem 0.5rem 1rem',
                    backgroundColor: getColorFromTheme('dark.100'),
                    ...(transitionStyles as Sx),
                  })}
                >
                  <Group spacing='1.5rem'>
                    <Text color='white' weight='bold'>
                      {unsavedChanges(dirtyFieldsCount)}
                    </Text>
                    <ButtonGroup
                      hasSeparateButtons
                      sx={{
                        justifyContent: 'flex-end',
                      }}
                    >
                      {additionalButtons}
                      <Button
                        variant='tertiary'
                        onClick={() => reset()}
                        sx={{
                          background: 'transparent',
                          '.mantine-Button-label': {
                            color: getColorFromTheme('white'),
                          },
                        }}
                      >
                        {modalForm.cancelButton}
                      </Button>
                      <Button
                        type='submit'
                        form={formId}
                        loading={isLoading}
                        disabled={isSubmitButtonDisabled}
                        data-cy='form-submit-button'
                      >
                        {save}
                      </Button>
                    </ButtonGroup>
                  </Group>
                </Paper>
              )}
            </Transition>
          </Affix>
        </>
      ) : (
        <FormItemsWrapper>
          {children}
          <ButtonGroup
            hasSeparateButtons
            sx={{
              justifyContent: buttonsPosition === 'under-center' ? 'center' : 'flex-end',
            }}
          >
            {additionalButtons}
            <Button
              type='submit'
              loading={isLoading}
              disabled={isSubmitButtonDisabled}
              data-cy='form-submit-button'
            >
              {submitButtonText}
            </Button>
          </ButtonGroup>
        </FormItemsWrapper>
      )}
    </Box>
  )
}

// Form.Item = FormItem
