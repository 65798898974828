import { UntitledIcon } from '@faceup/icons'
import { ulLinkExternal01 } from '@faceup/icons/ulLinkExternal01'
import { Text, Title } from '@mantine/core'
import type { TitleOrder } from '@mantine/core/lib/Title/Title'
import DOMPurify from 'dompurify'
import parse, { type DOMNode, type Element, attributesToProps, domToReact } from 'html-react-parser'
import { useLinkExternalConfirm } from '../hooks/useLinkExternalConfirm'

type HtmlRendererProps = { html: string }

const isTag = (node: DOMNode): node is Element => node.type === 'tag'

/**
 * Renders HTML with some sanitization and a hook to make all links open a new window
 * TL;DR:
 *  - Sanitize the HTML with DOMPurify and add a hook to make all links open a new window
 *  - Parse the HTML with html-react-parser and add confirm alert for all links
 *  - Render the HTML
 */
export const HtmlRenderer = ({ html }: HtmlRendererProps) => {
  const linkExternalConfirm = useLinkExternalConfirm()
  // https://github.com/cure53/DOMPurify/blob/main/demos/hooks-target-blank-demo.html
  // Add a hook to make all links open a new window
  DOMPurify.addHook('afterSanitizeAttributes', node => {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank')
      node.setAttribute('rel', 'noopener noreferrer')
    }
  })
  const cleanedHtml = DOMPurify.sanitize(html)
  const options = {
    replace: (domNode: DOMNode) => {
      if (isTag(domNode) && domNode.name === 'a') {
        const props = attributesToProps(domNode.attribs)
        return (
          <a
            {...props}
            onClick={e => {
              e.preventDefault()
              if (typeof props['href'] === 'string') {
                linkExternalConfirm(props['href'])
              }
            }}
          >
            {domToReact((domNode as Element).children as DOMNode[], options)}{' '}
            <UntitledIcon icon={ulLinkExternal01} />
          </a>
        )
      }
      const headingSizes: TitleOrder[] = [1, 2, 3, 4, 5, 6]
      const headingSize = headingSizes.find(size => isTag(domNode) && domNode.name === `h${size}`)
      if (isTag(domNode) && headingSize !== undefined) {
        const props = attributesToProps(domNode.attribs)
        return (
          <Title {...props} order={headingSize} pb='sm'>
            {domToReact((domNode as Element).children as DOMNode[], options)}
          </Title>
        )
      }
      if (isTag(domNode) && domNode.name === 'p') {
        const props = attributesToProps(domNode.attribs)
        return (
          <Text
            {...props}
            component='p'
            sx={{
              marginBottom: 0,
              paddingBottom: '1em',
            }}
          >
            {domToReact((domNode as Element).children as DOMNode[], options)}
          </Text>
        )
      }
      return domNode
    },
  }
  const parsedCleanedHtml = parse(cleanedHtml, options)

  return <>{parsedCleanedHtml}</>
}
