import { Box, Popover, Stack, createPolymorphicComponent } from '@mantine/core'
import { type ReactNode, useContext, useState } from 'react'
import { Form } from '../Form'
import { UiContext } from '../UiProvider/UiProvider'
import { Button, type ButtonProps } from './Button'

type ButtonConfirmProps = {
  confirmContent: ReactNode
  onConfirm?: () => void
  confirmWidth?: number
  skipConfirm?: boolean
} & ButtonProps

export const ButtonConfirm = createPolymorphicComponent<'button', ButtonConfirmProps>(
  ({
    confirmContent,
    skipConfirm,
    confirmWidth = 400,
    onConfirm,
    ...props
  }: ButtonConfirmProps) => {
    const [opened, setOpened] = useState<boolean>(false)
    const { buttonConfirm } = useContext(UiContext)

    if (skipConfirm) {
      return <Button {...props} />
    }

    return (
      <Popover width={confirmWidth} opened={opened} onChange={setOpened} withArrow>
        <Popover.Target>
          <Button {...props} onClick={() => setOpened(true)} />
        </Popover.Target>
        <Popover.Dropdown>
          <Stack>
            <Box>{confirmContent}</Box>
            <Form.ButtonGroup>
              <Button size='small' variant='secondary' onClick={() => setOpened(false)}>
                {buttonConfirm?.cancel ?? 'Cancel'}
              </Button>
              <Button
                size='small'
                onClick={() => {
                  setOpened(false)
                  onConfirm?.()
                }}
              >
                {buttonConfirm?.confirm ?? 'Confirm'}
              </Button>
            </Form.ButtonGroup>
          </Stack>
        </Popover.Dropdown>
      </Popover>
    )
  }
)
