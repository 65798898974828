export const copyTextForOldBrowsers = (text: string) => {
  const element = document.createElement('textarea')
  document.body.appendChild(element)
  element.value = text
  element.textContent = text
  const selection = getSelection()
  const range = document.createRange()
  range.selectNode(element)
  selection?.removeAllRanges()
  selection?.addRange(range)
  // Deprecated, but supported in IE and some older browsers
  const isCopied = document.execCommand('copy')
  document.body.removeChild(element)

  return isCopied
}
