import type { Locale } from '@faceup/ui-base'
import { DEFAULT_LANGUAGE } from '@faceup/utils'
import { createContext } from 'react'
import type { Language } from '../__generated__/globalTypes'

export type TextDirection = 'ltr' | 'rtl'

type State = {
  language: Language
  previousLanguage?: Language
  changeLanguage: (language: Language) => void
  changePreviousLanguage: (language: Language) => void
  messages: Record<string, string> | undefined
  basename: string
  antLocale: Locale | undefined
  direction: TextDirection
}

export const LanguageContext = createContext<State>({
  language: DEFAULT_LANGUAGE,
  previousLanguage: undefined,
  changeLanguage: () => undefined,
  changePreviousLanguage: () => undefined,
  messages: undefined,
  basename: '/',
  antLocale: undefined,
  direction: 'ltr',
})
