import { changePassword, createRecoveryKey, createSystemRecoveryKey } from '@faceup/crypto'

export const processVersionMigration = (payload: MigrationV1toV2Payload & { version: number }) => {
  switch (payload.version) {
    case 1:
      return processUserMigrationFromV1toV2(payload)
    default:
      return Promise.resolve('Wrong encryption version')
  }
}

type MigrationV1toV2Payload = {
  password: string
  publicKey: string
  privateKey: string
  systemPublicKey: string
}

const processUserMigrationFromV1toV2 = async ({
  password,
  privateKey,
  publicKey,
  systemPublicKey,
}: MigrationV1toV2Payload) => {
  const userPayload = await changePassword(password, {
    publicKey,
    privateKey,
  })

  if (typeof userPayload === 'string') {
    throw new Error(userPayload)
  }

  const {
    privateKeyEncrypted: newPrivateKeyEncrypted,
    salt: newSalt,
    password: newPasswordPrehash,
    nonce: newNonce,
    passwordKey,
  } = userPayload

  const recoveryPayload = await createRecoveryKey(newPrivateKeyEncrypted, newNonce, passwordKey)

  if (typeof recoveryPayload === 'string') {
    throw new Error(recoveryPayload)
  }

  const systemRecoveryPayload = await createSystemRecoveryKey(
    recoveryPayload.recoveryKeyPlain,
    systemPublicKey
  )

  if (typeof systemRecoveryPayload === 'string') {
    throw new Error(systemRecoveryPayload)
  }

  return {
    newNonce,
    newSalt,
    newPasswordPrehash,
    newPrivateKeyEncrypted,
    currentPasswordPrehash: password,
    ...recoveryPayload,
    ...systemRecoveryPayload,
  }
}
