import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { Layout } from './components'

const messages = defineMessages({
  content: 'FollowUp.senderPolicies.content.school',
  title: 'FollowUp.senderPolicies.title',
})

const PersonalDataProcessingInfoSchoolView = () => {
  const { formatMessage } = useIntl()

  return (
    <Layout
      title={<FormattedMessage {...messages.title} />}
      content={formatMessage(messages.content)}
    />
  )
}

export default PersonalDataProcessingInfoSchoolView
