import { type Attachment, AttachmentsDropzone, Form } from '@faceup/ui'
import { Alert } from '@faceup/ui-base'
import { DEFAULT_LANGUAGE, Plan } from '@faceup/utils'
import { useContext, useMemo } from 'react'
import { LanguageContext } from '../../Contexts/LanguageContext'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import type { CreateReportForm_institution } from '../../__generated__/globalTypes'
import useConfigForProject from '../../hooks/useConfigForProject'

const messages = defineMessages({
  attachmentsLabel: 'Shared.report.attachments',
  dropzoneDrop: 'FollowUp.report.dropzone.drop',
  dropzoneOpen: 'FollowUp.report.dropzone.open',
  dropzoneLimit: 'FollowUp.report.dropzone.limit',
  addAttachment: 'Shared.report.attachments.add',
  alertMessage: 'FollowUp.report.attachments.alert.message',
  alertLink: 'FollowUp.report.attachments.alert.link',
})
type AttachmentsDropzoneFormItemProps = {
  onSetAttachments: (attachments: Attachment[]) => void
  institution?: CreateReportForm_institution | null
  attachments: Attachment[]
}

export const AttachmentsDropzoneFormItem = ({
  attachments,
  onSetAttachments,
  institution,
}: AttachmentsDropzoneFormItemProps) => {
  const { formatMessage } = useIntl()

  const { getSupportUrlByLanguage } = useConfigForProject()

  const { language } = useContext(LanguageContext)

  // TODO: https://gitlab.com/faceup-nntb/monorepo/-/merge_requests/694#note_1132857869
  // create  and use useTier hook instead of this
  const canUseDropzone = useMemo(
    () =>
      Boolean(institution?.billing?.plan) &&
      [Plan.Standard, Plan.Premium].includes(institution?.billing?.plan ?? Plan.Basic),
    [institution?.billing?.plan]
  )

  if (!canUseDropzone) {
    return null
  }

  return (
    <Form.Item
      label={<FormattedMessage {...messages.attachmentsLabel} />}
      data-cy='create-company-report-form-attachment-dropzone'
    >
      <AttachmentsDropzone
        attachments={attachments}
        setAttachments={files => {
          onSetAttachments(files)
        }}
        translations={{
          tooManyFiles: formatMessage(sharedMessages.attachmentsTooManyFiles),
          tooLargeFile: formatMessage(sharedMessages.attachmentsTooLargeFile),
          metadataRemoved: formatMessage(sharedMessages.metadataRemoved),
          metadataUnableToRemove: formatMessage(sharedMessages.metadataUnableToRemove),
          unsupportedMimetype: formatMessage(sharedMessages.unsupportedMimetype),
          delete: formatMessage(sharedMessages.delete),
          dropzoneDrop: formatMessage(messages.dropzoneDrop),
          dropzoneLimits: formatMessage(messages.dropzoneLimit),
          dropzoneOpen: formatMessage(messages.dropzoneOpen),
          add: formatMessage(messages.addAttachment),
        }}
      />
      {attachments.length > 0 && (
        <Alert
          style={{ marginTop: '1rem' }}
          type='info'
          showIcon
          closable
          message={
            <div style={{ padding: 5 }}>
              <FormattedMessage {...messages.alertMessage} />{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href={getSupportUrlByLanguage(language ?? DEFAULT_LANGUAGE)}
                target='_blank'
                rel='noopener noreferrer'
              >
                <FormattedMessage {...messages.alertLink} />
              </a>
            </div>
          }
        />
      )}
    </Form.Item>
  )
}
