import styled from '@emotion/styled'
import { Icon, Modal, Typography } from '@faceup/ui-base'
import { useContext, useLayoutEffect, useRef, useState } from 'react'
import HeaderCloseIcon from '../../Components/CloseIcon'
import { Header } from '../../Components/Header'
import { RecordingStatus, ReportFormContext } from '../../Contexts/ReportFormContext'
import CloseIcon from '../../Shared/assets/close-icon.svg?react'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { useMedia } from '../../mq'
import VoiceRecordingBody from './VoiceRecordingBody'

const { Title } = Typography
const messages = defineMessages({
  title: 'FollowUp.voiceRecording.title',
})

type Props = {
  visible: boolean
  close: () => void
}

const VoiceRecordingModal = ({ visible, close }: Props) => {
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
  const { recordingStatus, setRecordingStatus } = useContext(ReportFormContext)
  const isMdDown = useMedia('mdDown')
  const audioRef = useRef<HTMLAudioElement>(null)

  const showCloseIcon = recordingStatus !== RecordingStatus.Recording

  useLayoutEffect(() => {
    document.body.style.overflow = isMdDown && visible ? 'hidden' : 'auto'
  }, [visible, isMdDown])

  const closeIconHandler = () => {
    if (recordingStatus === RecordingStatus.Playing) {
      setRecordingStatus(RecordingStatus.Paused)
      if (audioRef.current) {
        audioRef.current.pause()
        audioRef.current.currentTime = 0
      }
    }
    close()
  }

  if (isMdDown && visible) {
    return (
      <VoiceRecordingOverlay>
        <Header>
          <Title level={4}>
            <FormattedMessage {...messages.title} />
          </Title>
          {showCloseIcon && <HeaderCloseIcon close={closeIconHandler} />}
        </Header>
        <VoiceRecordingBody
          audioRef={audioRef}
          mediaStream={mediaStream}
          setMediaStream={setMediaStream}
          close={close}
        />
      </VoiceRecordingOverlay>
    )
  }

  return (
    <Modal
      centered
      open={visible}
      onOk={() => close()}
      onCancel={closeIconHandler}
      width={600}
      footer={null}
      closeIcon={showCloseIcon ? <Icon component={CloseIcon} /> : ' '}
      maskClosable={false}
      title={<FormattedMessage {...messages.title} />}
    >
      <VoiceRecordingBody
        audioRef={audioRef}
        mediaStream={mediaStream}
        setMediaStream={setMediaStream}
        close={close}
      />
    </Modal>
  )
}

const VoiceRecordingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: #fff;
  z-index: 1000;
  overflow: hidden auto;
`

export default VoiceRecordingModal
